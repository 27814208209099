import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router';
import { AuthContext } from '../admin/AuthContext';

const AdminRouter = () => {

    const {user} = useContext(AuthContext);
    const authorized = user?.uid === 'UVGW1QlyTkdVBk68TdVTT8GGUKI3'
    return authorized ? <Outlet /> :  <Navigate to ="/admin/login"/>
}

export default AdminRouter;