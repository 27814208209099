import { Button } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { getAuthorMaps, getInitialMap, getMangaMaps, saveData } from '../../helpers/admin'

const MainAdminScreen = () => {

    const generateSiteMap = async () => {

        const initialString = getInitialMap();
        const finalString = `</urlset>`;

        const mangaString = await getMangaMaps();
        const authorString = await getAuthorMaps();

        saveData(`${initialString}${mangaString}${authorString}${finalString}`, 'sitemap.xml')
    }

    return (
        <div className="container mt-3">
            Sekrit admin stuff don't look <br /><br />
            <Link to="/admin/manga">
                New manga
            </Link>
            <br />
            <Link to="/admin/tags">
                Tags
            </Link>
            <br />

            <Button
                variant="contained"
            >
                Back everything up dawg
            </Button>

            <br />
            <Button 
                variant="contained"
                onClick={generateSiteMap}>
                Generate sitemap
            </Button>


        </div>
    )
}

export default MainAdminScreen;