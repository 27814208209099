import { TextField } from '@material-ui/core';
import React, { useReducer } from 'react'
import { filterAuthors } from '../../helpers/filters';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import AuthorCard from './AuthorCard';
const ReactGA = require('react-ga4');

const AuthorList = ({ authorsList }) => {

    function reducer(state,action) {
        switch(action.type){
            case 'change':
                console.log(action)
                return {
                    filteredList: filterAuthors(authorsList, action.payload),
                    search: action.payload
                };
            default: 
                return state;
        }
    }

    const [{filteredList,search}, dispatch] = useReducer(reducer, {filteredList:authorsList,search:''});

    const { width } = useWindowDimensions();

    const handleBlur = () => {
        if(search.trim() !== ''){
            ReactGA.event({
                category: 'AuthorList',
                action: 'Filtered search',
                label: search,
            })
        }
    }

    return (
        <>
            <div className="border border-3 border-primary mb-3">
                <div className="border-bottom border-3 border-primary d-flex">
                    <TextField
                        autoComplete="off"
                        value={search}
                        onChange={(e)=>{dispatch({type:'change',payload:e.target.value})}}
                        onBlur={handleBlur}
                        name='search'
                        className="form-control"
                        type='text'
                        label='Filter author by names and groups'
                    />
                </div>
                <div className="container-fluid pb-2 manga-container animated fast fadeIn`">
                    {
                        filteredList.length === 0
                            ?
                            <div className="alert alert-warning mt-2">
                                No results found...
                                </div>
                            :
                            <div className={`row ${width < 1000 ? 'row-cols-1' : width < 1500 ? 'row-cols-2' : 'row-cols-3'}`}>
                                {
                                    filteredList.map((author) => (
                                        <AuthorCard
                                            key={author.name}
                                            author={author}
                                        />
                                    ))
                                }
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AuthorList;