import { IconButton, TextField } from '@material-ui/core'
import React from 'react'
import { useForm } from '../../hooks/useForm'
import DeleteIcon from '@material-ui/icons/Delete';

const AuthorTextField = ( {index, handleLinkInput, handleDeleteLink, initialValue} ) => {

    const [state, handleInputChange, reset] = useForm(initialValue);

    const {
        text,
        url,
        img,
        localimg
    } = state;

    const handleOnClick = () => {
        handleDeleteLink(state);
        reset();
    }


    return (
        <div className="d-flex border rounded mb-2">
            <TextField
                autoComplete="off"
                style={{width:'70%'}}
                label="Text"
                name="text"
                value={text}
                onChange={handleInputChange}
                onBlur={()=>{
                    handleLinkInput(index,state);
                }}
            />
            <TextField
                autoComplete="off"
                className="ms-2 flex-grow-1"
                label="URL"
                name="url"
                value={url}
                onChange={handleInputChange}
                onBlur={()=>{
                    handleLinkInput(index,state);
                }}
            />
            <TextField
                autoComplete="off"
                className="ms-2 flex-grow-1"
                label="LOCAL"
                name="localimg"
                value={localimg}
                onChange={handleInputChange}
                onBlur={()=>{
                    handleLinkInput(index,state);
                }}
            />
            <TextField
                autoComplete="off"
                className="ms-2 flex-grow-1"
                label="IMG"
                name="img"
                value={img}
                onChange={handleInputChange}
                onBlur={()=>{
                    handleLinkInput(index,state);
                }}
            />
            <IconButton
                onClick={handleOnClick}
            >
                <DeleteIcon />
            </IconButton>

        </div>
    )
}

export default AuthorTextField;