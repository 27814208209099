import React, { useEffect, useState } from 'react'
import { getAuthorsFirestore } from '../../helpers/firestore';
import AuthorList from '../authors/AuthorList';
import { Helmet } from 'react-helmet';

const AuthorsScreen = () => {

    const [authorsList, setAuthorsList] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect( () => {
        getAuthorsFirestore(false)
            .then((list)=>{
                setAuthorsList(list);
            })
            .catch((error) => {
                const errorElement = (
                    <div className="alert alert-danger">
                        There was an error while reading from the database. <br/>
                        Send me a screenshot of this code and I might be able to do somethin about it: {`${error}`}
                    </div>
                )
                setErrorMessage(errorElement);
            })
    }, [])

    return (
        <>
            {
                authorsList
                    ?
                <>
                    <Helmet>
                        <title>Authors List - RudyTudySubs</title>
                    </Helmet>
                    <AuthorList 
                        authorsList={authorsList}
                    />
                </>
                    :
                errorMessage 
                    ?
                <>
                    <Helmet>
                        <title>Error! - RudyTudySubs</title>
                    </Helmet>
                    {errorMessage}
                </>
                    :
                <>
                    <Helmet>
                        <title>Loading... - RudyTudySubs</title>
                    </Helmet>
                    <div className="alert alert-secondary">Loading...</div>
                </>
            }
        </>
    )
}

export default AuthorsScreen;