import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFormattedDate } from '../../helpers/dates';
import MangaLink from './MangaLink';



const MangaCard = React.memo(({ manga, lewdMode }) => {

    const {
        author,
        collabs = [],
        description,
        group,
        links,
        name,
        tags = {},
        lewd,
        date,
        thumbnail,
        id = ''
    } = manga;

    const tagsArray = Object.entries(tags);
    tagsArray.sort((first, second) => {
        if (first[1].order < second[1].order) {
            return -1;
        } else {
            return 1;
        }
    });
    
    const linksArray = Object.values(links).sort((first, second) => {
        if (first.name > second.name) {
            return 1;
        } else {
            return -1;
        }
    });

    const [collabText, setCollabText] = useState('');
    useEffect(() => {
        //console.log(collabs)
        let text = 'asdasdas';
        if (collabs.some(({ name = '' }) => name === 'MiMi')) {
            text = (
                <span key='MiMi'>Done as <a href="https://dynasty-scans.com/scanlators/me_a_scans">Mimi</a>!</span>
            )
        } else {
            // Other groups and shit idk
            text = [(<span key="entry">Done alongside </span>)];
            // Iterate
            collabs.forEach(({ name: nameCollab = '', link = '' }, index) => {
                text = [...text, (<span key={nameCollab}><a href={link}>{nameCollab}</a>{index === collabs.length - 2 ? ' and ' : index === collabs.length - 1 ? '!' : ', '}</span>)];
            });
        }
        setCollabText(text);
        //console.log(text)
    }, [collabs,name])


    return (
        <div className="col mt-2">
            <div className={`card${lewd ? ' lewd-card' : ''} h-100 text-wrap text-break`}>
                <div className="d-flex">
                    <div className="manga-img">
                        <Link key={id} className="no-links" to={`/manga/${id}`}>
                            <img className={`${lewd && lewdMode==='blur' ? 'lewd bg-gradient' : ''}`} alt={`${name} - Author: ${author} - Group: ${group}`} src={`/images/thumb/${id}.jpg`} onError={(e)=>{e.target.onError = null; e.target.src = thumbnail}}/>
                            <div className="manga-overlay">
                                <div className="manga-date border-top border-bottom border-dark text-center bg-warning bg-gradient fw-bold ">{getFormattedDate(date)}</div>
                                <div className="h-100"></div>
                            </div>
                            <div className="ms-auto span-tap text-center border-top border-bottom border-dark bg-info">Tap the image to see more!</div>
                        </Link>
                    </div>
                    <div className="manga-other">
                        <div className="card-body d-flex flex-column h-100">
                            <h5 className="card-title">
                                {name}
                            </h5>
                            <h6 className="card-subtitle mb-2 text-muted">
                                {`By ${author.name} - ${author.group}`}
                            </h6>
                            <p className="card-text">{description}</p>
                            {
                                collabs.length === 0
                                ||
                                <div className="manga-collabs mt-auto border-top border-secondary p-1">
                                    {collabText}
                                </div>
                            }

                            <div className={`${collabs.length === 0 ? 'mt-auto ' : ''}manga-tags border-top border-secondary text-capitalize text-wrap pt-1`}>
                                {
                                    tagsArray.map((tagGroup) => {
                                        const [key, { list = [] }] = tagGroup;
                                        return (
                                            <React.Fragment key={key}>
                                                {
                                                    list.length === 0
                                                    ||
                                                    <>
                                                        <h6 className="d-inline me-2 pe-1">{key}:</h6>
                                                        <span className="tags">
                                                            {list.map((tag) => (
                                                                <span key={tag} className="tag ms-1 me-2">{tag}</span>
                                                            ))}
                                                        </span>
                                                        <br />
                                                    </>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-100"></div>
                <div className="card-footer d-flex">
                    <span className="my-auto read-here">
                        Read here:
                    </span>
                    <div className="links ms-auto flex-shrink-0">
                        {
                            linksArray.map((link) => {
                                if(link.value){
                                    return (
                                        <MangaLink key={link.name} link={link} name={name} />
                                    )
                                }
                                return <></>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
)

export default MangaCard;