import { NavLink } from 'react-router-dom';
import React from 'react'

const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark position-sticky">
            <div className="container-fluid justify-content-start flex-wrap">
                <NavLink
                    activeclassname="active"
                    className="ms-2 navbar-brand"
                    to="/"
                > 
                    RudyTudySubs
                </NavLink>
                    <div className="navbar-nav flex-row flex-wrap">
                        <NavLink
                            activeclassname="active"
                            className="nav-link"
                            to="/manga"
                        > 
                            Manga
                        </NavLink>
                        <NavLink
                            activeclassname="active"
                            className="nav-link ms-4 ms-md-2"
                            to="/authors"
                        > 
                            Authors
                        </NavLink>
                        <NavLink
                            activeclassname="active"
                            className="nav-link ms-4 ms-md-2"
                            to="/about"
                        > 
                            About
                        </NavLink>
                        <NavLink
                            activeclassname="active"
                            className="nav-link ms-4 ms-md-2"
                            to="/nicoyume"
                        > 
                            Nico Yazawa Is Dreaming
                        </NavLink>
                        <NavLink
                            activeclassname="active"
                            className="nav-link ms-4 ms-md-2"
                            to="/granbluedle"
                        > 
                            Granbluedle
                        </NavLink>
                    </div>
                {/* <button className="ms-auto navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <form className="ms-auto me-2 form-inline my-2 my-lg-0 d-flex">
                        <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success my-2 my-sm-0 ms-2" type="submit">Search</button>
                    </form>
                </div> */}
            </div>
        </nav>
    )
}

export default NavBar;