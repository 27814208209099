import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import React from 'react'

const TagDisplayer = React.memo(({ categories, handleChange, bigTagList }) => {

    return (
        <>
            {
                bigTagList.map((tagList,index) => (
                    <React.Fragment key={categories[index]}>
                        <h5 className="text-capitalize w-100">{categories[index]} tags</h5>
                        <div className="container-fluid flex-shrink-0 overflow-auto">
                            {
                                tagList.length === 0
                                    ?
                                    <div className="alert alert-warning">
                                        Nothing here...
                                    </div>
                                    :
                                    tagList.map((tag) => (
                                        <FormControl className="me-2" key={tag.label}>
                                            <FormControlLabel
                                                key={tag.label ? tag.label : tag}
                                                control={
                                                    <Checkbox
                                                        name={tag.label}
                                                        disabled={!tag.label}
                                                        color="primary"
                                                        onChange={(event, checked) => { handleChange(event, checked, categories[index], tag.extra, tag.id) }}
                                                    />
                                                }
                                                label={tag.label || tag}
                                            />
                                            <FormHelperText>{tag.extra}</FormHelperText>
                                        </FormControl>
                                    ))
                            }
                        </div>
                    </React.Fragment>
                    )
                )
            }
        </>
    )
}
)

export default TagDisplayer;