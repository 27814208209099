import { Button } from '@material-ui/core';
import React, { useState } from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions';
const images = require.context('../../../public/images/links')

const AuthorViewer = ( {author} ) => {

    const [full, setFull] = useState(false);

    const { width } = useWindowDimensions();

    const {
        name,
        group,
        links,
        description = ''
    } = author;

    return (
        <>
             <div className="row border-2 border-secondary border-bottom">
                <h2 className="col">{name} - {group}</h2>
            </div>
            <div className="container mb-2">
                <div className="row gx-0">
                    <div className="col">
                        <div className="h-100 d-flex flex-column justify-content-between">
                            
                            {/* Description */}
                            <div className="mx-auto mt-3 p-1 border border-2 border-primary">
                                <p className={`${full || width < 1200 ? 'view-scroll' : 'view-overflow'} mb-0`}>{description}</p>
                                {
                                    width < 1200
                                        ||
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => { setFull(!full) }}
                                    >
                                        {full ? 'Show less' : 'Show more'}
                                    </Button>
                                }
                            </div>

                            {/* Links to author stuff */}
                            <div className="mx-auto mt-3 border border-2 border-primary row row-cols-1 g-0">
                                {
                                    links.map((link) => (
                                        <a key={link.url} className="view-author-links anchor-link d-block border-bottom border-secondary border-3" href={link.url}>
                                            <div className="h-100 col ps-0  d-flex">
                                                <img className="border-end border-secondary border-3 h-100" alt={link.text} src={link.localimg == null ? link.img : images(`./${link.localimg}.png`)} />
                                                <div className="h-100 w-100 ps-1 text-wrap d-flex">
                                                    <div className="my-auto">
                                                        {link.text}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </> 
    )
}

export default AuthorViewer;