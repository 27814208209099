import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
// Get images for links
const images = require.context('../../../public/images/nicoyume');

// Clear game
//cookies.set('no1idol', 'Nico Yazawa', {path:'/'})



const NicoYumeMainScreen = () => {

    const isIdol = cookies.get('no1idol');


    return (
        <>
            <Helmet>
                <title>Nico Yazawa Is Dreaming - RudyTudySubs</title>
            </Helmet>
            <div className={`nicoyume mb-3 ${isIdol === 'Nico Yazawa' ? 'rainbow' : ''}`}>
                <img className="m-auto d-block mb-1 header" alt="nicoyume header" src={isIdol === 'Nico Yazawa' ? (images('./titletrueB.png')) : (images('./title.png'))} />
                <b>Nico Yazawa Is Dreaming</b> is a RPG-esque game by <Link to={`/authors/9FHyxPr9QLFt7qgj92yu`}>7 Colored LED</Link> made in Java,
                mostly centered on character interactions with some RPG elements sprinkled in-between.
                <br />
                <br />

                <i>When Nico-chan woke up one day, she found herself inside a mysterious dream world!!<br />
                    Joined by the rest of μ's, she embarks on a great adventure!<br />
                    Beset by many strange and cute creatures, she must bring a smile to all of them!<br />
                    Aim to become the number one idol in the universe!</i><br /><br />

                <b>Note:</b><br />
                This is a derivate work of Love Live! School Idol Project. <br />
                <br />
                <hr />
                <br />
                <span className="biggerText">If you think the game is too hard:</span> <br />
                This is one of the so-called "die until you memorize it" games, so it might be a bit too hard.
                The default difficulty is set to hard, so if you find it too difficult feel free to lower it
                to normal or even all the way to easy, your save data will not be affected so don't worry about that. <br />
                (For more details read the "Changing the difficulty.txt" within the game folder.) <br />
                (The document "readme.txt" also contains several hints if you're stuck.)

                <br />
                <br />
                <hr />
                <br />
                <span className="biggerText">
                    Download Nico Yazawa Is Dreaming ver1.03 (ENv1.00)
                    <br />
                    <a title="Mediafire Download" className="me-1" href="https://www.mediafire.com/file/alrxdqumbr40n14">
                        MediaFire
                    </a>
                    |
                    <a title="Drive Download" className="ms-1" href="https://drive.google.com/file/d/1iLd4LvEc3lfaICyr5Bo42_rGnC9Zt3Iw">
                        Google Drive
                    </a>
                    <br />
                    <a title="Youtube playthrough" className="ms-1" href="https://www.youtube.com/playlist?list=PLRZvQLwph9W8un89Zi9etY5E3u2MWnhAJ">
                        Watch a full playthrough
                    </a>
                    <br />
                    <a title="Text playthrough" className="ms-1" href="https://lparchive.org/Nico-Yazawa-Is-Dreaming/">
                        Read a full playthrough (by Meta_Mia)
                    </a>
                </span>
                <br />
                (Chrome may falsely flag the download as malicious, but it's safe to ignore.)
                <br />
                <br />
                <span className="biggerText">
                    <a href="https://nso.booth.pm/items/1155594" title="Booth Download">
                        Download the original game for free
                    </a>
                </span>
                <br />
                <span className="biggerText">
                    <a href="https://nso.booth.pm/items/1296883" title="Soundtrack Download">
                        Buy the soundtrack
                    </a>
                </span>
                <br /><br />
                <hr />
                <br />
                <span className="biggerText">Troubleshooting</span><br /><br />

                Make sure to <b>extract the game to its own folder</b> instead of running directly from the ZIP file.
                If you don't do so, any saves made will be lost the next time you run the game.<br /><br />
                If your environment does not support <b>OpenGL 2.0</b>, it will not work. <br /><br />
                <b>If you are using a 32bit OS, you cannot start the program from the exe file</b>, you can install java
                beforehand and start the <b>.jar file</b> in the data folder directly by double clicking it or
                running it from the console. (Might still not work)
                <br />
                <br />
                <hr />
                <br />
                <span className="biggerText">Screenshots</span><br /><br />
                <table className="screenshots">
                    <tbody>
                        <tr><td><img src={(images('./1.png'))} alt="Screenshot" /></td></tr>
                        <tr><td><img src={(images('./2.png'))} alt="Screenshot" /></td></tr>
                        <tr><td><img src={(images('./3.png'))} alt="Screenshot" /></td></tr>
                        <tr><td><img src={(images('./4.png'))} alt="Screenshot" /></td></tr>
                    </tbody>
                </table>
                <br />
                <hr style={{ width: '80%' }} />
                <br />
                <span className="biggerText">
                    <Link to="/nicoyume/notes">
                        Translation notes
                    </Link>
                </span>
                <br />(Contains unmarked spoilers)
            </div>
        </>
    )
}

export default NicoYumeMainScreen;