import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import React, { useCallback } from 'react'
import { useForm } from '../../hooks/useForm';
import AuthorTextField from './AuthorTextField';

const AuthorDialog = ({author, add, handleClose, handleSubmit}) => {

    const [state, handleInputChange] = useForm({
        ...(add ? {
            name: author.newValue,
            group: '',
            description: '',
            links: [
                {text:"Author's twitter",url:'', localimg: 'twitter', img:'https://files.catbox.moe/ap1zct.png'},
                {text:"Author's pixiv",url:'', localimg: 'pixiv',img:'https://files.catbox.moe/wt68pp.jpg'}
            ]
        } : {
                ...author
            })
    })

    const {name, group, description, links} = state;

    const handleAddLink = () => {
        // Create new array with all previous links + a new empty one
        const newLinks = [...links, {text:"Author's ",url:'', localimg: '', img:'https://files.catbox.moe/dsvbda.png'}];
        // Set it
        const newTarget = {
            target: {
                name: 'links',
                value: newLinks
            }
        }
        handleInputChange(newTarget);
    }

    const handleDeleteLink = useCallback( (state) => {
        const newLinks = [...links.filter((collab) => collab.text !== state.text)];
        const newTarget = {
            target: {
                name: 'links',
                value: newLinks
            }
        }
        handleInputChange(newTarget);
    },[handleInputChange,links])

    const handleLinkInput = useCallback( (id, state) => {
        const newLinks = [...links];
        newLinks[id] = { ...state };
        const newTarget = {
            target: {
                name: 'links',
                value: newLinks
            }
        }
        handleInputChange(newTarget);
    },[handleInputChange,links])

    return (
        <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{ add ? 'Add ' : 'Edit'} author</DialogTitle>
            <DialogContent>
                <TextField
                    autoComplete="off"
                    autoFocus
                    className="form-control"
                    name="name"
                    label='Name'
                    placeholder='Name'
                    type='text'
                    value={name}
                    onChange={handleInputChange}
                /> 
                <TextField
                    autoComplete="off"
                    className="form-control"
                    name="group"
                    label='Group'
                    placeholder='Group'
                    type='text'
                    value={group}
                    onChange={handleInputChange}
                    onKeyUpCapture={
                        (e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                handleSubmit(state);
                            }
                        }
                    }
                />
                <TextField
                    autoComplete="off"
                    className="form-control"
                    name="description"
                    label='Description'
                    placeholder='Lorem ipsum'
                    type='text'
                    value={description}
                    onChange={handleInputChange}
                    onKeyUpCapture={
                        (e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                handleSubmit(state);
                            }
                        }
                    }
                />

                <div className="border-top border-2 border-secondary mt-2 pt-2">
                    {
                        links.map((link,index)=>(
                            <AuthorTextField 
                                key={index}
                                initialValue={link}
                                index={index}
                                handleLinkInput={handleLinkInput}
                                handleDeleteLink={handleDeleteLink}
                            />
                        ))
                    }
                    <Button
                        fullWidth
                        color="secondary"
                        variant="outlined"
                        onClick={handleAddLink}
                    >
                        Add link
                    </Button>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={()=>{handleSubmit(state)}} color="primary">
                    { add ? 'Add' : 'Edit'}
                </Button>
            </DialogActions>
      </Dialog>
    )
}

export default AuthorDialog;