import React from 'react'
import TagAdder from './TagAdder'
import {Helmet} from "react-helmet";

const AddTagsScreen = () => {
    return (
        <>
            <Helmet>
                <title>Tag adder</title>
            </Helmet>
            <div className="container mt-3">
                <h1>Tag management</h1>
                <hr />
                <TagAdder 
                    categoryTop='general'
                />
                <hr/>
                <TagAdder 
                    categoryTop='series'
                />
                <hr/>
                <TagAdder 
                    categoryTop='characters'
                />
                <hr/>
            </div>
        </>
    )
}

export default AddTagsScreen;