import { db } from "../firebase/firebase-config"
const images = require.context('../../public/images/links')

export const manageManga = async (manga, add) => {
    // Extract stuff from it
    const { collabs, description, name, tags, id = '', ...unmodified } = manga;
    // Trim normal text that can be trimmed
    const [newDescription, newName] = [description, name].map((text) => text.trim());
    // Trim collabs
    const newCollabs = collabs.map((partner) => ({
        name: partner.name.trim(),
        link: partner.link
    }))
    // Group all tags
    let bigTags = [];
    Object.values(tags).forEach((category) => {
        bigTags = [...bigTags, ...category.list];
    })

    // Form the new object
    const newManga = {
        ...unmodified,
        tags,
        bigTags,
        collabs: newCollabs,
        description: newDescription,
        name: newName,
    }

    //console.log("Writing in database - Manga");

    if (add) {
        // Add to database
        return db.collection('manga').add(newManga);
    } else {
        // Edit in database
        return db.collection('manga').doc(id).set(newManga);
    }

}

export const getManga = async (mangaId) => {
    const mangaSnap = await db.collection('manga').doc(mangaId).get();


    //console.log("Reading in database - Manga");

    // Check if it exists
    if (!mangaSnap.exists) {
        throw new Error(`Manga with supplied ID does not exist in the database.`);
    }

    // Convert to data
    const manga = {
        ...mangaSnap.data(),
        id: mangaSnap.id,
        date: mangaSnap.data().date.toDate()
    }
    ////console.log(manga);
    return manga;
}

export const getLatestManga = async () => {
    const mangaSnap = await db.collection('manga')
        .orderBy('date', 'desc')
        .limit(1)
        .get();


    //console.log("Reading in database - Latest manga");

    // Convert to data
    const manga = {
        ...mangaSnap.docs[0].data(),
        id: mangaSnap.docs[0].id,
        date: mangaSnap.docs[0].data().date.toDate()
    }

    return manga;
}

export const getMangaList = async (authorId = '') => {
    const mangaSnap = await db.collection('manga')
        .orderBy('date', 'desc')
        .orderBy('name', 'asc')
        .get();
    const mangaList = [];


    //console.log("Reading in database - Manga list");

    mangaSnap.forEach(manga => {
        const mangaData = manga.data();
        if (authorId === '' || authorId === mangaData.author.id) {
            mangaList.push({
                ...mangaData,
                id: manga.id,
                date: mangaData.date.toDate()
            });
        }
    })
    return mangaList;
}

export const addTagFirestore = async ({ category, label, extra, extras }) => {
    await db.collection(`tags_${category}`).add({
        label,
        extra,
        extras
    });


    //console.log("Writing in database - Tag");

    return label;
}

export const editTagFirestore = async ({ category, label, extra, extras, id, oldValue }) => {
    await db.collection(`tags_${category}`).doc(id).set({
        label,
        extra,
        extras
    });


    //console.log("Writing in database - Tags");

    // Get a reference to all manga that use that tag
    const mangaCollectionSnap = await db.collection('manga').where('bigTags', 'array-contains', oldValue).get();
    // Initialize the batch
    const batch = db.batch();
    // Iterate the snap
    mangaCollectionSnap.forEach((documentSnap) => {
        // Get all data for easier modifying
        const data = documentSnap.data();
        // Extract the big tag list and the one the tag belongs to
        const { bigTags } = data;
        const categoryData = data.tags[category];
        // Find and replace in those arrays
        bigTags[bigTags.indexOf(oldValue)] = label;
        categoryData.list[categoryData.list.indexOf(oldValue)] = label;
        // Update each manga
        batch.update(documentSnap.ref, 'bigTags', bigTags, `tags.${category}`, categoryData);
        //console.log("Writing in database - Manga Update");
    })
    // Commit the changes
    await batch.commit();

    return label;
}

export const getTagList = async (category) => {
    ////console.log(category)
    let tagRequest = db.collection(`tags_${category}`)
        .orderBy('label', 'asc');

    const tagSnap = await tagRequest.get();

    const tagList = [];

    tagSnap.forEach(tag => {
        tagList.push({
            ...tag.data(),
            id: tag.id,
        });
    })


    //console.log("Reading in database - Tag list");

    ////console.log(tagList)

    return tagList;
}

export const addAuthorFirestore = async (author) => {
    //console.log("Writing in database - Author add");
    return db.collection('authors').add(author).id;
}

export const editAuthorFirestore = async (author, authorId) => {
    //console.log("Writing in database - Author edit");
    await db.collection('authors').doc(authorId).set(author);

    // Get a reference to all manga that use that author
    const mangaCollectionSnap = await db.collection('manga').where('author.id', '==', authorId).get();
    // Initialize the batch
    const batch = db.batch();
    // Iterate the snap
    mangaCollectionSnap.forEach((documentSnap) => {
        // Update each manga
        batch.update(documentSnap.ref, 'author', { ...author, id: authorId });
        //console.log("Writing in database - Manga Update Author");
    })
    // Commit the changes
    return batch.commit();
}

export const getAuthorFirestore = async (authorId) => {
    const authorSnap = await db.collection('authors').doc(authorId).get();

    //console.log("Reading in database - Author");

    // Check if it exists
    if (!authorSnap.exists) {
        throw new Error(`Author with supplied ID does not exist in the database.`);
    }

    // Convert to data
    const author = {
        ...authorSnap.data(),
        id: authorSnap.id
    }
    ////console.log(author);
    return author;
}

export const getAuthorsFirestore = async (sekritUpdate) => {
    let authorRequest = db.collection('authors')
        .orderBy('name', 'asc');


    //console.log("Reading in database - Author list");

    const authorSnap = await authorRequest.get();

    const authorList = [];

    authorSnap.forEach(author => {
        authorList.push({
            ...author.data(),
            id: author.id,
        });
    })

    if(sekritUpdate) {
        // lol update
        const imageKeys = images.keys().map(key => key.slice(2, -4))
        authorList.forEach(listed => {
            let links = listed.links
            links.forEach(link => {
                // find the name 
                const txt = link.text;
                imageKeys.forEach(key => {
                    if (txt.includes(key)) {
                        link.localimg = key
                    }
                })
            })
            // console.log(listed)
            editAuthorFirestore(listed, listed.id)
        })
        // console.log(authorList)
    }
    return authorList;
}