import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { getAuthorFirestore, getMangaList } from '../../helpers/firestore';
import {Helmet} from "react-helmet";
import AuthorViewer from '../authors/AuthorViewer';
import MangaList from '../manga/MangaList';

const ViewAuthorScreen = () => {

    // Read from database if on the URL
    const { authorId } = useParams();
    const [author, setAuthor] = useState(null);
    const [mangaList, setMangaList] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        // Load from database database woah woah
        if (authorId) {
            getAuthorFirestore(authorId)
                .then((returnedAuthor) => {
                    setAuthor(returnedAuthor);
                })
                .then(()=>{
                    // Now load the manga related to the author
                    getMangaList(authorId)
                        .then((returnedManga)=>{
                            setMangaList(returnedManga);
                        })
                })
                .catch((error) => {
                    const errorElement = (
                        <div className="alert alert-danger">
                            There was an error while reading from the database. <br/>
                            The author you tried to access probably doesn't exist anymore. <br/>
                            Send me a screenshot of this code and I might be able to do somethin about it: {`${error}`}
                        </div>
                    )
                    // const errorElement = (
                    //     <span key="a">Done as <a href="https://dynasty-scans.com/scanlators/me_a_scans">Mimi</a>!</span>
                    // )
                    setErrorMessage(errorElement);
                })
        }
    }, [authorId])

    return (
        <>
            {
                author
                    ?
                <>
                    <Helmet>
                        <title>{author.name} - RudyTudySubs</title>
                    </Helmet>
                    <div className="container mt-3">
                        <AuthorViewer
                            author={author}
                        />
                    </div>
                    <div className="mt-5">
                        <h3>Manga by this author</h3>
                        {
                            mangaList
                                ?
                            <MangaList
                                mangaList={mangaList}
                            />
                                :
                            <div className="alert alert-secondary">Loading...</div>
                        }
                    </div>
                </>
                    :
                errorMessage 
                    ?
                <>
                    <Helmet>
                        <title>Error! - RudyTudySubs</title>
                    </Helmet>
                    {errorMessage}
                </>
                    :
                <>
                    <Helmet>
                        <title>Loading... - RudyTudySubs</title>
                    </Helmet>
                    <div className="alert alert-secondary">Loading...</div>
                </>
            }
        </>
    )
}

export default ViewAuthorScreen;