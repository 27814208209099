import {useState} from 'react';
import Cookies from 'universal-cookie';

const useCookie = (key, initialValue = false) => {
  const cookies = new Cookies();
  const [item, setItemValue] = useState(() => {
    if (cookies.get(key)) {
      return cookies.get(key);
    }
    return initialValue;
  });

  const setValue = (value, options = {path: '/', maxAge: (10 * 365 * 24 * 60 * 60 * 1000)}) => {
    setItemValue(value);
    cookies.set(key, value, options);
  };

  const removeItem = (options) => {
    cookies.remove(key);
  };

  return [item, setValue, removeItem];
};

export default useCookie;