import React from 'react'

// Get images for links
const images = require.context('../../../public/images/links')

const MangaLink = ({ link, name }) => {

    return (
        <div className="mx-2">
            <a className="anchor-link border border-secondary p-1 pb-2 border-lg-0 d-flex" href={link.value} title={link.label}>
                <span className="me-2 d-lg-none">{link.label}</span>
                <img className="rounded-circle border border-secondary border-2 m-auto" alt={link.label} src={(images(`./${link.name}.png`))} />
            </a>
        </div>

    )
}

export default MangaLink;