/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef } from 'react'
import { Snackbar, Alert, Zoom, Slide, Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, TextField, createFilterOptions, Accordion, AccordionSummary, Typography, AccordionDetails, DialogActions } from '@mui/material';
import { getWeapons, guess, getRandomWeapon, guexx, ouxx, getStats, ougid, getSpecificWeapon, lost, typeIcon, checkMaint } from '../../helpers/granbluedle';
import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import GuessSheet from '../granbluedle/GuessSheet';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import QuizIcon from '@mui/icons-material/Quiz';
import useCookie from '../../hooks/useCookie';
import CrossfadeImage from 'react-crossfade-image'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LanguageIcon from '@mui/icons-material/Language';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Countdown from 'react-countdown';
import { getFormattedDate, getFormattedDateJP } from '../../helpers/dates';
import { useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ReactGA from "react-ga4";
import { toHiragana, toKatakana } from 'wanakana';
const cookies = new Cookies();

const images = require.context('../../../public/images/granbluedle/etc')
const grans = require.context('../../../public/images/granbluedle/player/gran')
const jiitas = require.context('../../../public/images/granbluedle/player/djeeta')
const enemies = require.context('../../../public/images/granbluedle/enemy')

const char = require.context('../../../public/images/granbluedle/char')
const wpn = require.context('../../../public/images/granbluedle/wpn')
const skill = require.context('../../../public/images/granbluedle/skill')
const skilljp = require.context('../../../public/images/granbluedle/skilljp')

// Check maint before entering
const maintStuff = await checkMaint()

const GranbluedleScreen = ({
    endlessMode
}) => {


    if (maintStuff.maintenanceV) {
        return (
            <>
                <Helmet>
                    <title>Granbluedle Maintenance - RudyTudySubs</title>
                </Helmet>

                <div className='prt-result-cnt pt-4 pb-5 mb-2' style={{ color: 'white' }}>
                    <img src={images('./logo.png')} alt='logo' />
                    <div className='mt-2 col-12'>
                        The game is now undergoing maintenance.<br />
                        Thank you for your patience.<br />
                        The reason for the maintenance is as follows: {maintStuff.maintenanceReasonV} <br />
                        ETA: <Countdown date={maintStuff.maintenanceDateV} />
                        <br /><br />
                        ただいまメンテナンス中です。<br />
                        メンテナンス終了までしばらくお待ちください。
                    </div>
                </div>
            </>
        )
    }

    //TODO: check later
    const [stats, setStats] = useState(null)
    const [finalDate, setFinalDate] = useState(null)

    const OPTIONS_LIMIT = 10
    const defaultFilterOptions = createFilterOptions();
    const filterOptions = createFilterOptions({
        limit: OPTIONS_LIMIT,
        ignoreAccents: true,
        ignoreCase: true,
        matchFrom: 'any',
        trim: true,
        stringify: (option) => {
            if (option.blank) {
                return '';
            }
            if (jpMode) {
                const text = `${option.jpname} ${option.characterjp == null ? '' : option.characterjp} ${toHiragana(option.jpname)} ${option.characterjp == null ? '' : toHiragana(option.characterjp)} ${toKatakana(option.jpname)} ${option.characterjp == null ? '' : toKatakana(option.characterjp)}`
                return text
            } else {
                const text = `${option.weapon_name} ${option.character_name == null ? '' : option.character_name}`
                return text
            }
        }
    });

    const { weaponId, hardParam } = useParams();

    const [weaponList, setWeaponList] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [value, setValue] = useState({ blank: true, weapon_name: 'Dagger of Truth' })
    const [guessList, setGuessList] = useState([])
    const [weaponToGuess, setWeaponToGuess] = useState(null)

    const [lockout, setLockout] = useState(false)
    const [HP, setHP] = useState(16500)
    const [guts, setGuts] = useState(true)
    const [CA, setCA] = useState(0)

    const acc = useRef(null)
    const winScreenMove = useRef(null)
    const questResults = useRef(null)
    const inputRef = useRef(null)
    const [hintImage, setHintImage] = useState(null)
    const [hintDesc, setHintDesc] = useState(null)
    const [hintName, setHintName] = useState(null)
    const [hintSkills, setHintSkills] = useState(null)
    const [hintDialogOpen, setHintDialogOpen] = useState(false)

    const [gameState, setGameState] = useState(0) // 0 - playing | 1 - won | 2 - lost

    const [firstMessage, setFirstMessage] = useState("")
    const [firstVisible, setFirstVisible] = useState(false)
    const [secondMessage, setSecondMessage] = useState("")
    const [secondVisible, setSecondVisible] = useState(false)

    const [helpDialog, setHelpDialog] = useState(false)
    const [tutorialDialog, setTutorialDialog] = useState(false)

    const [visibleAlert, setVisibleAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState("")

    // Cookies stuff
    const [tutorialState, setTutorialState] = useCookie('tutorialDone', 0)
    const [hardMode, setHardMode] = useCookie('hardMode', false);
    const [isGran, setIsGran] = useCookie('isGran', false);
    const [jpMode, setJpMode] = useCookie('jpMode', false);

    const [player, setPlayer] = useState(isGran ? grans(`./idle.gif`) : jiitas('./idle.gif'))
    const [enemy, setEnemy] = useState(enemies('./kinggold.gif'))
    const [enemyName, setEnemyName] = useState('kinggold')
    const [ougimotion, setOugimotion] = useState(false)

    const [timeToSave, setTimeToSave] = useState(0);
    const [metaStats, setMetaStats] = useState(null)
    const [mostUsed, setMostUsed] = useState(0)
    const [copiedGameStats, setCopiedGameStats] = useState(false);
    const [copiedPlayerStats, setCopiedPlayerStats] = useState(false);

    const { width } = useWindowDimensions();


    // weapon list and load daily progress 
    useEffect(() => {
        var hardModeDo = hardMode
        if (hardParam) {
            if (hardParam === 'h') {
                hardModeDo = true
                setHardMode(true)
            }
        }
        var date = new Date();
        date.setTime(date.getTime + (9 * 60000));
        getStats().then(rec => {
            var finalDate = new Date(rec.firstDay)
            finalDate.setTime(finalDate.getTime() + ((rec.currentDay + 1) * 24 * 60 * 60 * 1000))
            setFinalDate(finalDate)
            setStats(rec)
            getWeapons(hardModeDo)
                .then((list) => {
                    setWeaponList(list);
                    if (endlessMode) {
                        if (weaponId) {
                            // id
                            ReactGA.event({
                                action: 'granbluedle - game init',
                                category: `endless start ${hardModeDo ? 'hard' : 'normal'}`,
                                label: weaponId
                            })
                            getSpecificWeapon(weaponId).then((wep) => setWeaponToGuess(wep))
                        } else {
                            ReactGA.event({
                                action: 'granbluedle - game init',
                                category: `endless start ${hardModeDo ? 'hard' : 'normal'}`,
                                label: 'random weapon'
                            })
                            getRandomWeapon(hardModeDo).then((wep) => setWeaponToGuess(wep))
                        }
                    } else {
                        // Check for current date saved
                        var startOver = true;
                        const lastDayPlayed = localStorage.getItem('lastDayPlayed')
                        if (lastDayPlayed != null) {
                            const intPlayed = parseInt(lastDayPlayed)
                            if (intPlayed === (rec.currentDay + 1)) {
                                startOver = false;
                            }
                        }
                        // initialize stuff
                        if (startOver) {
                            console.log('clean slate')
                            localStorage.setItem('lastDayPlayed', rec.currentDay + 1)
                            localStorage.setItem('normalDailyProgress', null)
                            localStorage.setItem('hardDailyProgress', null)
                            ReactGA.event({
                                action: 'granbluedle - game init',
                                category: `daily start ${hardModeDo ? 'hard' : 'normal'}`,
                                label: `daily ${rec.currentDay + 1}`
                            })
                        } else {
                            // load values
                            const objSaved = JSON.parse(localStorage.getItem(hardModeDo ? 'hardDailyProgress' : 'normalDailyProgress'))

                            if (objSaved != null) {
                                setEnemyName(objSaved.enemy)
                                setGameState(objSaved.gameState)
                                setHintImage(objSaved.hintImage)
                                setHintDesc(objSaved.hintDesc)
                                setHintName(objSaved.hintName)
                                setHintSkills(objSaved.hintSkills)
                                setHP(objSaved.HP)
                                setCA(objSaved.CA)
                                setGuts(objSaved.guts)
                                setGuessList(objSaved.guessList)
                            } else {
                                ReactGA.event({
                                    action: 'granbluedle - game init',
                                    category: `daily resume ${hardModeDo ? 'hard' : 'normal'}`,
                                    label: `${rec.currentDay + 1}`
                                })
                            }

                        }
                        setTimeToSave(v => v + 1)
                    }
                    // meta
                    const metaStuff = localStorage.getItem(hardModeDo ? endlessMode ? 'hardMetaProgressEndless' : 'hardMetaProgress' : endlessMode ? 'normalMetaProgressEndless' : 'normalMetaProgress')
                    if (metaStuff != null) {
                        var metaStatsSave = JSON.parse(metaStuff)
                        metaStatsSave.gamesPlayed = parseInt(metaStatsSave.gamesPlayed);
                        metaStatsSave.gamesWon = parseInt(metaStatsSave.gamesWon);
                        metaStatsSave.currStreak = parseInt(metaStatsSave.currStreak);
                        metaStatsSave.maxStreak = parseInt(metaStatsSave.maxStreak);
                        metaStatsSave.hintDesc = parseInt(metaStatsSave.hintDesc);
                        metaStatsSave.hintImage = parseInt(metaStatsSave.hintImage);
                        metaStatsSave.hintSkills = parseInt(metaStatsSave.hintSkills);
                        metaStatsSave.hintName = parseInt(metaStatsSave.hintName);
                        metaStatsSave.rank = parseInt(metaStatsSave.rank == null ? '1' : metaStatsSave.rank)
                        metaStatsSave.oldrank = parseInt(metaStatsSave.oldrank == null ? '1' : metaStatsSave.oldrank)
                        metaStatsSave.rp = parseInt(metaStatsSave.rp == null ? '0' : metaStatsSave.rp)
                        metaStatsSave.exp = parseInt(metaStatsSave.exp == null ? '0' : metaStatsSave.exp)
                        metaStatsSave.rpToEarn = parseInt(metaStatsSave.rpToEarn == null ? '0' : metaStatsSave.rpToEarn)
                        setMetaStats(metaStatsSave)
                        // Most used
                        var mostUsedupdate = mostUsed
                        if (metaStatsSave.hintDesc > mostUsedupdate) {
                            mostUsedupdate = metaStatsSave.hintDesc
                        }
                        if (metaStatsSave.hintImage > mostUsedupdate) {
                            mostUsedupdate = metaStatsSave.hintImage
                        }
                        if (metaStatsSave.hintSkills > mostUsedupdate) {
                            mostUsedupdate = metaStatsSave.hintSkills
                        }
                        if (metaStatsSave.hintName > mostUsedupdate) {
                            mostUsedupdate = metaStatsSave.hintName
                        }
                        setMostUsed(mostUsedupdate)
                    }
                })
                .catch((error) => {
                    const errorElement = (
                        <div className="alert alert-danger">
                            There was an error while reading from the database. <br />
                            Send me a screenshot of this code and I might be able to do something about it: {`${error}`}
                        </div>
                    )
                    setErrorMessage(errorElement);
                })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hardMode, endlessMode, weaponId, hardParam])

    useEffect(() => {
        if (timeToSave !== 0) {
            var objToSave = {}
            objToSave.enemy = enemyName
            objToSave.gameState = gameState
            objToSave.hintImage = hintImage
            objToSave.hintDesc = hintDesc
            objToSave.hintName = hintName
            objToSave.hintSkills = hintSkills
            objToSave.HP = HP
            objToSave.CA = CA
            objToSave.guessList = guessList
            objToSave.guts = guts

            console.log(objToSave)

            localStorage.setItem(hardMode ? 'hardDailyProgress' : 'normalDailyProgress', JSON.stringify(objToSave))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeToSave])

    // preload images
    useEffect(() => {
        var array = [
            grans('./attack.gif'), grans('./ded.gif'), grans('./dmg.gif'), grans('./idle.gif'), grans('./low.gif'), grans('./ougi.gif'), grans('./ready.gif'), grans('./win.gif'),
            jiitas('./attack.gif'), jiitas('./ded.gif'), jiitas('./dmg.gif'), jiitas('./idle.gif'), jiitas('./low.gif'), jiitas('./ougi.gif'), jiitas('./ready.gif'), jiitas('./win.gif'),
            enemies('./kinggold.gif'), enemies('./kinggold_attack.gif'), enemies('./kinggold_ded.gif'),
            enemies('./kingsilver.gif'), enemies('./kingsilver_attack.gif'), enemies('./kingsilver_ded.gif'),
            enemies('./kingbronze.gif'), enemies('./kingbronze_attack.gif'), enemies('./kingbronze_ded.gif'),
            enemies('./smolslime.gif'), enemies('./smolslime_attack.gif'), enemies('./smolslime_ded.gif')
        ]
        var list = [];
        for (var i = 0; i < array.length; i++) {
            var img = new Image();
            img.onload = function () {
                var index = list.indexOf(this);
                if (index !== -1) {
                    // remove image from the array once it's loaded
                    // for memory consumption reasons
                    list.splice(index, 1);
                }
            }
            list.push(img);
            img.src = array[i];
        }
    }, [])

    // Check region
    useEffect(() => {
        const firstVisit = cookies.get('firstVisit')
        if (!firstVisit) {
            axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                if (data.country_code === 'JP') {
                    setJpMode(true);
                }
                ReactGA.event({
                    action: 'granbluedle - first visit',
                    category: 'first visit',
                    label: `${data.country_code}`
                })
            })
            cookies.set('firstVisit', true, { path: '/', maxAge: (10 * 365 * 24 * 60 * 60 * 1000) })
            setHelpDialog(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Tutorial stuff
    useEffect(() => {
        const tutorialDone = cookies.get('tutorialDone')
        if (!tutorialDone) {
            ReactGA.event('tutorial_begin')
            setTutorialState(0)
        } else {
            setTutorialState(tutorialDone)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        switch (tutorialState) {
            case -1:
                setFirstVisible(false)
                setSecondVisible(false)
                break;
            case 0:
                setFirstVisible(true);
                setFirstMessage(jpMode ? "まずは好きな武器を入力する！" : "Start by typing your favorite weapon!")
                break;
            case 1:
                setSecondVisible(true);
                setSecondMessage(jpMode ? "始めての推測の結果をもとに範囲を狭めて！" : "Use the results of your first guess to narrow down your next guess!")
                setFirstVisible(true);
                setFirstMessage(jpMode ? "詳しくは下部のカラーコードをご確認ください" : "Check the Color Code at the bottom for more information.")
                break;
            case 2:
                setSecondVisible(true);
                setSecondMessage(jpMode ? "奥義ゲージを100%以上ため、奥義を使えるようになる！" : "Once you reach 100% CA you'll be able to use a Skybound Art!")
                setFirstVisible(true);
                setFirstMessage(jpMode ? "詳しくは下記の「ヒント」をご覧ください" : "Check the Hints section below for more information.")
                break;
            case 3:
                setSecondVisible(true);
                setSecondMessage(jpMode ? "目的の武器にたどり着くまで推測続けて！" : "Keep guessing until you reach the target weapon!")
                setFirstVisible(true);
                setFirstMessage(jpMode ? "頑張ってね！" : "Good luck and have fun!")
                break;
            default: return;
        }
    }, [tutorialState, jpMode])

    useEffect(() => {
        if (gameState > 0) {
            if (gameState === 1) {
                setEnemy(enemies(`./${enemyName}_ded.gif`))
                setPlayer(isGran ? grans('./win.gif') : jiitas('./win.gif'));
            } else {
                setPlayer(isGran ? grans('./ded.gif') : jiitas('./ded.gif'));
            }
            if (!endlessMode) {
                lost(hardMode).then((wep) => setWeaponToGuess(wep))
            }
            if (!endlessMode) {
                setTimeToSave(v => v + 1)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameState, isGran, endlessMode, enemyName])

    function updateMetaStats(won) {
        // Update stats
        var metaStatsSave = metaStats
        if (metaStatsSave == null) {
            metaStatsSave = {
                gamesPlayed: 0,
                gamesWon: 0,
                currStreak: 0,
                maxStreak: 0,
                hintDesc: 0,
                hintImage: 0,
                hintSkills: 0,
                hintName: 0,
                rank: 1,
                oldrank: 1,
                rp: 0,
                exp: 0,
                rpToEarn: 0
            }
        }

        if (metaStatsSave.rank == null) {
            metaStatsSave.rank = 1
            metaStatsSave.oldrank = 1
            metaStatsSave.rp = 0
            metaStatsSave.exp = 0
            metaStatsSave.rpToEarn = 0
        }

        metaStatsSave.gamesPlayed = metaStatsSave.gamesPlayed + 1;
        metaStatsSave.gamesWon = won ? metaStatsSave.gamesWon + 1 : metaStatsSave.gamesWon;
        metaStatsSave.currStreak = won ? metaStatsSave.currStreak + 1 : 0;
        metaStatsSave.maxStreak = metaStatsSave.currStreak > metaStatsSave.maxStreak ? metaStatsSave.currStreak : metaStatsSave.maxStreak;
        metaStatsSave.hintDesc = hintDesc != null ? metaStatsSave.hintDesc + 1 : metaStatsSave.hintDesc;
        metaStatsSave.hintImage = hintImage != null ? metaStatsSave.hintImage + 1 : metaStatsSave.hintImage;
        metaStatsSave.hintSkills = hintSkills != null ? metaStatsSave.hintSkills + 1 : metaStatsSave.hintSkills;
        metaStatsSave.hintName = hintName != null ? metaStatsSave.hintName + 1 : metaStatsSave.hintName;

        // check for seed
        if (weaponId) {
            metaStatsSave.rpToEarn = 0
            metaStatsSave.oldrank = metaStatsSave.rank
            metaStatsSave.rp = 0
            metaStatsSave.exp = 0
        } else {
            // rp
            var rpToEarn = won ? HP > 10000 ? 10000 : HP < 3000 ? 3000 : HP : 1000;
            if (enemyName === 'kinggold') {
                rpToEarn = rpToEarn + rpToEarn
            } else if (enemyName === 'kingsilver') {
                rpToEarn = rpToEarn + Math.floor(rpToEarn * 0.5)
            }
            metaStatsSave.rpToEarn = rpToEarn

            // Levels
            var levelUps = 0;
            while (rpToEarn >= 4000) {
                rpToEarn = rpToEarn - 4000;
                levelUps = levelUps + 1
            }
            metaStatsSave.oldrank = metaStatsSave.rank
            metaStatsSave.rank = metaStatsSave.rank + levelUps
            metaStatsSave.rp = rpToEarn
            metaStatsSave.exp = Math.floor(Math.random() * 99999)
        }

        // Most used
        var mostUsedupdate = mostUsed
        if (metaStatsSave.hintDesc > mostUsedupdate) {
            mostUsedupdate = metaStatsSave.hintDesc
        }
        if (metaStatsSave.hintImage > mostUsedupdate) {
            mostUsedupdate = metaStatsSave.hintImage
        }
        if (metaStatsSave.hintSkills > mostUsedupdate) {
            mostUsedupdate = metaStatsSave.hintSkills
        }
        if (metaStatsSave.hintName > mostUsedupdate) {
            mostUsedupdate = metaStatsSave.hintName
        }
        setMostUsed(mostUsedupdate)
        setMetaStats(metaStatsSave)

        if (weaponId == null) {
            ReactGA.event({
                action: `granbluedle - ${won ? 'won' : 'lost'}`,
                category: `${endlessMode ? 'endless' : `daily ${stats.currentDay + 1}`} ${hardMode ? 'hard' : 'normal'}`,
                label: `Rank: ${metaStatsSave.rank} Guesses: ${guessList.length} Ougi: ${hintDesc != null ? '📓' : ''}${hintSkills != null ? '🔠' : ''}${hintImage != null ? '📷' : ''}${hintName != null ? '⌨️' : ''} ${hintDesc == null && hintSkills == null && hintImage == null & hintName == null ? '❌' : ''}`
            })
            ReactGA.event({
                action: `granbluedle - ${won ? 'won' : 'lost'}`,
                category: 'character',
                label: isGran ? 'gran' : 'djeeta'
            })
            ReactGA.event({
                action: `granbluedle - ${won ? 'won' : 'lost'}`,
                category: 'language',
                label: jpMode ? 'jp' : 'en'
            })
            ReactGA.event({
                action: `granbluedle - ${won ? 'won' : 'lost'}`,
                category: 'enemy',
                label: enemyName
            })
        }

        localStorage.setItem(hardMode ? endlessMode ? 'hardMetaProgressEndless' : 'hardMetaProgress' : endlessMode ? 'normalMetaProgressEndless' : 'normalMetaProgress', JSON.stringify(metaStatsSave))
    }

    function copyGameStats() {
        var text = ""
        if (jpMode) {
            text = HP === 0 ? '参加者募集！\n#Granbluedle' : `${enemyName === 'kinggold' ? 'キングゴールドスライム' : enemyName === 'kingsilver' ? 'キングシルバースライム' : enemyName === 'kingbronze' ? 'うたたねアンディ' : 'スライム'}を倒した!\n#Granbluedle`
            if (!endlessMode) {
                text = `${text} 第${stats.currentDay + 1}回デイリー ${hardMode ? '(ハード)' : ''}`
            }
        } else {
            text = HP === 0 ? 'I need backup!\n#Granbluedle' : `I defeated a ${enemyName === 'kinggold' ? 'King Goldslime' : enemyName === 'kingsilver' ? 'King Silverslime' : enemyName === 'kingbronze' ? 'Drowsy Drew' : 'Slime'}!\n#Granbluedle`
            if (!endlessMode) {
                text = `${text} daily #${stats.currentDay + 1} ${hardMode ? '(hard mode)' : ''}`
            }
        }
        text = `${text}\n❓: ${guessList.length} ❤️: ${(HP / 16500 * 100).toFixed(0)}% ⚔️: ${hintDesc != null ? '📓' : ''}${hintSkills != null ? '🔠' : ''}${hintImage != null ? '📷' : ''}${hintName != null ? '⌨️' : ''} ${hintDesc == null && hintSkills == null && hintImage == null & hintName == null ? '❌' : ''}\n`
        var andMore = '';
        guessList.forEach(({ sheet }, i) => {
            if (i < 5) {
                text = `${text}${sheet.release === 'Higher Close' ? '🔼' :
                    sheet.release === 'Higher Far' ? '⏫' :
                        sheet.release === 'Lower Close' ? '🔽' :
                            sheet.release === 'Lower Far' ? '⏬' : '🟩'
                    }${sheet.element === 1 ? '🟩' : sheet.element === 2 || sheet.element === 3 ? '🟧' : '🟥'
                    }${sheet.type ? '🟩' : '🟥'
                    }${sheet.series ? '🟩' : '🟥'
                    }${sheet.awakening ? '🟩' : '🟥'
                    }${sheet.uncap ? '🟩' : '🟥'
                    }${sheet.obtains === 'None' ? '🟥' : sheet.obtains === 'Partial' ? '🟧' : '🟩'
                    }${sheet.skills === 'None' ? '🟥' : sheet.skills === 'Partial' ? '🟧' : '🟩'}\n`
            } else {
                andMore = jpMode ? `あと省いた${i - 4}体の推測` : `And ${i - 4} more guesse(s)!`
            }
        })
        text = `${text}${andMore}\n`
        text = `${text}https://rudytudysubs.herokuapp.com/granbluedle${endlessMode ? `/endless/${weaponToGuess.id}` : ''}${endlessMode && hardMode ? '/h' : ''}`
        text = `${text}\n`
        navigator.clipboard.writeText(text)
        setCopiedGameStats(true)
        ReactGA.event('share', {
            method: 'game',
            content_type: hardMode ? 'hard' : 'normal',
            item_id: endlessMode ? weaponToGuess.id : `daily ${stats.currentDay + 1}`
        })
        setTimeout(() => {
            setCopiedGameStats(false)
        }, 5000);
    }

    function copyPlayerStats() {
        var text = ''
        if (jpMode) {
            text = `WEBクイズゲームは今これをやってるよ。今の${hardMode ? 'ハード' : ''}${endlessMode ? 'エンドレス' : 'デイリー'}ステータスはこちら！ #Granbluedle!`;
        } else {
            text = `Check out my ${hardMode ? 'hard ' : ''}${endlessMode ? 'endless' : 'daily'} stats in #Granbluedle!`;
        }
        text = `${text}\n👑 Rank: ${metaStats.rank}`
        text = `${text}\n🎮 ${jpMode ? '試合数' : 'Games Played'}: ${metaStats.gamesPlayed}`
        text = `${text}\n🏆 ${jpMode ? '勝利数' : 'Games Won'}: ${metaStats.gamesWon}`
        text = `${text}\n🔥 ${jpMode ? '現在の連勝' : 'Current Streak'}: ${metaStats.currStreak}`
        text = `${text}\n⭐ ${jpMode ? '史上最高の連勝' : 'Max Streak'}: ${metaStats.maxStreak}`
        text = `${text}\n⚔️ ${jpMode ? 'お気に奥義' : 'Favorite SBA'}: ${metaStats.hintDesc === mostUsed ? '📓' : ''}${metaStats.hintSkills === mostUsed ? '🔠' : ''}${metaStats.hintImage === mostUsed ? '📷' : ''}${metaStats.hintName === mostUsed ? '⌨️' : ''}`
        text = `${text}\nhttps://rudytudysubs.herokuapp.com/granbluedle${endlessMode ? `/endless/` : ''}`
        text = `${text}\n`
        navigator.clipboard.writeText(text)
        setCopiedPlayerStats(true)
        ReactGA.event('share', {
            method: 'player',
            content_type: hardMode ? 'hard' : 'normal',
            item_id: endlessMode ? weaponToGuess.id : `daily ${stats.currentDay + 1}`
        })
        setTimeout(() => {
            setCopiedPlayerStats(false)
        }, 5000);

    }

    function takeDamage(ougi) {
        // 0 = 26
        // 1 = -97
        // 2 = -197
        setPlayer(isGran ? grans(`./dmg.gif`) : jiitas('./dmg.gif'))
        setEnemy(enemies(`./${enemyName}_attack.gif`))

        let currentTutorial = tutorialState;
        if (tutorialState !== -1) {
            setTutorialState(currentTutorial + 1)
        }
        if (tutorialState > 2) {
            setTutorialState(-1)
            ReactGA.event('tutorial_complete');
        }

        setTimeout(() => {
            const hpNext = (HP - 1250 <= 0) ? (guts ? 1 : 0) : HP - 1250;
            setHP(hpNext)
            var caNext = CA - (ougi === 0 ? -26 : ougi === 1 ? 97 : ougi === 2 ? 197 : 0);
            caNext = caNext > 200 ? 200 : caNext
            setCA(caNext)

            if (hpNext === 1) {
                setGuts(false)
            }

            // Enemy
            var enemy = hpNext < 3333 ? 'smolslime' : hpNext < 6666 ? 'kingbronze' : hpNext < 10500 ? 'kingsilver' : 'kinggold'
            setEnemyName(enemy)
            setEnemy(enemies(`./${enemy}.gif`))

            // Advance if not but check for loss first
            if (hpNext === 0) {
                updateMetaStats(false)
                setGameState(2)
                inputRef.current.scrollIntoView();
                setTimeout(() => {
                    questResults.current.scrollIntoView();
                }, 2000);
                return;
            }

            if (caNext >= 100) {
                setPlayer(isGran ? grans(`./ready.gif`) : jiitas('./ready.gif'))
            } else if (hpNext < 7000) {
                setPlayer(isGran ? grans(`./low.gif`) : jiitas('./low.gif'))
            } else {
                setPlayer(isGran ? grans(`./idle.gif`) : jiitas('./idle.gif'))
            }
            setLockout(false)
            if (!endlessMode) {
                setTimeToSave(v => v + 1)
            }
            setTimeout(() => {
                inputRef.current.focus();
            }, 500);
        }, 1000);
    }

    function ougi(which) {
        if (CA < 100) return;
        setLockout(true)
        setOugimotion(true)
        setTimeout(() => {
            switch (which) {
                case 1: //image
                    if (hintImage) return
                    var whichPromise1 = endlessMode ? ouxx(1, weaponToGuess, hardMode) : ougid(1, hardMode);
                    whichPromise1.then((base64) => {
                        setHintImage('data:image/jpeg;base64,' + base64)
                        setHintDialogOpen(true)
                        takeDamage(2)
                    })
                    break;
                case 2: // DESC
                    if (hintDesc) return
                    var whichPromise2 = endlessMode ? ouxx(2, weaponToGuess, hardMode) : ougid(2, hardMode);
                    whichPromise2.then((desc) => {
                        setHintDesc(desc)
                        setHintDialogOpen(true)
                        takeDamage(2)
                    })
                    break;
                case 3: // Letters
                    if (hintName) return
                    var whichPromise3 = endlessMode ? ouxx(3, weaponToGuess, hardMode) : ougid(3, hardMode);
                    whichPromise3.then((desc) => {
                        setHintName(desc)
                        setHintDialogOpen(true)
                        takeDamage(1)
                    })
                    break;
                case 4: // Skills
                    if (hintSkills) return
                    var whichPromise4 = endlessMode ? ouxx(4, weaponToGuess, hardMode) : ougid(4, hardMode);
                    whichPromise4.then((skills) => {
                        setHintSkills(skills)
                        setHintDialogOpen(true)
                        takeDamage(1)
                    })
                    break;
                default: break;
            }
            setOugimotion(false);
            acc.current.scrollIntoView();
        }, 3000);
    }

    function guessSelect(e, v, r) {
        // Check for repeats
        var allow = true;
        if (v == null) {
            return;
        }
        guessList.forEach(guessed => {
            if (v.weapon_name === guessed.guess.weapon_name) {
                allow = false
            }
        })

        if (!allow) {
            showAlert(jpMode ? '武器はすでに推測でした！' : 'Weapon already guessed!')
            return;
        }
        var promiseGuess = endlessMode ? guexx(v, weaponToGuess, hardMode) : guess(v, hardMode);

        promiseGuess.then((response) => {
            // check for first guess
            if (guessList.length === 0 && weaponId == null) {
                ReactGA.event({
                    action: 'granbluedle - first guess',
                    category: `${endlessMode ? 'endless' : 'daily'} ${hardMode ? 'hard' : 'normal'}`,
                    label: `${v.weapon_name}`
                })
            }
            setGuessList([response, ...guessList])
            setLockout(true)
            setPlayer(isGran ? (grans('./attack.gif')) : (jiitas('./attack.gif')));

            // Check for correct
            const {
                awakening: answer_awakening,
                element: answer_element,
                obtains: answer_obtains,
                release: answer_release,
                series: answer_series,
                skills: answer_skills,
                type: answer_type,
                uncap: answer_uncap
            } = response.sheet

            setTimeout(() => {
                if (answer_awakening && answer_element && answer_release && answer_series &&
                    answer_type && answer_uncap && answer_obtains === 'Correct' && answer_skills === 'Correct') {
                    updateMetaStats(true)
                    setGameState(1)
                    if (tutorialState !== -1) {
                        setSecondVisible(true);
                        setSecondMessage("You completed the game before the tutorial was over.")
                        setFirstVisible(true);
                        setFirstMessage("Good job!")
                    }
                    winScreenMove.current.scrollIntoView();
                    setTimeout(() => {
                        questResults.current.scrollIntoView();
                    }, 2000);
                    return;
                } else {
                    takeDamage(0)
                }
            }, 1111);
        })
            .catch((error) => console.error(error))
            .finally(() => { setValue({ blank: true, weapon_name: 'Dagger of Truth' }); })
    }

    function changeChar() {
        const isGonnaBeGran = !isGran
        setIsGran(isGonnaBeGran)
        if (CA >= 100) {
            setPlayer(isGonnaBeGran ? grans(`./ready.gif`) : jiitas('./ready.gif'))
        } else if (HP < 7000) {
            setPlayer(isGonnaBeGran ? grans(`./low.gif`) : jiitas('./low.gif'))
        } else {
            setPlayer(isGonnaBeGran ? grans(`./idle.gif`) : jiitas('./idle.gif'))
        }
    }

    function showAlert(msg) {
        setVisibleAlert(true)
        setMessageAlert(msg)
    }

    return (
        <>
            {
                weaponList != null && stats != null && finalDate != null
                    ?
                    <>
                        <Helmet>
                            <title>Granbluedle - RudyTudySubs</title>
                        </Helmet>

                        <Dialog
                            open={tutorialDialog}
                            onClose={() => { setTutorialDialog(false) }}
                        >
                            <DialogTitle>{jpMode ? 'チュートリアル' : 'Tutorial'}</DialogTitle>
                            <DialogContent>
                                {jpMode ? 'も一度チュートリアルは最初にもどるかい？' : 'Do you want to see the tutorial again?'}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { setTutorialDialog(false); }}>
                                    {jpMode ? 'いいえ' : 'No'}
                                </Button>
                                <Button onClick={() => { setTutorialState(0); setTutorialDialog(false); }}>
                                    {jpMode ? 'はい' : 'Yes'}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            maxWidth='lg'
                            open={helpDialog}>
                            <DialogTitle>{jpMode ? 'グランブルーデュルーって何なの?' : 'What is this?'}</DialogTitle>
                            <DialogContent style={{ width: '100%' }} className='d-flex flex-column' dividers={true}>
                                {jpMode ? 'グランブルーデュルーはグランブルーファンタジーの武器を当てるクイズゲームです、HPが0になり倒されてしまう前に、デイリーの武器を当てる必要があります、武器を推測するたびに、その武器の様々カテゴリーが表示されます、そのカテゴリーが正解なら緑に染まります。' : "Granbluedle is a guessing game about Granblue Fantasy* weapons where you must correctly guess the day's weapon before your HP hits 0 and you are defeated. Each time you guess a weapon, you will get a set of answers based on its attributes, they will be highlighted in green if they match those of the target."}
                                <br /><br />
                                {jpMode ? "下にその例を示す、それでもまだわからない場合は、さらに下に各カテゴリーについて詳しくことが書いてます。" : "An example is presented below showing a sample run. If you are still confused, you can scroll down further to learn more about each of the categories."}
                                <img className='my-1' style={{ width: '100%' }} alt="example" src={images(jpMode ? './jp_example.png' : './example.png')} />
                                {jpMode ? "ハードモードについて、 初期設定ではストーリーイベントやリミテッド以外ガチャの武器は含まれない、ほとんど同じなのですから、ハードモードを有効にすると、これらを推測できるようになる。" : "Note on hard mode: By default weapons from story events and non-limited gacha are not included since they are almost all identical, enabling Hard Mode makes these guessable."}
                                <hr style={{ borderTop: '1px', borderStyle: 'solid' }} />
                                <h3>{jpMode ? "カテゴリー" : 'Categories'}</h3>
                                <hr style={{ borderTop: '1px', borderStyle: 'solid' }} />
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "武器" : "Weapon"}</h4>
                                {jpMode ? '推測された武器の画像と名前、その武器がキャラクターを解放した場合、そのキャラクターもここに表示される。' : "The image and name of the guessed weapon. If the weapon unlocks a character, it also be displayed here."}
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "登録日" : "Release"}</h4>
                                {
                                    jpMode
                                        ?
                                        <span>推測された武器の登録日付、初期の資料がないため、推定された値もある、下向きの矢印は対象の武器が推測された武器より<b>古いという意味</b>、上向きの矢印は対象の武器が推測された武器より<b>新しいという意味</b>、どちらかの方向に1つの矢印がある場合は、同じ年に登録されたことを示す。</span>
                                        :
                                        <span>The date the guessed weapon was first released. Some values have been estimated due to lack of early documentation. Downward arrows indicate that the target weapon is <b>older</b> than the guessed weapon, while Upward arrows indicate that the target weapon is <b>newer</b> than the guessed weapon. A single arrow in either direction indicates they released the same year.</span>
                                }

                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "属性" : "Element"}</h4>
                                {jpMode ? "推測された武器の属性、6つの属性のいずれでもある、全属性武器を入手できる場合は 「Any」でもある、推測された武器の属性がターゲットの武器の属性が強くか弱くいる場合、推測は部分的に正しいことがある。" : "The guessed weapon's element. It can be any of the six elements, or \"Any\" if the weapon can be obtained regardless of element. A guess can be partially correct if the element of the guessed weapon is superior or inferior to that of the target weapon."}
                                <br /><img className='mx-auto' style={{ maxWidth: '100%' }} alt="ele example" src={images(jpMode ? './jp_elements.png' : './elements.png')} /><br />
                                {
                                    jpMode
                                        ?
                                        <span>例として、<b>対象の武器</b>の属性が「水」の場合、「火」の武器を推測すると「RESIST」と表示され、「土」の武器を推測すると「WEAK POINT！」と表示される。
                                        </span>
                                        :
                                        <span>For example, if the <b>target</b> weapon's element is Water, a guess of a Fire weapon would show "RESIST" while an Earth guess would show "WEAK POINT!".</span>
                                }
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "種" : "Type"}</h4>
                                {jpMode ? "推測された武器の種。既存の10種の武器のいずれでもある。" : "The type of the guessed weapon. It can be any of the ten existing weapon types."}
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "シリーズ" : "Series"}</h4>
                                {jpMode ? "推測された武器のシリーズ。リスト内の武器の絞り込みに使用されるシリーズのいずれでもある。" : "The series of the guessed weapon. It can be any of the series used to filter weapons in the inventory."}
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "覚醒" : "Awakening"}</h4>
                                {jpMode ? "推測された武器に覚醒があるかどうか。" : "Whether the gussed weapon has awakening or not. This applies for both free to play and gacha based awakenings."}
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "上限解放" : "Max Uncap"}</h4>
                                {jpMode ? "推測された武器の最終上限解放。これは通常3から5の間の値だが、武器が超越をできる場合は6にもある。" : "The max uncap of the guessed weapon. This is usually a value between 3 and 5, but it has the option to be 6 if the weapon has Transcendence."}
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "入手方法" : "Obtain"}</h4>
                                {jpMode ? "推測された武器の入手方法。特定のマルチバトルからドロップするのではなく、「マルチバトル 」のように一般化されている。ノーマルモードでは以下の入手方法の武器は含めなく、 コラボイベント、ストーリーイベント、レジェンドガチャ、クラシックガチャ、ショップ･ポイント交換、サプライズチケット。" : "Where to obtain the guessed weapon. These have been largely generalized, such as \"Raid Drop\" instead of the specific raid it can drop from. In normal mode, weapons with the following obtain methods won't show up: Tie-In Event, Story Event, Premium Draw, Classic Draw, Shop (Point Shop), Surprise Ticket."}
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "スキル" : "Skills"}</h4>
                                {jpMode ? "推測された武器が持つスキル。例として、「紅蓮の」の代わりに 「神石(大)の」のように一般化されている。技術的な理由から、火属性にスキルが存在しない場合を除き、すべての武器スキルには火のアイコンが付いている。" : "Skills the guessed weapon has. These have also been generalized: for example, only showing \"Big Primal\" instead of \"Inferno's\". For technical reasons, all weapon skills have Fire icons unless the skill doesn't exist in Fire, in which case it follows the priority of Water > Earth > Wind > Light > Dark."}
                                <hr style={{ borderTop: '1px', borderStyle: 'solid' }} />
                                <h3>{jpMode ? "このゲームについて" : "About"}</h3>
                                <hr style={{ borderTop: '1px', borderStyle: 'solid' }} />
                                {
                                    jpMode
                                        ?
                                        <span>
                                            このゲームは、Wordleや特にIsaacleのような他のデイリー推理ゲームから大きなインスピレーションを得て、一人で作った情熱的なプロジェクトである。<a href="https://gbf.wiki/Main_Page">ENwiki</a>と<a href="https://gbf.huijiwiki.com/wiki/">CNwiki</a>の両方には、ここにすべての情報を提示するという重労働をこなしてくれたことに多大な感謝をしている。
                                        </span>
                                        :
                                        <span>This game is a passion project made by one person taking great inspiration from other daily guessing games such as Wordle and especially Isaacle, immense thanks to both <a href="https://gbf.wiki/Main_Page">the EN wiki</a> as well as <a href="https://gbf.huijiwiki.com/wiki/">the CN wiki</a> for having more information than the Library of Alexandria and doing the heavy work of having all the information presented here, that being said there was room for human error so if you notice something wrong feel free to contact me about it.</span>
                                }
                                <hr style={{ borderTop: '1px', borderStyle: 'solid' }} />
                                <h3>{jpMode ? "クッキー" : "Cookies"}</h3>
                                <hr style={{ borderTop: '1px', borderStyle: 'solid' }} />
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "サイトのクッキー" : "Site Cookies"}</h4>
                                {jpMode ? "当サイトでは、クッキーを使用してお客様の設定を保存し、複数のセッションを記録しています。" : "The website makes use of cookies to save your preferences as well as record keeping over multiple sessions."}
                                <h4 style={{ fontWeight: 'bold' }}>{jpMode ? "第三者のクッキー" : "Third Party Cookies"}</h4>
                                {jpMode ? "当サイトでは、正解を見つけるための平均推測回数や、各奥義が他の奥義と比較してどの程度好まれているかの評価など、ユーザーの体験を追跡するためにGoogle Analyticsを使用しています。 Google Analyticsのクッキーの詳細については、Google Analyticsの公式ページをご覧ください。" : "The website makes use of Google Analytics for tracking user experiences such as average guess count to find the right answer or evaluating how preffered each SBA is compared to the others, the website contains no ads nor will it ever do so.  For more information on Google Analytics cookies, see the official Google Analytics page."}
                            </DialogContent>
                            <DialogActions>
                                <span className='col-9'>{jpMode ? '※グランブルーファンタジーは株式会社Cygames所有しており、グランブルーデュルーに一は切関係ありません。当サイト上で使用しているゲーム画像の著作権および商標権、その他知的財産権は、当該コンテンツの提供元に帰属します。' : '*Granblue Fantasy is the copyright of Cygames Inc. and not affiliated in any way with Granbluedle. Granblue Fantasy content and materials are trademarks and copyrights of Cygames, Inc. or its licensors. All rights reserved.'}</span>
                                <Button className='col-2' onClick={() => { setHelpDialog(false) }}>
                                    {jpMode ? '分かりました' : 'Understood'}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <div className='prt-result-cnt pt-4 pb-5 mb-2' style={{ color: 'white' }}>
                            <img src={images('./logo.png')} alt='logo' />
                            <div className='mt-2 d-flex justify-content-center flex-wrap'>
                                <Button variant='contained' color={hardMode ? 'error' : 'primary'} className="ms-2" disableRipple onClick={() => { setHelpDialog(true); }}>
                                    <QuestionMarkIcon />
                                </Button>
                                <Button style={{ color: 'white', textDecoration: 'none' }} variant='contained' color={hardMode ? 'error' : 'primary'} className="ms-2" disableRipple
                                    href={endlessMode ? '/granbluedle' : '/granbluedle/endless'}
                                >
                                    {endlessMode ? jpMode ? 'デイリー' : 'Daily' : jpMode ? 'エンドレス' : 'Endless'}
                                </Button>
                                <Button variant='contained' color={hardMode ? 'error' : 'primary'} className="ms-2" disableRipple
                                    onClick={() => { const hard = !hardMode; setHardMode(hard); }}
                                    href={endlessMode ? '/granbluedle/endless' : '/granbluedle'}
                                >
                                    {hardMode ? jpMode ? 'ノーマル' : 'Normal' : jpMode ? 'ハード' : 'Hard'}
                                </Button>
                                <Button variant='contained' color={hardMode ? 'error' : 'primary'} className="ms-2" disableRipple
                                    onClick={() => { const jp = !jpMode; setJpMode(jp); }}>
                                    <LanguageIcon />
                                </Button>
                                <Button variant='contained' color={hardMode ? 'error' : 'primary'} className="ms-2" disableRipple
                                    onClick={() => { setTutorialDialog(true); }}>
                                    <QuizIcon />
                                </Button>
                            </div>
                            <div className='mt-2 col-12'>{jpMode ? 'グランブルーファンタジーの武器を当てるクイズゲーム、グランブルーデュルーへようこそ！' : "Welcome to Granbluedle, the Granblue Fantasy weapon guessing game!"}<br />
                                {
                                    jpMode
                                        ?
                                        <span>
                                            現在は{hardMode ? <span style={{ color: 'red' }}>ハード</span> : ''}{!endlessMode ? `第${stats.currentDay + 1}回デイリー` : 'エンドレスモード'}を遊んでいる
                                        </span>
                                        :
                                        <span>
                                            You're currently playing: {hardMode ? <span style={{ color: 'red' }}>Hard </span> : <></>} {!endlessMode ? `Daily #${stats.currentDay + 1}` : 'Endless mode'}.
                                        </span>
                                }
                                {
                                    !endlessMode
                                        ?
                                        <>
                                            <br /> {jpMode ? <span>デイリーは毎日<b>午前5時（日本時間）</b>に更新</span> : <span>New daily every day at <b>05:00AM JST.</b></span>}
                                            <br /> {jpMode ? 'カウントダウンが終わるとデイリー武器が変わる ' : 'New daily weapon to guess in: '}
                                            <Countdown daysInHours date={finalDate} onComplete={() => { window.location.reload() }}>
                                                <p>Refresh the page!</p>
                                            </Countdown>
                                        </> : <></>}
                                <br /><br />
                                {
                                    jpMode
                                        ?
                                        <>
                                            <span>現在、推測できる武器は{stats.normalWeapons}種類！（ハードのみ武器含めて{stats.hardWeapons}種類です）</span>
                                            <br /><span>このリストの最終更新日は{getFormattedDateJP(new Date(stats.lastUpdate))}</span>
                                        </>
                                        :
                                        <>
                                            <span>Currently there's {stats.normalWeapons} ({stats.hardWeapons} in hard mode) weapons to guess!</span>
                                            <br /><span>This list was last updated on {getFormattedDate(new Date(stats.lastUpdate))}.</span>
                                        </>
                                }

                            </div>



                        </div>

                        {
                            gameState > 0
                                ?
                                <div ref={questResults} style={{ color: 'white' }} className='prt-result-cnt pt- d-flex flex-column'>
                                    <div className='col-8 mx-auto mt-4'>
                                        {
                                            metaStats != null
                                                ?
                                                <>
                                                    <div style={{ minHeight: '112px' }}>
                                                        <div className='col-12 d-flex'>
                                                            <img className='mx-auto mt-3' alt='resultsheader' src={images('./resultsheader.png')} />
                                                        </div>
                                                        <div className='col-12 mt-3 d-flex'>
                                                            <img className='mx-auto ' alt='resultimagecaptain' src={isGran ? grans('./results.jpg') : jiitas('./results.jpg')} />
                                                            <div className='mt-2' style={{ width: '150px', position: 'absolute', left: width < 450 ? '40%' : width < 600 ? '48%' : '53%', fontStyle: 'italic', textShadow: 'text-shadow: 0px 0px 1px #150f0f, 0px 0px 1px #150f0f, 0px 0px 1px #150f0f, 0px 0px 2px #150f0f, 0px 0px 2px #150f0f, 0px 0px 2px #150f0f;' }}>
                                                                <div className='d-flex justify-content-between'>
                                                                    <span>Rank</span>
                                                                    <span className='me-2'>{metaStats.oldrank} {metaStats.rank > metaStats.oldrank ? <span className='new-rank' style={{ color: '#42e5ff' }}>-&gt; {metaStats.rank}</span> : ''}</span>
                                                                </div>
                                                                <div style={{ marginTop: '2px', width: '100%', height: '4px', backgroundImage: 'linear-gradient(#150f0f 0%, #1f1b18 60%)' }}>
                                                                    <div style={{ width: `${(metaStats.rp / 4000) * 100}%`, marginTop: '-4px', height: '100%', backgroundImage: 'linear-gradient(#a6a585 17%, #b3b2a8 30%, #997b42 44%, #663b0e 100%)' }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='pop-usual pop-exp pop-show mt-4'>
                                                        <div className='prt-popup-header'>
                                                            {jpMode ? '獲得経験値' : 'EXP Gained'}
                                                        </div>
                                                        <div className='exptext prt-popup-body'>
                                                            <div className='d-flex justify-content-around '>
                                                                <div className='d-flex col-4 justify-content-end'>
                                                                    <div className='d-flex'>
                                                                        <img alt="rp" src={images(jpMode ? './jp_rp.png' : './rp.png')} />
                                                                        {
                                                                            enemyName === 'kinggold' || enemyName === 'kingsilver'
                                                                                ?
                                                                                <img id='rp' alt="mult" src={images(enemyName === 'kinggold' ? jpMode ? './jp_2x.png' : './2x.png' : jpMode ? './jp_15x.png' : './15x.png')} />
                                                                                :
                                                                                <></>
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <span className='my-auto col-6 text-start'> +
                                                                    {enemyName === 'kinggold' ? Math.floor(metaStats.rpToEarn / 2) : enemyName === 'kingsilver' ? Math.floor(metaStats.rpToEarn / 1.5) : metaStats.rpToEarn}
                                                                    {enemyName === 'kinggold' || enemyName === 'kingsilver' ?
                                                                        <span style={{ color: '#ffa826' }}> ({jpMode ? 'ボーナス' : 'Bonus'} +{enemyName === 'kinggold' ? Math.floor(metaStats.rpToEarn / 2) : enemyName === 'kingsilver' ? Math.floor((metaStats.rpToEarn / 1.5) / 2) : metaStats.rpToEarn})</span> : <></>}</span>
                                                            </div>
                                                            <div className='d-flex justify-content-around'>
                                                                <div className='d-flex col-4 justify-content-end'>
                                                                    <img alt="rp" src={images('./exp.png')} />
                                                                </div>
                                                                <span className='my-auto col-6 text-start'> +{metaStats.exp}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            weaponToGuess
                                                ?
                                                <div className='pop-usual pop-exp pop-show mt-4'>
                                                    <div className='prt-popup-header'>
                                                        {jpMode ? 'アイテム詳細' : 'Item Details'}
                                                    </div>
                                                    <div className='prt-popup-body'>
                                                        <a href={`https://gbf.wiki/${weaponToGuess.weapon_name}`} target='_blank' rel='noreferrer' style={{textDecoration: 'none'}}>
                                                            <div className='d-flex justify-content-around'>
                                                                <img alt='winningweapon' src={wpn(`./${weaponToGuess.id}.png`)} />
                                                            </div>
                                                            <div className='d-flex justify-content-center mt-1 flex-wrap'>
                                                                <span style={{color:'white'}}>{jpMode ? weaponToGuess.jpname : weaponToGuess.weapon_name}</span>
                                                                <img className='ms-1' alt='weapontype' src={typeIcon(weaponToGuess.type, jpMode)} />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div className='col-8 mx-auto mt-2'>
                                        {
                                            metaStats != null
                                                ?
                                                <div id="player-stats" className=' mt-3 cnt-get-treasure common-frame'>
                                                    <div className='txt-treasure-title'>{jpMode ? 'プレイヤー' : 'Player Stats'}</div>
                                                    <div className='cnt-treasure-contents'>
                                                        <div className='prt-get-lupi'>
                                                            <div className='prt-get-lupi-title'>{jpMode ? '試合数' : 'Games Played'}</div>
                                                            <div className='txt-get-lupi-value'>{metaStats.gamesPlayed}</div>
                                                        </div>
                                                        <div className='prt-get-lupi'>
                                                            <div className='prt-get-lupi-title'>{jpMode ? '勝利数' : 'Games Won'}</div>
                                                            <div className='txt-get-lupi-value'>{metaStats.gamesWon}</div>
                                                        </div>
                                                        <div className='prt-get-lupi'>
                                                            <div className='prt-get-lupi-title'>{jpMode ? '現在の連勝' : 'Current Streak'}</div>
                                                            <div className='txt-get-lupi-value'>{metaStats.currStreak}</div>
                                                        </div>
                                                        <div className='prt-get-lupi'>
                                                            <div className='prt-get-lupi-title'>{jpMode ? '史上最高の連勝' : 'Max Streak'}</div>
                                                            <div className='txt-get-lupi-value'>{metaStats.maxStreak}</div>
                                                        </div>
                                                        <div className='prt-get-lupi'>
                                                            <div className='prt-get-lupi-title'>{jpMode ? 'お気に奥義' : 'Favorite SBA'}</div>
                                                            <div className='txt-get-lupi-value'>{metaStats.hintDesc === mostUsed ? '📓' : ''}
                                                                {metaStats.hintSkills === mostUsed ? '🔠' : ''}
                                                                {metaStats.hintImage === mostUsed ? '📷' : ''}
                                                                {metaStats.hintName === mostUsed ? '⌨️' : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        <Button onClick={() => { copyPlayerStats() }}
                                            disableRipple style={{ backgroundImage: `url(${images('./button.png')}` }} className='mt-2 gbf-button'>
                                            <span>{jpMode ? 'シェアする' : 'Share'}</span>
                                        </Button>
                                        <Zoom in={copiedPlayerStats}>
                                            <h5>{jpMode ? 'クリップボードにコピーしました！' : 'Copied to clipboard!'}</h5>
                                        </Zoom>
                                        <div id="game-stats" className='cnt-get-treasure common-frame mt-4'>
                                            <div className='txt-treasure-title'>{jpMode ? 'ゲーム' : 'Game Stats'}</div>
                                            <div className='cnt-treasure-contents'>
                                                {
                                                    jpMode
                                                        ?
                                                        HP === 0 ? <span>参加者募集！<br />#Granbluedle</span> : <span>{enemyName === 'kinggold' ? 'キングゴールドスライム' : enemyName === 'kingsilver' ? 'キングシルバースライム' : enemyName === 'kingbronze' ? 'うたたねアンディ' : 'スライム'}を倒した!<br />#Granbluedle</span>
                                                        :
                                                        HP === 0 ? <span>I need backup!<br />#Granbluedle</span> : <span>I defeated a {enemyName === 'kinggold' ? 'King Goldslime' : enemyName === 'kingsilver' ? 'King Silverslime' : enemyName === 'kingbronze' ? 'Drowsy Drew' : 'Slime'}!<br />#Granbluedle</span>
                                                }
                                                {
                                                    endlessMode ? <></> : <span>{jpMode ? ` 第${stats.currentDay + 1}回デイリー` : ` Daily #${stats.currentDay + 1}`}  {hardMode ? `(${jpMode ? 'ハード' : 'hard mode'})` : ''}</span>
                                                }
                                                <br /><span>❓: {guessList.length} ❤️: {(HP / 16500 * 100).toFixed(0)}%</span>
                                                <span> ⚔️: {hintDesc != null ? '📓' : ''}{hintSkills != null ? '🔠' : ''}{hintImage != null ? '📷' : ''}{hintName != null ? '⌨️' : ''}
                                                    {hintDesc == null && hintSkills == null && hintImage == null & hintName == null ? '❌' : ''}</span><br />
                                                {

                                                    guessList.map(({ sheet }) => (<span>
                                                        {
                                                            sheet.release === 'Higher Close' ? '🔼' :
                                                                sheet.release === 'Higher Far' ? '⏫' :
                                                                    sheet.release === 'Lower Close' ? '🔽' :
                                                                        sheet.release === 'Lower Far' ? '⏬' :
                                                                            '🟩'
                                                        }
                                                        {
                                                            sheet.element === 1 ? '🟩' : sheet.element === 2 || sheet.element === 3 ? '🟧' : '🟥'
                                                        }
                                                        {
                                                            sheet.type ? '🟩' : '🟥'
                                                        }
                                                        {
                                                            sheet.series ? '🟩' : '🟥'
                                                        }
                                                        {
                                                            sheet.awakening ? '🟩' : '🟥'
                                                        }
                                                        {
                                                            sheet.uncap ? '🟩' : '🟥'
                                                        }
                                                        {
                                                            sheet.obtains === 'None' ? '🟥' :
                                                                sheet.obtains === 'Partial' ? '🟧' :
                                                                    '🟩'
                                                        }
                                                        {
                                                            sheet.skills === 'None' ? '🟥' :
                                                                sheet.skills === 'Partial' ? '🟧' :
                                                                    '🟩'
                                                        }
                                                        <br />
                                                    </span>))
                                                }
                                            </div>
                                        </div>
                                        {
                                            endlessMode
                                                ?
                                                <>
                                                    <span>{jpMode ? 'エンドレスでシェアすると、さっき当てた武器へのURLが表示されます' : 'Sharing on Endless will give you a link to the weapon you just guessed.'}</span><br />
                                                </>
                                                :
                                                <></>
                                        }
                                        <Button onClick={() => { copyGameStats() }}
                                            disableRipple style={{ backgroundImage: `url(${images('./button.png')}` }} className='mt-2 gbf-button'>
                                            <span>{jpMode ? 'シェアする' : 'Share'}</span>
                                        </Button>

                                        <Zoom in={copiedGameStats}>
                                            <h5>{jpMode ? 'クリップボードにコピーしました！' : 'Copied to clipboard!'}</h5>
                                        </Zoom>
                                        {
                                            endlessMode
                                                ?
                                                <></>
                                                :
                                                <>
                                                    <br /><br />
                                                    {
                                                        jpMode
                                                            ?
                                                            <span className='mt-2'>新たな挑戦は<Countdown daysInHours date={finalDate} />に登場する！<br />とりあえず、今日の{hardMode ? 'ノーマル' : 'ハード'}の武器に挑戦してみたらどうですか？</span>
                                                            :
                                                            <span className='mt-2'>A new challenge will be available in <Countdown daysInHours date={finalDate} />!<br />In the meantime, how about attempting today's {hardMode ? 'normal' : 'hard'} weapon?</span>
                                                    }
                                                </>
                                        }
                                        <div className='mt-3 d-flex justify-content-around flex-wrap mx-auto'>
                                            {
                                                endlessMode
                                                    ?
                                                    <></>
                                                    :
                                                    <Button onClick={() => { const hard = !hardMode; setHardMode(hard); window.location.reload(); }}
                                                        disableRipple style={{ backgroundImage: `url(${images('./repeatbutton.png')}` }} className='mt-2 gbf-button'>
                                                        <span>{hardMode ? jpMode ? 'ノーマルへ' : 'Normal' : jpMode ? 'ハードへ' : 'Hard'}</span>
                                                    </Button>
                                            }
                                            <Button href='/granbluedle/endless'
                                                disableRipple style={{ backgroundImage: `url(${images(endlessMode ? './repeatbutton.png' : './button.png')}` }} className='mt-2 gbf-button'>
                                                <span>{endlessMode ? jpMode ? 'もう一度挑戦' : 'Play Again' : jpMode ? 'エンドレスへ' : 'Endless Mode'}</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }

                        <Snackbar
                            open={visibleAlert}
                            autoHideDuration={5000}
                            TransitionComponent={Slide}
                            onClose={() => { setVisibleAlert(false) }}>
                            <Alert
                                severity="warning"
                                variant="filled"
                                sx={{ width: '100%' }}
                            >
                                {messageAlert}
                            </Alert>
                        </Snackbar>

                        <div className={`col-12 d-flex flex-row justify-content-around ${width < 1260 ? 'flex-wrap' : ''}`} style={{ maxHeight: '85%' }}>
                            <div className='minier-grub' style={{ width: '500px' }}>
                                <div className='ms-2 border border-2 rounded' style={{ width: '100%', height: width < 575 ? '1050px' : '900px' }}
                                    ref={winScreenMove}>
                                    <div className='col-12 d-flex flex-column justify-content-between' style={{ height: '100%', backgroundColor: 'black' }}>
                                        <div className='d-flex flex-column justify-content-between' style={{ height: '55%', backgroundImage: ougimotion ? isGran ? `url("${grans('./ougi.gif')}")` : `url("${jiitas('./ougi.gif')}")` : 'url("https://prd-game-a4-granbluefantasy.akamaized.net/assets_en/img/sp/raid/bg/event_82.jpg")', backgroundSize: 'cover' }} >
                                            <div style={{ width: '100%', backgroundSize: 'contain', height: '20%', backgroundRepeat: 'no-repeat', backgroundImage: 'url("https://prd-game-a-granbluefantasy.akamaized.net/assets_en/img_mid/sp/raid/headrer_bg.png?1644906886")' }}>
                                                <Button className="ms-2" disableRipple onClick={() => { changeChar() }}>
                                                    <img src={isGran ? (images(`./female.png`)) : (images(`./male.png`))} alt='Change Character' />
                                                </Button>
                                            </div>
                                            <div className='mt-2 pt-3 d-flex justify-content-between'>
                                                <div className='' style={{ position: 'absolute', height: '222px', }}>
                                                    <CrossfadeImage duration={100} timingFunction="ease-in" src={enemy} alt='enemy' />
                                                </div>
                                                <div></div>
                                                <div className='mt-4' style={{ height: ougimotion ? '1px' : '180px', width: 'auto' }}>
                                                    <CrossfadeImage duration={100} className='mt-5' src={player} alt='player' />
                                                </div>
                                                <div style={{ position: 'absolute' }}>
                                                    <img className='winloseimg' alt="winlose" style={{
                                                        opacity: gameState === 0 ? '0.0' : '1.0',
                                                        transitionProperty: 'opacity', transitionTimingFunction: 'ease',
                                                        transitionDelay: '1s', transitionDuration: '5s'
                                                    }}
                                                        src={images(jpMode ? gameState === 1 ? './jp_win.png' : './jp_lose.png' : gameState === 1 ? './win.png' : './lose.png')} />
                                                </div>
                                            </div>
                                            <div style={{ height: '130px' }}>
                                                <Zoom in={secondVisible}>
                                                    <Alert severity='error' color='success' className='mb-1'>
                                                        {secondMessage}
                                                    </Alert>
                                                </Zoom>
                                                <Zoom in={firstVisible}>
                                                    <Alert severity='error' color='success'>
                                                        {firstMessage}
                                                    </Alert>
                                                </Zoom>
                                            </div>
                                        </div>
                                        <Autocomplete
                                            id="weapon-selector"
                                            sx={{ width: '100%' }}
                                            filterOptions={(options, state) => {
                                                if (state.inputValue.trim().length === 0) {
                                                    return defaultFilterOptions(options, state).slice(0, 0)
                                                }
                                                return filterOptions(options, state)
                                            }}
                                            clearOnEscape
                                            blurOnSelect
                                            options={weaponList}
                                            value={value}
                                            disabled={lockout || gameState > 0}
                                            isOptionEqualToValue={(option, value) => value == null ? false : option.weapon_name === value.weapon_name}
                                            getOptionLabel={(option) => option.blank ? '' : jpMode ? (option.jpname + " | " + option.characterjp) : (option.weapon_name + " | " + option.character_name)}
                                            disableClearable
                                            forcePopupIcon={false}
                                            noOptionsText={jpMode ? '条件に合う武器がありません' : 'There are no matching weapons.'}
                                            renderOption={(props, weapon) => (
                                                <Box style={{ display: 'flex', fontSize: '15px' }} component="li" {...props}>
                                                    <div className='col-3'>
                                                        <img
                                                            loading="lazy"
                                                            width={90}
                                                            src={wpn(`./${weapon.id}.png`)}
                                                            alt={weapon.weapon_name}
                                                        />
                                                    </div>
                                                    <div className='col-5'>
                                                        {jpMode ? weapon.jpname : weapon.weapon_name}
                                                    </div>
                                                    {
                                                        (weapon.character_name != null)
                                                            ?
                                                            <>
                                                                <div className='col-2' style={{ borderLeft: '2px dotted black' }}>
                                                                    <img
                                                                        className='ps-2'
                                                                        loading="lazy"
                                                                        width={50}
                                                                        src={char(`./${weapon.character_name}${weapon.weapon_name}.png`)}
                                                                        alt={weapon.character_name}
                                                                    />
                                                                </div>
                                                                <div className='col-2'>
                                                                    {jpMode ? weapon.characterjp : weapon.character_name}
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className='col-2'></div>
                                                                <div className='col-2'></div>
                                                            </>
                                                    }

                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={jpMode ? 'ここに推測を入力する！' : 'Enter your guess here!'}
                                                    style={{ backgroundColor: lockout || gameState > 0 ? 'gray' : 'white' }}
                                                    value={undefined}
                                                    inputRef={inputRef}
                                                    autoComplete='off'
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            )}
                                            onChange={guessSelect}
                                        />
                                        <div className='d-flex flex-row col-12 pb-1 pt-1' style={{ background: 'linear-gradient(0deg, black, rgb(29,34,35))' }}>
                                            <div className='d-flex flex-column col-2 ms-2 justify-content-end' style={{ backgroundSize: 'cover', backgroundImage: isGran ? 'url("https://prd-game-a1-granbluefantasy.akamaized.net/assets_en/img_mid/sp/assets/leader/raid_normal/310901_sw_0_01.jpg")' : 'url("https://prd-game-a1-granbluefantasy.akamaized.net/assets_en/img_mid/sp/assets/leader/raid_normal/310901_sw_1_01.jpg")' }} >
                                                <div className='d-flex flex-row-reverse flex-wrap-reverse'>
                                                    {guts ? <img width={25} src={images('./guts.png')} alt="guts" /> : <></>}
                                                    {hintDesc != null ? <img width={25} src={images('./hintDesc.png')} alt="hintDesc" /> : <></>}
                                                    {hintName != null ? <img width={25} src={images('./hintName.png')} alt="hintName" /> : <></>}
                                                    {hintSkills != null ? <img width={25} src={images('./hintSkills.png')} alt="hintSkills" /> : <></>}
                                                    {hintImage != null ? <img width={25} src={images('./hintImage.png')} alt="hintImage" /> : <></>}
                                                </div>
                                                <span className='me-1' style={{ fontWeight: 'bold', color: 'white', textAlign: 'end', marginBottom: '-0.4rem', textShadow: '0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d' }}>{HP}</span>
                                                <img height={6} src='https://prd-game-a-granbluefantasy.akamaized.net/assets_en/img_mid/sp/raid/status_hp.png?1644906887' alt='hp' />
                                                <span className='me-1' style={{ color: 'white', textAlign: 'end', marginBottom: '-1.5rem', textShadow: '0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d' }}>{CA}%</span>
                                                <div className='d-flex mb-4 align-items-end'>
                                                    <span className='col-6' style={{ marginBottom: '-0.1rem', color: 'white', textShadow: '0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d' }}>{jpMode ? '奥義' : 'C.A.'}</span>
                                                    <img className='col-6' height={6} src='https://prd-game-a1-granbluefantasy.akamaized.net/assets_en/img_mid/sp/raid/cjs_gauge/mode_gauge_yel.png' alt='ca' />
                                                </div>
                                            </div>
                                            <img className='col-2 ms-2' src='https://prd-game-a1-granbluefantasy.akamaized.net/assets_en/img_mid/sp/assets/npc/raid_normal/3999999999.jpg' alt='empty' />
                                            <img className='col-2 ms-2' src='https://prd-game-a1-granbluefantasy.akamaized.net/assets_en/img_mid/sp/assets/npc/raid_normal/3999999999.jpg' alt='empty' />
                                            <img className='col-2 ms-2' src='https://prd-game-a1-granbluefantasy.akamaized.net/assets_en/img_mid/sp/assets/npc/raid_normal/3999999999.jpg' alt='empty' />
                                            <img className='col-2 ms-4' src={(images(`./summon.png`))} alt='summon' />
                                        </div>
                                        <div className='d-flex flex-row col-12 object-fit-fill flex-wrap'>
                                            <div className='col-12 d-flex' style={{
                                                fontWeight: 'bolder', fontSize: '2rem', color: 'gold', fontFamily: 'windlassregular arial',
                                                textShadow: '0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d'
                                            }}><div className='skybound-arts m-auto'>{jpMode ? '奥義' : 'Skybound Arts'}</div></div>
                                            <div className='d-flex col-12 object-fit-fill justify-content-evenly flex-wrap ougibox' style={{ flex: 1 }}>
                                                <Button variant='contained' className='d-flex border border-2 rounded-pill col-11 col-sm-6'
                                                    onClick={() => { lockout || gameState > 0 ? console.log('') : (CA < 200 || hintDesc != null) ? showAlert(`${hintDesc != null ? jpMode ? 'この奥義はもう発動しました' : "You've already used this!" : jpMode ? 'この奥義を使用するには奥義ゲージ200％が必要です' : 'You need 200% CA to use this Skybound Art.'}`) : ougi(2) }}
                                                    style={{
                                                        color: CA < 200 || hintDesc != null ? 'rgb(255 255 255 / 50%)' : 'white', textAlign: 'center',
                                                        background: CA < 200 || hintDesc != null ? 'linear-gradient(0deg, rgb(86 20 4 / 50%), rgb(247 196 97 / 50%))' : 'linear-gradient(0deg, rgb(86 20 4), rgb(247 196 97))',
                                                        textShadow: CA < 200 || hintDesc != null ? '' : '0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d'
                                                    }}
                                                >
                                                    <div className='m-auto'>{jpMode ? 'ルリアノートを見せる' : 'Show Description'}</div>
                                                </Button>
                                                <Button variant='contained' className='d-flex border border-2 rounded-pill col-11 col-sm-6'
                                                    onClick={() => { lockout || gameState > 0 ? console.log('') : (CA < 100 || hintSkills != null) ? showAlert(`${hintSkills != null ? jpMode ? 'この奥義はもう発動しました' : "You've already used this!" : jpMode ? 'この奥義を使用するには奥義ゲージ100％が必要です' : 'You need 100% CA to use this Skybound Art.'}`) : ougi(4) }}
                                                    style={{
                                                        color: CA < 100 || hintSkills != null ? 'rgb(255 255 255 / 50%)' : 'white', textAlign: 'center',
                                                        background: CA < 100 || hintSkills != null ? 'linear-gradient(0deg, rgb(86 20 4 / 50%), rgb(247 196 97 / 50%))' : 'linear-gradient(0deg, rgb(86 20 4), rgb(247 196 97))',
                                                        textShadow: CA < 100 || hintSkills != null ? '' : '0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d'
                                                    }}
                                                >
                                                    <div className='m-auto'>{jpMode ? 'スキルを見せる' : 'Show Skills'}</div>
                                                </Button>
                                                <Button variant='contained' className='d-flex border border-2 rounded-pill col-11 col-sm-6'
                                                    onClick={() => { lockout || gameState > 0 ? console.log('') : (CA < 200 || hintImage != null) ? showAlert(`${hintImage != null ? jpMode ? 'この奥義はもう発動しました' : "You've already used this!" : jpMode ? 'この奥義を使用するには奥義ゲージ200％が必要です' : 'You need 200% CA to use this Skybound Art.'}`) : ougi(1) }}
                                                    style={{
                                                        color: CA < 200 || hintImage != null ? 'rgb(255 255 255 / 50%)' : 'white', textAlign: 'center',
                                                        background: CA < 200 || hintImage != null ? 'linear-gradient(0deg, rgb(86 20 4 / 50%), rgb(247 196 97 / 50%))' : 'linear-gradient(0deg, rgb(86 20 4), rgb(247 196 97))',
                                                        textShadow: CA < 200 || hintImage != null ? '' : '0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d'
                                                    }}
                                                >
                                                    <div className='m-auto'>{jpMode ? 'ぼやけた画像を見せる' : 'Show Blurry Image'}</div>
                                                </Button>
                                                <Button variant='contained' className='d-flex border border-2 rounded-pill col-11 col-sm-6'
                                                    onClick={() => { lockout || gameState > 0 ? console.log('') : (CA < 100 || hintName != null) ? showAlert(`${hintName != null ? jpMode ? 'この奥義はもう発動しました' : "You've already used this!" : jpMode ? 'この奥義を使用するには奥義ゲージ100％が必要です' : 'You need 100% CA to use this Skybound Art.'}`) : ougi(3) }}
                                                    style={{
                                                        color: CA < 100 || hintName != null ? 'rgb(255 255 255 / 50%)' : 'white', textAlign: 'center',
                                                        background: CA < 100 || hintName != null ? 'linear-gradient(0deg, rgb(86 20 4 / 50%), rgb(247 196 97 / 50%))' : 'linear-gradient(0deg, rgb(86 20 4), rgb(247 196 97))',
                                                        textShadow: CA < 100 || hintName != null ? '' : '0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 1px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d,0 0 2px #0c320d'
                                                    }}
                                                >
                                                    <div className='m-auto'>{jpMode ? '文字を見せる' : 'Show Letters'}</div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Accordion className='ms-2 my-2' style={{ width: '100%', }}
                                    expanded={hintDialogOpen} onChange={() => { setHintDialogOpen(v => !v) }}
                                >
                                    <AccordionSummary

                                        expandIcon={<ArrowDownwardIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography>{jpMode ? 'ヒント' : 'Hints'}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails ref={acc} className='text-center'>
                                        <p>{jpMode ? '奥義ゲージを100％または200％消費するで、奥義を発動するに対象武器のヒントを手に入れることができます' : 'You can get access to hints about the target weapon upon using your Skybound Arts by spending 100% or 200% of your CA bar, they will appear here once you do so.'}</p>
                                        <h6 style={{ fontWeight: 'bold' }}>{jpMode ? '名前' : 'Name'}</h6>
                                        <p>{hintName == null ? jpMode ? '未確定' : 'Not Unlocked.' : <b>{jpMode ? hintName.jp : hintName.description}</b>}</p>
                                        <h6 style={{ fontWeight: 'bold' }}>{jpMode ? '画像' : 'Image'}</h6>
                                        {
                                            hintImage == null ? <p>{jpMode ? '未確定' : 'Not Unlocked.'}</p> : <img src={hintImage} alt="Weapon Hint" />
                                        }
                                        <h6 style={{ fontWeight: 'bold' }}>{jpMode ? 'ルリアノート' : 'Description'}</h6>
                                        <p>{hintDesc == null ? jpMode ? '未確定' : 'Not Unlocked.' : jpMode ? hintDesc.jp : hintDesc.description}</p>
                                        <h6 style={{ fontWeight: 'bold' }}>{jpMode ? 'スキル' : 'Skills'}</h6>
                                        {
                                            hintSkills == null ? <p>{jpMode ? '未確定' : 'Not Unlocked.'}</p> : (
                                                <div className='my-auto text-left mx-1' >
                                                    {
                                                        hintSkills.map(sk => <div key={sk.name}><img src={jpMode ? skilljp(`./${sk.jp}.png`) : skill(`./${sk.name}.png`)} alt={jpMode ? sk.jp : sk.name} width='10%' /> {jpMode ? sk.jp : sk.name}</div>)
                                                    }
                                                </div>
                                            )
                                        }

                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className='ps-2 mini-grub' style={{ minWidth: '810px', maxWidth: '1300px', width: '80%' }} >
                                <div className='gridsheet-answers' style={{ height: '90vh', width: '100%', overflow: 'auto' }}>
                                    <GuessSheet key='header' guesssheet={{ header: true }} jpMode={jpMode} />
                                    {
                                        guessList.map((guess) => (<GuessSheet key={guess.guess.weapon_name} guesssheet={guess} jpMode={jpMode} />))
                                    }
                                </div>
                                <div className=' my-2 col-12 d-flex flex-row justify-content-around' >
                                    <div className='prt-result-cnt fw-bold col-10 pb-5' style={{ minHeight: '20vh' }}>
                                        <h5 className='m-md-2 color-code' style={{ color: 'white' }}>{jpMode ? 'カラーコード' : 'Color Code'}</h5>
                                        <div className='col-12 d-flex justify-content-center flex-wrap px-5 px-lg-2 mt-3  mt-lg-0'>
                                            <div className='d-flex col-4 col-lg-2 border border-2 border-secondary' style={{ backgroundColor: 'rgb(74 222 128)' }}>
                                                <div className='m-auto'>{jpMode ? '正解' : 'Correct'}<br /><p className='fw-normal'>{jpMode ? '内容はすべて対象武器と一致' : 'All contents match with target weapon.'}</p></div>
                                            </div>
                                            <div className='d-flex col-4 col-lg-2 border border-2 border-secondary ms-lg-3' style={{ backgroundColor: 'rgb(251 146 60)' }}>
                                                <div className='m-auto'>{jpMode ? '不全' : 'Partial'}<br /><p className='fw-normal'>{jpMode ? '内容の一部が一致するか、属性が対象武器に関連している' : 'Some of the contents match, or the element is related to the target weapon.'}</p></div>
                                            </div>
                                            <div className='d-flex col-4 col-lg-2 border border-2 border-secondary ms-lg-3' style={{ backgroundColor: 'rgb(251 146 60)' }}>
                                                <div className='m-auto px-1'>{jpMode ? '近い' : 'Close'} <ArrowUpwardIcon /><br /><p className='fw-normal'>{jpMode ? '対象武器の登録年は推測と同じ年である' : 'Release date of target weapon is the same year as the guess.'}</p></div>
                                            </div>
                                            <div className='d-flex col-4 col-lg-2 border border-2 border-secondary ms-lg-3' style={{ backgroundColor: 'rgb(248 113 113)' }}>
                                                <div className='m-auto'>{jpMode ? '遠い' : 'Far'} <ArrowUpwardIcon /><ArrowUpwardIcon /><p className='fw-normal'>{jpMode ? '対象武器の登録年は推測とは異なる' : 'Release date of target weapon is a different year as the guess.'}</p> </div>
                                            </div>
                                            <div className='d-flex col-4 col-lg-2 border border-2 border-secondary ms-lg-3' style={{ backgroundColor: 'rgb(248 113 113)' }}>
                                                <div className='m-auto'>{jpMode ? '違い' : 'Wrong'}<p className='fw-normal'>{jpMode ? 'いずれの内容も対象武器と一致しない' : 'None of the contents match with target weapon.'}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    errorMessage
                        ?
                        <>
                            <Helmet>
                                <title>Error! - RudyTudySubs</title>
                            </Helmet>
                            {errorMessage}
                        </>
                        :
                        <>
                            <Helmet>
                                <title>Granbluedle - RudyTudySubs</title>
                            </Helmet>
                            <div className="alert alert-secondary">Loading...</div>
                        </>
            }
        </>
    )
}

export default GranbluedleScreen;