import React from 'react'

const Footer = () => {
    return (
        <footer className="py-3 bg-dark mt-auto footer">
            <div className="container-fluid d-flex text-white">
                <div>Contact stuff: <a  href="mailto:rudytudysubs@gmail.com" target="_blank" rel="noopener noreferrer">rudytudysubs@gmail.com</a></div>
                <div className="ms-auto">I also hang out at these places: <a href="https://discord.gg/e97QGEA">WindyFall</a> - <a href="https://discord.com/invite/e4sbY8S">Dynasty</a></div>
            </div>
        </footer>
    )
}

export default Footer;