import { IconButton, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import AuthorDialog from './AuthorDialog';
import { addAuthorFirestore, editAuthorFirestore, getAuthorFirestore, getAuthorsFirestore } from '../../helpers/firestore';
import { toast } from '../../constants/notifs';

const AuthorSelector = React.memo(({
    excludeNew = false,
    initialValue,
    highLevelFunction
}
) => {
    // Autocomplete filter
    const filter = createFilterOptions();

    // States
    const [show, setShow] = useState(false);
    const [add, setAdd] = useState(false);
    // const [avoidAutoReset, setAvoidAutoReset] = useState(directAdd);
    const [error, setError] = useState(false);
    const [authorList, setAuthorList] = useState([]);
    const [currentAuthor, setCurrentAuthor] = useState(null);

    // Modal functions
    const handleOpen = function handleOpen() {
        setShow(true);
    };
    const handleClose = function handleClose() {
        //console.log(show)
        setShow(false);
        if (add) {
            setAdd(false);
            // Remove input if adding was cancelled
            setCurrentAuthor(null);
        }
    };

    // Method to get all authors
    const updateList = async () => {
        // Get the authors from the database
        setAuthorList(await getAuthorsFirestore(false));
    }

    // Gets all authors when loading the screen
    useEffect(() => {
        async function fetchData() {
            await updateList();
            // Try loading the author from the database when initiating
            //console.log("Estoy inicializando");
            //console.log(initialValue)
            if (initialValue && initialValue !== '') {
                //console.log('Estoy buscando en abse de datos')
                const newAuthor = await getAuthorFirestore(initialValue);
                //console.log(newAuthor)
                setCurrentAuthor(newAuthor);
            }
        }
        fetchData();
    }, [initialValue])

    // Communicate with top level element when updating author
    useEffect(() => {
        //console.log('Inifnitel oop inside selected')
        // Parent method here
        //console.log('Updating to higher level')
        //console.log(currentAuthor);
        if (currentAuthor === null || currentAuthor.name) {
            //console.log('Actualizando de verdad')
            highLevelFunction(currentAuthor);
        }
    }, [currentAuthor,highLevelFunction])

    // Handle the user interacting with the input
    const handleAuthorChange = (e, newValue, reason) => {
        // Removing everything
        if (reason === 'clear') {
            setCurrentAuthor(null);
        } else {
            setError(false);
            // For when the user just hits enter, it means add new
            if (typeof newValue === 'string') {
                setCurrentAuthor({
                    newValue
                })
                setAdd(true);
                handleOpen(true);
            } else {
                // Get the new value
                setCurrentAuthor(newValue);
                // If manually selected to add a new author
                if (newValue.newValue) {
                    setAdd(true);
                    handleOpen(true);
                }
            }
        }
    }

    // Method for modal submitting
    const handleSubmit = (author) => {
        // Add new or update existing
        if (add) {
            toast.async(
                addAuthorFirestore({
                    ...author,
                }),
                // Success
                ((id) => {
                    toast.success(`Added new author!`, { labels: { success: 'Success!' } });
                    const newAuthor = {
                        ...author,
                        id
                    };
                    setAdd(false);
                    setCurrentAuthor(newAuthor);
                }),
                `Error.`,
                `Wait a second...`,
                { labels: { async: 'Adding...' } }
            )
        } else {
            const { id, ...data } = author;
            toast.async(
                editAuthorFirestore({
                    ...data,
                }, id),
                // Success
                (() => {
                    toast.success(`Edited author and all manga related!`, { labels: { success: 'Success!' } });
                    setCurrentAuthor(author);
                }),
                `Error.`,
                `Wait a second...`,
                { labels: { async: 'Editing...' } }
            )
        }
        // Close the modal and update the list
        setShow(false);
        updateList();
    }


    return (
        <>
            <div className="d-flex full">
                <Autocomplete
                    freeSolo
                    options={authorList}
                    noOptionsText="No authors registered. (Bug?)"
                    style={{ width: '95%' }}
                    value={currentAuthor}
                    onChange={handleAuthorChange}
                    onInvalid={() => { setError(true) }}

                    getOptionLabel={(author) => {
                        if (typeof author === 'string') {
                            return author;
                        }
                        if (author.newValue) {
                            return author.newValue;
                        }
                        return `${author.name} - [${author.group}]`
                    }
                    }
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '' && !excludeNew) {
                            filtered.push({
                                newValue: params.inputValue,
                                name: `New author: "${params.inputValue}"`,
                                group: ''
                            });
                        }

                        return filtered;
                    }}
                    renderOption={(author) => `${author.name} - [${author.group}]`}
                    renderInput={(params) => <TextField {...params} error={error} className="form-control" label="Author" />}
                />
                <IconButton
                    color="primary"
                    className="ms-auto"
                    disabled={!currentAuthor}
                    onClick={handleOpen}
                >
                    <SearchIcon />
                </IconButton>

            </div>
            {show &&
                <AuthorDialog
                    author={currentAuthor}
                    add={add}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            }
        </>
    )
})

export default AuthorSelector;
