import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { getManga } from '../../helpers/firestore';
import MangaViewer from '../manga/MangaViewer';
import {Helmet} from "react-helmet";
import { AuthContext } from '../admin/AuthContext';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const ViewMangaScreen = () => {
    
    const {user} = useContext(AuthContext);

    // Read from database if on the URL
    const { mangaId } = useParams();
    const [manga, setManga] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        // Load from database database woah woah
        if (mangaId) {
            getManga(mangaId)
                .then((returnedManga) => {
                    setManga(returnedManga);
                })
                .catch((error) => {
                    const errorElement = (
                        <div className="alert alert-danger">
                            There was an error while reading from the database. <br/>
                            The author you tried to access probably doesn't exist anymore. <br/>
                            Send me a screenshot of this code and I might be able to do somethin about it: {`${error}`}
                        </div>
                    )
                    setErrorMessage(errorElement);
                })
        }
    }, [mangaId])

    //console.log(manga)

    return (
        <div className="container mt-3">

            {
                manga
                    ?
                <>
                    <Helmet>
                        <title>{manga.name} - RudyTudySubs</title>
                    </Helmet>
                    {
                        user
                            &&
                            <Link to={`/admin/manga/${mangaId}`}>
                                <Button
                                    fullWidth
                                    className="rounded-0 bg-danger"
                                >
                                    Edit
                                </Button>
                            </Link>
                    }
                    <MangaViewer manga={manga} />
                </>
                    :
                errorMessage 
                    ?
                <>
                    <Helmet>
                        <title>Error! - RudyTudySubs</title>
                    </Helmet>
                    {errorMessage}
                </>
                    :
                <>
                    <Helmet>
                        <title>Loading... - RudyTudySubs</title>
                    </Helmet>
                    <div className="alert alert-secondary">Loading...</div>
                </>
            }
        </div>
    )
}

export default ViewMangaScreen;