import { Button, IconButton, TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MangaList from '../manga/MangaList'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CollabTextField from './CollabTextField'
import { getFormattedDate, isDate } from '../../helpers/dates'
import TagDisplayer from '../tags/TagDisplayer'
import { manageManga, getTagList } from '../../helpers/firestore'
import TagAdder from './TagAdder'
import { useForm } from '../../hooks/useForm';
import { filterTags } from '../../helpers/filters';
import AuthorSelector from '../authors/AuthorSelector';

const MangaManager = ({ manga, editMode, initialAuthorId }) => {

    const [state, handleInputChange, reset] = useForm(manga);
    const [showPreview, setShowPreview] = useState(false)

    const { collabs, description, date, links, name, thumbnail, img, extension, tags } = state;

    const [dateText, setDateText] = useState('')
    const [mangaList, setMangaList] = useState([]);
    const [currentPairing, setCurrentPairing] = useState([])

    useEffect(() => {
        setMangaList([state]);
    }, [state])

    useEffect(() => {
        //console.log('Effect loop?')
        reset();
        updateTags('general');
        updateTags('series');
        updateTags('characters');
    }, [manga,reset])

    const history = useNavigate()
    const handleReturn = (e) => {
        e.preventDefault()
        history.push('/')
    }

    // const isInvalid = name ==='' || email ==='' || phone ==='' || company ===''

    const [{ err, msg, loading }, setState] = useState({
        loading: false,
        err: null,
        msg: null
    })

    // tags n shit
    const [tagsLists, setTagsLists] = useState({
        generalTags: [],
        seriesTags: [],
        charactersTags: []
    })
    
    // Words to filter tags
    const [keyWords, handleKeywordChange] = useForm({
        generalKeywords: '',
        seriesKeywords: '',
        charactersKeywords: ''
    })

    // Filtered tags
    const [filteredTagsLists, setFilteredTagsLists] = useState({
        filteredGeneralTags: [],
        filteredSeriesTags: [],
        filteredCharactersTags: []
    })

    const {
        generalTags,
        seriesTags,
        charactersTags
    } = tagsLists;

    const {
        generalKeywords,
        seriesKeywords,
        charactersKeywords
    } = keyWords;

    const {
        filteredGeneralTags,
        filteredSeriesTags,
        filteredCharactersTags
    } = filteredTagsLists;

    // Updating tags lists when user writes in the fields
    useEffect(() => {
        setFilteredTagsLists((old)=>({
            ...old,
            filteredGeneralTags: filterTags(generalTags,generalKeywords)
        }))
    }, [generalKeywords,generalTags])

    useEffect(() => {
        setFilteredTagsLists((old)=>({
            ...old,
            filteredSeriesTags: filterTags(seriesTags,seriesKeywords)
        }))
    }, [seriesKeywords,seriesTags])

    useEffect(() => {
        setFilteredTagsLists((old)=>({
            ...old,
            filteredCharactersTags: filterTags(charactersTags,charactersKeywords)
        }))
    }, [charactersKeywords,charactersTags])

    const updateTags = (category) => {
        //console.log('Im here');
        getTagList(category)
            .then((newTags)=>{
                setTagsLists( (old) => ({
                    ...old,
                    [`${category}Tags`] : newTags
                }));
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setState({
            loading: true,
            err: null,
            msg: null
        })
        manageManga(state, !editMode)
            .then((documentReference) => {
                setState({
                    loading: false,
                    err: null,
                    msg: `${editMode ? `Edited manga.` : `Added new manga, ID: ${documentReference.id}`}`
                })
                if(editMode) {
                    reset(state);
                } else {
                    reset();
                }
            })
            .catch((error) => {
                setState({
                    loading: false,
                    msg: null,
                    err: `${error.code} - ${error.message}`
                })
            })
    }

    const handleDate = () => {
        // Divide the date text into elements
        const [dd, mm, yyyy] = dateText.split('/');
        const newDate = new Date(yyyy, mm - 1, dd);
        // Test the date
        if (isDate(newDate)) {
            // Apply change
            const newTarget = {
                target: {
                    name: 'date',
                    value: newDate
                }
            }
            handleInputChange(newTarget);
        }
    }

    const handleInputLinks = (link,{ target }) => {
        const newLink = {
            ...link, value: target.value
        }

        const newTarget = {
            target: {
                name: 'links',
                value: {
                    ...links,
                    [newLink.name]: { ...newLink }
                }
            }
        }
        handleInputChange(newTarget);
    }

    const handleDeleteLinks = (name) => {
        const { [name]: excluded, ...newLinks } = links;
        const newTarget = {
            target: {
                name: 'links',
                value: newLinks
            }
        }
        handleInputChange(newTarget);
    }

    const handleCheckTag = useCallback ( ({ target }, isChecked, category) => {
        //console.log('handle check tag')
        if (target.name === 'Lewd') {
            // Add or remove the special lewd property directly
            let newTarget;
            if (isChecked) {
                newTarget = {
                    target: {
                        name: 'lewd',
                        value: true
                    }
                }
            } else {
                newTarget = {
                    target: {
                        name: 'lewd',
                        value: false
                    }
                }
            }
            handleInputChange(newTarget);
        }
        let newValue = {};
        if (isChecked) {
            // Creates the new list and sort it
            const newList = [...new Set([...tags[category].list, target.name].sort())];
            // Add to list
            newValue = {
                ...tags,
                [category]: {
                    list: newList,
                    order: tags[category].order
                }
            }
        } else {
            // Creates the new list and sort it
            const newList = [...new Set([...tags[category].list.filter((tag) => tag !== target.name)].sort())];
            // Remove from list
            newValue = {
                ...tags,
                [category]: {
                    list: newList,
                    order: tags[category].order
                }
            }
        }

        const newTarget = {
            target: {
                name: 'tags',
                value: newValue
            }
        }
        handleInputChange(newTarget);
    },[handleInputChange,tags])

    const handleCheckCharacter = useCallback ( (event, isChecked) => {
        //console.log('handle check character')
        handleCheckTag(event, isChecked, 'characters');
        if (isChecked) {
            // Add to list
            setCurrentPairing((old) => [...new Set([...old, event.target.name])]);
        } else {
            // Remove from list
            setCurrentPairing((old) => [...new Set([...old.filter((tag) => tag !== event.target.name)])]);
        }
        //console.log(currentPairing)
    },[handleCheckTag,setCurrentPairing])

    const handleAddShip = () => {
        let newTag = ""
        // Always keeps the same order
        const newPairing = [...currentPairing].sort();

        newPairing.forEach((tag, index) => {
            newTag = `${newTag}${tag}`;
            if (index !== newPairing.length - 1) {
                newTag = `${newTag} x `;
            }
        })

        // Creates the new list and sort it
        const newList = [...new Set([...tags.ships.list, newTag].sort())];

        // Build the new handle input change
        const newTarget = {
            target: {
                name: 'tags',
                value: {
                    ...tags,
                    ships: {
                        list: newList,
                        order: 3
                    }
                }
            }
        }
        handleInputChange(newTarget);
    }

    const handleAddCollab = () => {
        // Create new array with all previous links + a new empty one
        const newCollabs = [...collabs, {name:'',link:''}];
        // Set it
        const newTarget = {
            target: {
                name: 'collabs',
                value: newCollabs
            }
        }
        handleInputChange(newTarget);
    }

    const handleDeleteCollab = useCallback( (state) => {
        //console.log('handle delete collab')
        const newCollabs = [...collabs.filter((collab) => collab.name !== state.name)];
        const newTarget = {
            target: {
                name: 'collabs',
                value: newCollabs
            }
        }
        handleInputChange(newTarget);
    },[handleInputChange,collabs])

    const handleCollabInput = useCallback( (id, state) => {
        //console.log('handle collab input')
        const newCollabs = [...collabs];
        newCollabs[id] = { ...state };
        const newTarget = {
            target: {
                name: 'collabs',
                value: newCollabs
            }
        }
        handleInputChange(newTarget);
    },[handleInputChange,collabs])

    const handleAuthorChange = useCallback( (author) =>{
        //console.log('handle author change')
        //console.log('En nivel alto')
        //console.log(author)
        const newTarget = {
            target : {
                name: 'author',
                value: author
            }
        }
        handleInputChange(newTarget);
    },[handleInputChange])

    return (
        <>
            <div className="container mt-3">
                <h1>{editMode ? `Editing ${name}` : 'Add new manga'}</h1>
                <hr />
                <form autoComplete="off" className="d-grid gap-2" onSubmit={handleSubmit}>
                    <input type="hidden" value="something"/>

                    <div className="d-flex">
                        <TextField
                            autoComplete="off"
                            style={{ width: '70%' }}
                            value={dateText}
                            onChange={({ target }) => { setDateText(target.value) }}
                            onBlur={handleDate}
                            name='dateText'
                            className="form-control"
                            type='text'
                            label='Date (Text)'
                            placeholder='DD/MM/YYYY'
                        />

                        <TextField
                            autoComplete="off"
                            name='dateText'
                            disabled
                            className="flex-grow-1 form-control ms-3"
                            type='text'
                            label='Date'
                            value={getFormattedDate(date)}
                            placeholder='DD/MM/YYYY'
                        />

                    </div>
                    <TextField
                        autoComplete="off"
                        value={name}
                        onChange={handleInputChange}
                        name='name'
                        className="form-control"
                        type='text'
                        label="Name"
                        placeholder='Name'
                    />
                    
                    <AuthorSelector 
                        initialValue={initialAuthorId}
                        highLevelFunction={handleAuthorChange}
                    />

                    <TextField
                        autoComplete="off"
                        value={description}
                        onChange={handleInputChange}
                        name='description'
                        className="form-control"
                        type='text'
                        multiline
                        label='Description'
                        placeholder='Description'
                    />

                    <TextField
                        autoComplete="off"
                        value={thumbnail}
                        onChange={handleInputChange}
                        name='thumbnail'
                        className="form-control"
                        type='text'
                        label='Thumbnail url (smol)'
                        placeholder='Thumbnail url (smol)'
                    />

                    <TextField
                        autoComplete="off"
                        value={extension}
                        onChange={handleInputChange}
                        name='extension'
                        className="form-control"
                        type='text'
                        label='Extension'
                        placeholder='png'
                    />

                    <TextField
                        autoComplete="off"
                        value={img}
                        onChange={handleInputChange}
                        name='img'
                        className="form-control"
                        type='text'
                        label='Img url (big)'
                        placeholder='Img url (big)'
                    />

                    <div className="container border border-3 border-info py-3 text-capitalize">
                        <h5>Links</h5>
                        {
                            Object.values(links).map((site) => (
                                <div key={site.name} className="d-flex">
                                    <TextField
                                        style={{ width: '95%' }}
                                        autoComplete="off"
                                        onChange={(e)=>handleInputLinks(site,e)}
                                        name={site.name}
                                        className="form-control my-1"
                                        type='text'
                                        value={site.value}
                                        label={`${site.name} URL`}
                                        placeholder={`${site.label}`}
                                    />
                                    <IconButton
                                        className="ms-auto"
                                        tabIndex="99"
                                        onClick={() => { handleDeleteLinks(site.name) }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))
                        }
                    </div>

                    <div className="container border border-3 border-secondary py-3">

                        <div className="border border-2 mb-2 border-secondary">
                            <div className="d-flex">
                                <TextField
                                    autoComplete="off"
                                    onChange={handleKeywordChange}
                                    name='generalKeywords'
                                    className="form-control my-1 flex-grow-1"
                                    type='text'
                                    fullWidth
                                    label="Search general tags"
                                    placeholder="Keywords"
                                />
                                <Button
                                    className="ms-1"
                                    onClick={()=>{updateTags('general')}}
                                >
                                    Update
                                </Button>
                            </div>
                            <TagDisplayer
                                categories={["general"]}
                                bigTagList={[filteredGeneralTags]}
                                handleChange={handleCheckTag}
                            />
                        </div>

                        <div className="border border-2 mb-2 border-secondary">
                            <div className="d-flex">
                                <TextField
                                    autoComplete="off"
                                    onChange={handleKeywordChange}
                                    name='seriesKeywords'
                                    className="form-control my-1"
                                    type='text'
                                    fullWidth
                                    label="Search series tags"
                                    placeholder="Keywords"
                                />
                                <Button
                                    className="ms-1"
                                    onClick={()=>{updateTags('series')}}
                                >
                                    Update
                                </Button>
                            </div>
                            <TagDisplayer
                                categories={["series"]}
                                bigTagList={[filteredSeriesTags]}
                                handleChange={handleCheckTag}
                            />
                        </div>

                        <div className="border border-2 mb-2 border-secondary">
                            <div className="d-flex">
                                <TextField
                                    autoComplete="off"
                                    onChange={handleKeywordChange}
                                    name='charactersKeywords'
                                    className="form-control my-1"
                                    type='text'
                                    fullWidth
                                    label="Search character tags"
                                    placeholder="Keywords"
                                />
                                <Button
                                    className="ms-1"
                                    onClick={()=>{updateTags('characters')}}
                                >
                                    Update
                                </Button>
                            </div>
                            <TagDisplayer
                                categories={["characters"]}
                                bigTagList={[filteredCharactersTags]}
                                handleChange={handleCheckCharacter}
                            />
                            <Button
                                className="w-100 h-100 mt-2"
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAddShip}
                            >
                                Add ship
                            </Button>
                        </div>
                        <h6>Ships</h6>
                        <div>
                            {
                                tags.ships.list.map((ship) => (
                                    <span
                                        key={ship}
                                        className="me-3 border border-2 border-danger px-3 rounded-pill"
                                        role="button"
                                        onClick={() => { handleCheckTag({ target: { name: ship } }, false, 'ships') }}
                                    >
                                        {ship}
                                    </span>
                                ))
                            }
                        </div>
                    </div>

                    <div className="container border border-3 border-warning py-3">
                        <div className="d-flex">
                            <h5>Collabs</h5>
                            <IconButton
                                className="ms-auto"
                                onClick={handleAddCollab}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                        {
                            collabs.map((collab,index) => (
                                <CollabTextField
                                    key={index}
                                    index={index}
                                    handleCollabInput={handleCollabInput}
                                    handleDeleteCollab={handleDeleteCollab}
                                    initialValue={collab}
                                />
                            ))
                        }

                    </div>

                    {loading && <div className="alert alert-secondary">Processing...</div>}
                    {msg && <div className="alert alert-primary">{msg}</div>}
                    {err && <div className="alert alert-danger">{err}</div>}

                    <button
                        // disabled={isInvalid}
                        className="btn btn-primary"
                        type="submit"
                    >
                        {editMode ? 'Edit' : 'Add'} manga
                </button>
                    <button
                        onClick={handleReturn}
                        type="button"
                        className="btn btn-danger"
                    >
                        Cancel
                </button>
                </form>

                <hr />


            </div>
            <div className="container mb-3">
                <TagAdder />
            </div>

            <Button
                fullWidth
                onClick={()=>setShowPreview(!showPreview)}
                variant="outlined"
                className="mb-2"
            >
                Preview
            </Button>

            {
                showPreview 
                    && 
                <div className="container-fluid bg-light">
                    <MangaList mangaList={mangaList} />
                </div>
            }

        </>
    )
}

export default MangaManager;