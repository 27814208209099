import {useCallback, useState} from 'react'


export const useForm = (initialState = {}) =>{

    const [state, setstate] = useState(initialState);

    const handleInputChange = useCallback ( (...changes) =>{
        changes.forEach(({target})=>{
            if(target){
                setstate((prev)=>({
                    ...prev,
                    [target.name]:target.value
                }));
            }
        })
    },[setstate])

    const reset = useCallback ( (value = initialState) =>{
        setstate(value);
    },[setstate,initialState])

    return [state,handleInputChange, reset];
}