import React from 'react'

const AboutScreen = () => {
    return (
        <>
            <div className="alert alert-info d-flex flex-column">
                <p className="mb-0">Hello and welcome to the official and super duper cool RudyTudySubs website.</p>
                <p className="mb-2">It's really just a glorified archive though.</p>
                <p className="mb-2">This website was made with React and all the data is stored in a Firestore database.</p>
                <p className="mb-1">I ran out of things to say so here's some links you can click for where you can find this stuff in other websites.</p>
                <p className="mb-0"><a href="https://mangadex.org/group/11476">Mangadex</a></p>
                <p className="mb-0"><a href="https://dynasty-scans.com/scanlators/rudytudysubs">Dynasty</a></p>
                <p className="mb-0"><a href="https://e-hentai.org/uploader/rudydesu">E-Hentai</a></p>
                <p className="mb-0"><a href="https://rudytudysubs.tumblr.com/">Tumblr</a></p>
                <p className="mb-0"><a href="https://ko-fi.com/rudytudysubs">Ko-Fi (give me money)</a></p>
            </div>
            <h3>Cookies</h3>
            <hr />
            <h4>Site Cookies</h4>
            The website makes use of cookies to save your preferences as well as record keeping over multiple sessions.
            <h4>Third Party Cookies</h4>
            The website makes use of Google Analytics for tracking user experiences such as filters used or readers used, the website contains no ads nor will it ever do so.  For more information on Google Analytics cookies, see the official Google Analytics page.
        </>
    )
}

export default AboutScreen;