import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { getMangaList } from '../../helpers/firestore'
import MangaList from '../manga/MangaList'

export const MangaScreen = () => {

    const [mangaList, setMangaList] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect( () => {
        getMangaList()
            .then((list)=>{
                setMangaList(list);
            })
            .catch((error) => {
                const errorElement = (
                    <div className="alert alert-danger">
                        There was an error while reading from the database. <br/>
                        Send me a screenshot of this code and I might be able to do somethin about it: {`${error}`}
                    </div>
                )
                setErrorMessage(errorElement);
            })
    }, [])

    return (
        <>
        {
            mangaList
                ?
            <>
                <Helmet>
                    <title>Manga List - RudyTudySubs</title>
                </Helmet>
                <MangaList 
                    mangaList={mangaList}
                />
            </>
                :
            errorMessage 
                ?
            <>
                <Helmet>
                    <title>Error! - RudyTudySubs</title>
                </Helmet>
                {errorMessage}
            </>
                :
            <>
                <Helmet>
                    <title>Loading... - RudyTudySubs</title>
                </Helmet>
                <div className="alert alert-secondary">Loading...</div>
            </>
        }
        </>
    )
}
