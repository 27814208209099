export const linksList = {
    dynasty: {
        name: 'dynasty',
        label: 'Read on Dynasty',
        value:''
    },
    ehentai: {
        name: 'ehentai',
        label: 'Read on E-Hentai',
        value:''
    },
    exhentai: {
        name: 'exhentai',
        label: 'Read on Sadpanda',
        value:''
    },
    mangadex: {
        name: 'mangadex',
        label: 'Read on Mangadex',
        value:''
    },
    zip: {
        name: 'zip',
        label: 'Download .zip file',
        value:''
    }
}