const escapeRegExp = require('lodash.escaperegexp');

export const filterTags = (tagList, keywords) => {
    if (keywords.trim() === ''){
        return tagList;
    }
    // Creates a regex filter
    const pattern = new RegExp(escapeRegExp(keywords), 'i'); 
    return tagList.filter( (tag) => {
        // Try matching with label
        if (tag.label.match(pattern)) {
            return true;
        } else {
            // Try matching with the extra tags
            return tag.extras.some( (extraTag) => extraTag.match(pattern));
        }
    })
}

export const filterAuthors = (authorList, keywords) => {
    if (keywords.trim() === ''){
        return authorList;
    }
    // Creates a regex filter
    const pattern = new RegExp(escapeRegExp(keywords), 'i'); 
    return authorList.filter( (author) => {
        // Try matching with label
        if (author.name.match(pattern) || author.group.match(pattern)) {
            return true;
        } else {
            return false;
        }
    })
}

export const filterManga = (mangaList, filters) =>{
    const {
        author,
        lewdMode, 
        name,
        tags,
        tagsMode,
    } = filters;

    let filteredManga = [...mangaList];
    
    // Filter by lewds
    filteredManga = filteredManga.filter(({lewd})=>{
        // If it doesn't matter
        if(lewdMode==='blur' || lewdMode==='noblur') {
            return true;
        } else {
            if(lewd) {
                // If it's lewd, only return it in lewd mode
                return lewdMode === 'alllewd';
            } else {
                return lewdMode === 'nolewd';
            }
        }
    })

    // Filter by manga name 
    if(name!=='') {
        // Creates a regex filter
        const pattern = new RegExp(escapeRegExp(name), 'i'); 
        filteredManga = filteredManga.filter((manga)=>{
            // Try matching with manga name
            if (manga.name.match(pattern)){
                return true;
            } else {
                return false;
            }
        })
    }

    // Then by author name
    if(author!=='') {
        // Creates a regex filter
        const pattern = new RegExp(escapeRegExp(author), 'i'); 
        filteredManga = filteredManga.filter((manga)=>{
            // Try matching with manga name
            if (manga.author.name.match(pattern)){
                return true;
            } else {
                return false;
            }
        })
    }

    // Finally, by tags
    if(tags.length!==0){
        if(tagsMode==='any'){
            filteredManga = filteredManga.filter((manga)=>{
                return manga.bigTags.some((tag)=>tags.includes(tag));
            })
        } else {
            filteredManga = filteredManga.filter((manga)=>{
                let obj = {};
                // Give keys and values to dummy object
                manga.bigTags.forEach((el,index) => {
                    obj[el] = index;
                })
                // Check
                return tags.every((tag) => {
                    return obj[tag] !== undefined;
                })
            })
        }
    }
    // console.log(filteredManga)

    // Returns the result
    return filteredManga;
}