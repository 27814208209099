import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import React from 'react'
import { useForm } from '../../hooks/useForm';

const NicoYumeDialog = ({handleClose, handleSubmit}) => {

    const [{name}, handleInputChange] = useForm({
        name:''
    })

    return (
        <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Did you complete the game yet?</DialogTitle>
            <DialogContent>
                <p>If you did, there should be a code somewhere within one of the game folder files you can write here:</p>
                <TextField
                    autoComplete="off"
                    autoFocus
                    className="form-control"
                    name="name"
                    type='text'
                    value={name}
                    onChange={handleInputChange}
                    onKeyUpCapture={
                        (e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                handleSubmit(name);
                            }
                        }
                    }
                /> 
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{handleSubmit(name)}} color="primary">
                    Send
                </Button>
            </DialogActions>
      </Dialog>
    )
}

export default NicoYumeDialog;