import { Button } from '@material-ui/core';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { getFormattedDate } from '../../helpers/dates';
import useWindowDimensions from '../../hooks/useWindowDimensions';
const images = require.context('../../../public/images/links')

const MangaViewer = ({ manga }) => {

    const [full, setFull] = useState(false);

    const {
        author,
        collabs = [],
        description,
        group,
        links,
        name,
        id,
        extension,
        tags = {},
        lewd,
        img,
        date = new Date()
    } = manga;

    const tagsArray = Object.entries(tags);
    tagsArray.sort((first, second) => {
        if (first[1].order < second[1].order) {
            return -1;
        } else {
            return 1;
        }
    });

    const linksArray = Object.values(links).sort((first, second) => {
        if (first.name > second.name) {
            return 1;
        } else {
            return -1;
        }
    });

    const {width} = useWindowDimensions();

    return (
        <>
            <div className="row border-2 border-secondary border-bottom">
                <h2 className={`col col-1${lewd ? '1' : '2'}`}>{name}</h2>
                {
                    lewd 
                        && 
                    <div className="col col-1 my-auto"> 
                        <h2 className="d-inline px-2 text-danger fw-bold border-danger border border-1">H</h2>
                    </div>
                }
            </div>
            <div className="container mb-2">
                <div className="row gx-0">
                    <div className="px-2 pt-2 col-12  col-lg-7 col-xl-8 border-bottom border-lg-right border-lg-bottom-0 border-2 border-secondary">
                        <img
                            src={`/images/manga/${id}.${extension == null ? 'jpg' : extension}`} 
                            onError={(e)=>{e.target.onError = null; e.target.src = img}}
                            alt={`${name} - By ${author} - ${group}`} className="view-img" />
                    </div>
                    <div className="col border-2 border-lg-right border-secondary">
                        <div className="h-100 d-flex flex-column justify-content-between">
                            {/* Date */}
                            <div className="pt-2 border-start border-end border-lg-left-0 border-lg-right-0 bg-warning fw-bold border-bottom border-3 border-secondary text-center mb-2 pb-2">
                                {getFormattedDate(date)}
                            </div>
                            {/* Author stuff */}
                            <Link className="mx-2 view-author text-wrap mb-2" to={`/authors/${author.id}`}>
                                <p className="m-auto text-center fw-bolder">By {author.name} - {author.group}</p>
                            </Link>
                            {/* Links to author stuff */}
                            <div className="mx-2 mb-auto border border-2 border-primary row row-cols-1 g-0">
                                {
                                    author.links?.map((link) => (
                                        <a key={link.url} className="view-author-links anchor-link d-block border-bottom border-secondary border-3" href={link.url}>
                                            <div className="h-100 col ps-0  d-flex">
                                                <img className="border-end border-secondary border-3 h-100" alt={link.text} src={link.localimg == null ? link.img : images(`./${link.localimg}.png`)} />
                                                <div className="h-100 w-100 ps-1 text-wrap d-flex">
                                                    <div className="my-auto">
                                                        {link.text}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))
                                }
                            </div>
                            <br />
                            {/* Description */}
                            <div className="mx-2 p-1 border border-2 border-primary">
                                <p className={`${full || width < 1200 ? 'view-scroll' : 'view-overflow'} mb-0`}>{description}</p>
                                {
                                    width < 1200
                                        ||
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => { setFull(!full) }}
                                    >
                                        {full ? 'Show less' : 'Show more'}
                                    </Button>
                                }
                            </div>
                            {/* Tags */}
                            <div className="mx-2 mt-2 p-1 border border-2 border-primary text-capitalize text-wrap d-flex flex-column">
                                {
                                    tagsArray.map((tagGroup) => {
                                        const [key, { list = [] }] = tagGroup;
                                        return (
                                            <React.Fragment key={key}>
                                                {
                                                    list.length === 0
                                                    ||
                                                    <div className="">
                                                        <h6>{key}</h6>
                                                        <span className="tags view-tags">
                                                            {list.map((tag) => (
                                                                <span key={tag} className="mx-1 ms-lg-1 me-lg-2 tag">{tag}</span>
                                                            ))}
                                                        </span>
                                                        <br />
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                            {/* collabs */}
                            {
                                collabs.length === 0
                                ||
                                <div className="mx-2 mt-2">
                                    <span>With the help of these lovely people:</span>
                                    <div className=" border border-2 border-primary row row-cols-1 g-0">
                                        {
                                            collabs.map((collab) => (
                                                <a key={collab.name} className="view-author-links anchor-link d-block border-bottom border-secondary border-3" href={collab.link}>
                                                    <div className="h-100 col ps-0  d-flex">
                                                        <div className="text-center w-25  border-end border-secondary border-3 h-100">{collab.name[0].toUpperCase()}</div>
                                                        <div className="h-100 w-100 ps-1 text-wrap d-flex">
                                                            <div className="my-auto">
                                                                {collab.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            {/* Links */}
                            <br />
                            <div className="mx-2 border border-2 border-primary mt-auto rounded-3">
                                <div className="row row-cols-md-2 g-0">
                                    {
                                        linksArray.map((link) => {
                                            if(link.value !== ''){
                                                return (
                                                    <a key={link.name} className="view-links anchor-link d-block border-bottom border-end border-secondary border-3" href={link.value}>
                                                        <div className="h-100 col ps-0  d-flex">
                                                            <img className="border-end border-secondary border-3 h-100" alt={link.label} src={(images(`./${link.name}.png`))} />
                                                            <div className={`${link.name} h-100 w-100 ps-1 text-wrap d-flex`}>
                                                                <div className="my-auto">
                                                                    {link.label}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )
                                            } else {
                                                return <></>
                                            }   
                                        }
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MangaViewer;