import React from 'react'
import { useNavigate } from 'react-router';
import { auth } from '../../firebase/firebase-config';
import { useForm } from '../../hooks/useForm';

const LoginScreen = () => {

    const history = useNavigate();

    const [{email,pass},handleInputChange] = useForm({
        email:'',
        pass:''
    });

    const login = (e) => {
        e.preventDefault();
        auth.signInWithEmailAndPassword(email,pass)
            .then(()=>{
                history.push('/admin');
            })
            .catch(()=>{})
    }

    return (
        <div>
            You shouldn't be here.

            <form onSubmit={login}>
                <input
                    name='email'
                    value={email}
                    onChange={handleInputChange}
                />
                <input
                    name='pass'
                    value={pass}
                    type="password"
                    onChange={handleInputChange}
                />
                <button
                    type="submit"
                >
                    try
                </button>
                
            </form>
            
        </div>
    )
}

export default LoginScreen;