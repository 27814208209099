import React, { useEffect, useState } from 'react'
import MangaViewer from '../manga/MangaViewer';
import { Helmet } from "react-helmet";
import { getFormattedDate } from '../../helpers/dates';
import { getLatestManga } from '../../helpers/firestore';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

// // Get images for links
const images = require.context('../../../public/images/granbluedle/etc');

const HomeScreen = () => {

    const [manga, setManga] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showLewd, setShowLewd] = useState(false)

    useEffect(() => {
        // Load from database database woah woah
        getLatestManga()
            .then((returnedManga) => {
                setManga(returnedManga);
            })
            .catch((error) => {
                const errorElement = (
                    <div className="alert alert-danger">
                        There was an error while reading from the database. <br />
                        It wasn't possible to get the latest registered manga, for some reason. <br />
                        Send me a screenshot of this code and I might be able to do something about it: {`${error}`}
                    </div>
                )
                setErrorMessage(errorElement);
            })
    }, [])

    return (
        <>
            <Helmet>
                <title>RudyTudySubs</title>
            </Helmet>
            <div className="d-flex">
                <h1>Welcome to the RudyTudySubs website!</h1>
                <small> ^ Use the bar above</small>
            </div>
            <div className="alert alert-primary d-flex flex-column">
                <p className="mb-0">
                    <b>News 21/03/2024: Granbluedle, a game about guessing GBF weapons, is now out!</b>
                </p>
                <p className="mb-0">You can play it now by clicking on this image below or moving to the section with the navbar up top.</p>
                <p className="mb-0">Also some more changes around the site just for general cleanup.</p>
                <Link to="/granbluedle">
                    <div className="newsImage">
                        <img src={((images('./logo.png')))} alt="granbludle header" />
                    </div>
                </Link>
            </div>
            {
                manga
                &&
                <div className="alert alert-info">
                    Latest update: {getFormattedDate(manga.date)} - {manga.name}
                </div>
            }
            <div className="container mt-3">
                {
                    manga
                        ?
                        <>
                            {
                                manga.lewd
                                    ?
                                    showLewd
                                        ?
                                        <MangaViewer manga={manga} />
                                        :
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            color="secondary"
                                            onClick={() => { setShowLewd(true) }}
                                        >
                                            NSFW WARNING - Uwah actually this is pretty lewd, sure you wanna see it?
                                        </Button>
                                    :
                                    <MangaViewer manga={manga} />
                            }

                        </>
                        :
                        errorMessage
                            ?
                            <>
                                {errorMessage}
                            </>
                            :
                            <>
                                <div className="alert alert-secondary">Loading...</div>
                            </>
                }
            </div>
        </>
    )
}

export default HomeScreen;