import { Button, TextField } from '@material-ui/core';
import React, { useReducer, useState } from 'react'
import { filterManga } from '../../helpers/filters';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import MangaCard from './MangaCard';
import MangaTagDialog from './MangaTagDialog';
import ReactGA from "react-ga4";

const MangaList = React.memo(
    (
        {
            mangaList
        }
    ) => {

        const [openTags, setOpenTags] = useState(false)

        function reducer(state, action) {
            let filters = {};
            switch (action.type) {
                case 'author':
                    filters = {
                        ...state.filters,
                        author: action.payload
                    };
                    break;
                case 'name':
                    filters = {
                        ...state.filters,
                        name: action.payload
                    };
                    break;
                case 'lewdMode':
                    ReactGA.event({
                        category: 'MangaTags',
                        action: 'Changed R18 mode',
                        label: action.payload
                    })
                    filters = {
                        ...state.filters,
                        lewdMode: action.payload
                    };
                    break;
                case 'tags':
                    ReactGA.event({
                        category: 'MangaTags',
                        action: 'Changed tags',
                        label: `${action.payload.tagsMode} - ${action.payload.tags}`
                    })
                    filters = {
                        ...state.filters,
                        tags: action.payload.tags,
                        tagsMode: action.payload.tagsMode
                    };
                    break;
                default:
                    filters = {
                        author: '',
                        lewdMode: 'blur',
                        name: '',
                        tags: [],
                        tagsMode: 'any',
                    };
                    break;
            }
            //console.log(filters);
            return {
                filteredList: filterManga(mangaList, filters),
                filters
            };
        }

        const [{ filteredList, filters }, dispatch] = useReducer(reducer, {
            filteredList: mangaList,
            filters: {
                author: '',
                lewdMode: 'blur', // blur, noblur, nolewd, alllewd
                name: '',
                tags: [],
                tagsMode: 'any',
            }
        });

        const changeTags = (tags, tagsMode) => {
            dispatch({
                type: 'tags',
                payload: {
                    tags,
                    tagsMode
                }
            });
            changeOpen();
        }

        const changeOpen = () => {
            if (!openTags) {
                ReactGA.modalview('/mangaTags')
            }
            setOpenTags((old) => !old);
        }


        const { width } = useWindowDimensions();
        //console.log(`H: ${height} - W: ${width}`)
        //console.log(mangaList)
        // console.log(filteredList);

        const handleBlur = (which) => {
            switch (which) {
                case 'name':
                    if( filters.name.trim() !== ''){
                        ReactGA.event({
                            category: 'MangaTags',
                            action: 'Filtered manga name',
                            label: filters.name,
                        })
                    }
                    break;
                case 'author':
                    if( filters.author.trim() !== ''){
                        ReactGA.event({
                            category: 'MangaTags',
                            action: 'Filtered author name',
                            label: filters.author,
                        })
                    }
                    break;
                default:
                    return;
            }
        }

        return (
            <>
                {
                    openTags
                    &&
                    <MangaTagDialog
                        handleClose={changeOpen}
                        handleSubmit={changeTags}
                    />
                }
                <div className="border border-3 border-primary mb-3">
                    <div className="border-bottom border-3 border-primary">
                        <div className="d-flex border-bottom border-3 border-primary">
                            <div className="w-100 me-1 border-end border-3 border-primary">
                                <TextField
                                    autoComplete="off"
                                    value={filters.name}
                                    onChange={(e) => { dispatch({ type: 'name', payload: e.target.value }) }}
                                    onBlur={()=>handleBlur('name')}
                                    name='manga'
                                    className="form-control"
                                    type='text'
                                    label='Filter manga by name'
                                />
                            </div>
                            <div className="w-100 ms-1 border-start border-3 border-primary">
                                <TextField
                                    autoComplete="off"
                                    value={filters.author}
                                    onChange={(e) => { dispatch({ type: 'author', payload: e.target.value }) }}
                                    onBlur={()=>handleBlur('author')}
                                    name='author'
                                    className="form-control"
                                    type='text'
                                    label='Filter manga by author'
                                />
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="w-25 me-1 border-end border-3 border-primary">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    className={`h-100 rounded-0${filters.tags.length !== 0 ? ' bg-info bg-gradient' : ''}`}
                                    onClick={changeOpen}
                                >
                                    Set tag filters{filters.tags.length !== 0 ? ` - ${filters.tagsMode}` : ''}
                                </Button>
                            </div>
                            <div className="overflow-auto w-50 mx-1 border-end border-start border-3 border-primary d-flex">
                                <Button
                                    className={`p-0 w-25 h-100 rounded-0${filters.lewdMode === 'blur' ? ' bg-info bg-gradient' : ''}`}
                                    variant="contained"
                                    onClick={() => { dispatch({ type: 'lewdMode', payload: 'blur' }) }}
                                >
                                    Blur R18
                                </Button>
                                <Button
                                    className={`p-0 w-25 h-100 rounded-0${filters.lewdMode === 'noblur' ? ' bg-info bg-gradient' : ''}`}
                                    variant="contained"
                                    onClick={() => { dispatch({ type: 'lewdMode', payload: 'noblur' }) }}
                                >
                                    Show R18
                                </Button>
                                <Button
                                    className={`p-0 w-25 h-100 rounded-0${filters.lewdMode === 'nolewd' ? ' bg-info bg-gradient' : ''}`}
                                    variant="contained"
                                    onClick={() => { dispatch({ type: 'lewdMode', payload: 'nolewd' }) }}
                                >
                                    No R18
                                </Button>
                                <Button
                                    className={`p-0 w-25 h-100 rounded-0${filters.lewdMode === 'alllewd' ? ' bg-info bg-gradient' : ''}`}
                                    variant="contained"
                                    onClick={() => { dispatch({ type: 'lewdMode', payload: 'alllewd' }) }}
                                >
                                    Only R18
                                </Button>
                            </div>
                            <div className="w-25 ms-1 border-start border-3 border-primary">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    className="h-100 rounded-0 bg-warning"
                                    onClick={() => { dispatch({ type: 'reset' }) }}
                                >
                                    Reset filters
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pb-2 manga-container animated fast fadeIn`">
                        {
                            filteredList.length === 0
                                ?
                                <div className="alert alert-warning mt-2">
                                    No results found, ease up on the filters maybe.
                                    </div>
                                :
                                <div className={`row ${width < 1000 ? 'row-cols-1' : width < 1500 ? 'row-cols-2' : 'row-cols-3'}`}>
                                    {
                                        filteredList.map((manga) => (
                                            <MangaCard
                                                key={manga.name}
                                                manga={manga}
                                                lewdMode={filters.lewdMode}
                                            />
                                        ))
                                    }
                                </div>
                        }
                    </div>
                </div>
            </>
        )
    }
)

export default MangaList;