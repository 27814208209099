import React from 'react';
import ReactDOM from 'react-dom';
import RudySiteApp from './RudySiteApp';

import './styles/styles.scss'

ReactDOM.render(
  <React.StrictMode>
    <RudySiteApp />
  </React.StrictMode>,
  document.getElementById('root')
);
