import React from 'react'
import { Link } from 'react-router-dom';
const images = require.context('../../../public/images/links')

const AuthorCard = ({ author }) => {

    const {
        name,
        group,
        description,
        links,
        id
    } = author;

    return (
        <div className="col mt-2">
            <div className="card h-100 text-wrap text-break">
                <Link className="card-header anchor-link d-flex" to={`/authors/${id}`}>
                    <h5 className="card-title mb-0">
                        {name}
                    </h5>
                    <span className="ms-auto span-tap">Tap here to see more!</span>
                </Link>
                <div className="card-body d-flex flex-column h-100">
                    <h6 className="card-subtitle mb-2 text-muted">
                        Group: {group}
                    </h6>
                    <p className="card-text">{description}</p>

                </div>
                <div className="h-100 manga-filler bg-gradient"></div>
                <div className="card-footer d-flex">
                    <span className="my-auto read-here">
                        Their stuff:
                    </span>
                    <div className="links ms-auto">
                        {
                            links.map((link)=>(
                                <div key={link.text} className="mx-2">
                                    <a className="anchor-link border border-secondary p-1 pb-2 border-lg-0 d-flex" href={link.url} title={link.text}>
                                        <span className="me-1 d-lg-none">{link.text}</span>
                                        <img className="rounded-circle border border-secondary border-2 m-auto" alt={link.text} src={link.localimg == null ? link.img : images(`./${link.localimg}.png`)} />
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthorCard;