import { getSiteMapDate } from "./dates";
import { getAuthorsFirestore, getMangaList } from "./firestore";

export const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = 'display: none';
    return function (data, fileName) {
        const blob = new Blob([data], { type: 'text/xml' }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

export const getInitialMap = () => {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        <url>
            <loc>https://rudytudysubs.herokuapp.com/</loc>
            <lastmod>2021-07-21</lastmod>
        </url>
        <url>
            <loc>https://rudytudysubs.herokuapp.com/about</loc>
        </url>
        <url>
            <loc>https://rudytudysubs.herokuapp.com/authors</loc>
        </url>
        <url>
            <loc>https://rudytudysubs.herokuapp.com/manga</loc>
        </url>
        <url>
            <loc>https://rudytudysubs.herokuapp.com/nicoyume</loc>
            <lastmod>2021-07-26</lastmod>
        </url>
        <url>
            <loc>https://rudytudysubs.herokuapp.com/nicoyume/notes</loc>
            <lastmod>2021-07-26</lastmod>
        </url>
        <url>
            <loc>https://rudytudysubs.herokuapp.com/granbluedle</loc>
        </url>
        <url>
            <loc>https://rudytudysubs.herokuapp.com/granbluedle/endless</loc>
        </url>`;
}

export const getMangaMaps = async () => {
    let result = '';
    const mangaList = await getMangaList();
    mangaList.forEach(manga => {
        result = `${result}
        <url>
        <loc>https://rudytudysubs.herokuapp.com/manga/${manga.id}</loc>
        <lastmod>${getSiteMapDate(manga.date)}</lastmod>
      </url>`
    });
    return result;
}

export const getAuthorMaps = async () => {
    let result = '';
    const authorList = await getAuthorsFirestore(true);
    authorList.forEach(author => {
        result = `${result}
        <url>
        <loc>https://rudytudysubs.herokuapp.com/authors/${author.id}</loc>
      </url>`
    });
    return result;
}