import { Button, TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { tagCategories } from '../../constants/tags';
import { manageTag } from '../../helpers/tags';
import { useForm } from '../../hooks/useForm';
import { toast } from "../../constants/notifs";
import { getTagList } from '../../helpers/firestore';
import TagDisplayer from '../tags/TagDisplayer';


const TagAdder = React.memo(({ categoryTop = 'characters' }) => {

    const selectRef = useRef(null)
    const labelRef = useRef(null)

    const [state, handleInputChange, reset] = useForm({
        category: categoryTop,
        label: '',
        extra: ''
    });

    const {
        category,
        label,
        extra
    } = state;

    // Update box categories when changing
    const [tagList, setTagList] = useState([]);

    const [updateSwitch, setUpdateSwitch] = useState(false);
    useEffect(() => {
        //console.log('Effect loop?')
        async function fetchData(){
            //console.log("I'm  calling the database")
            setTagList(await getTagList(category));
        }
        fetchData();
    }, [category,updateSwitch])

    // Edit mode
    const [edit, setEdit] = useState({
        mode: false,
        id: '',
        oldValue: ''
    });

    const handleAdd = () => {
        if (edit.mode) {
            const newState = {
                ...state,
                id: edit.id,
                oldValue: edit.oldValue
            }
            toast.async(
                manageTag(newState, false),
                // Success
                ((label) => {
                    toast.success(`Modified tag: ${label} and all manga that had it`, { labels: { success: 'Completado' } });
                    const temp = `${category}`;
                    reset();
                    handleInputChange({
                        target: {
                            name: 'category',
                            value: temp
                        }
                    });
                    setUpdateSwitch(!updateSwitch);
                    selectRef.current.focus();
                }),
                `Error.`,
                `Wait a second...`,
                { labels: { async: 'Editing...' } }
            )
        } else {
            toast.async(
                manageTag(state, true),
                // Success
                ((label) => {
                    toast.success(`Added new tag: ${label}`, { labels: { success: 'Completado' } });
                    const temp = `${category}`;
                    reset();
                    handleInputChange({
                        target: {
                            name: 'category',
                            value: temp
                        }
                    });
                    setUpdateSwitch(!updateSwitch);
                    selectRef.current.focus();
                }),
                `Error.`,
                `Wait a second...`,
                { labels: { async: 'Adding...' } }
            )
        }
    }

    const handleEdit = useCallback( (event, isChecked, cat, extra, id) => {
        if (isChecked) {
            // Update the adder to match selected stuff
            const newCategory = {
                target : {
                    name: 'category',
                    value: cat
                }
            }
            const newLabel = {
                target : {
                    name: 'label',
                    value: event.target.name
                }
            }
            const newExtra = {
                target : {
                    name: 'extra',
                    value: extra
                }
            }
            handleInputChange(newLabel,newExtra,newCategory);
            labelRef.current.focus();
            // Set edit mode
            setEdit({
                id,
                mode:true,
                oldValue: event.target.name
            });
        } else {
            const temp = `${category}`;
            reset();
            handleInputChange({
                target: {
                    name: 'category',
                    value: temp
                }
            });
            selectRef.current.focus();

            // Set edit mode
            setEdit({
                id: '',
                mode:false,
            });
        }
    },[handleInputChange,labelRef,setEdit,reset,category])


    return (
        <div className="border border-2 border-primary py-3 px-3">
            <div className="d-flex border border-2 border-secondary px-2 py-2">
                <TextField
                    select
                    inputRef={selectRef}
                    className="me-2 pe-2 border-end border-2 border-secondary"
                    style={{ width: '15%' }}
                    label="Category"
                    name="category"
                    value={category}
                    onChange={handleInputChange}
                    helperText="Tag category"
                    SelectProps={{
                        native: true,
                    }}
                >
                    {
                        tagCategories.map((tag) => (
                            <option
                                key={tag}
                                value={tag}
                            >
                                {tag}
                            </option>
                        ))
                    }

                </TextField>

                <TextField
                    autoComplete="off"
                    inputRef={labelRef}
                    className="me-2 pe-2 border-end border-2 border-secondary"
                    style={{ width: '35%' }}
                    label="Category label"
                    name="label"
                    placeholder="Love Live!"
                    value={label}
                    onChange={handleInputChange}
                    helperText="'tis what will show up in the boxes"
                    onKeyUpCapture={
                        (e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                handleAdd();
                            }
                        }
                    }
                />

                <TextField
                    autoComplete="off"
                    className="flex-grow-1 me-2 pe-2 border-end border-2 border-secondary"
                    label="Extra tags"
                    name="extra"
                    placeholder="love live, sayohina"
                    value={extra}
                    onChange={handleInputChange}
                    helperText="Will help with searching. Use a comma to divide them."
                    onKeyUpCapture={
                        (e) => {
                            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                handleAdd();
                            }
                        }
                    }
                />

                <Button
                    disabled={label.trim() === ''}
                    onClick={handleAdd}
                >
                    { edit.mode ? 'Edit' : 'Add' }
                </Button>

            </div>
            <div
                className="container border border-top-0 border-2 border-secondary py-3"
                style={{ height: '30vh' }}
            >
                <TagDisplayer
                    categories={[category]}
                    bigTagList={[tagList]}
                    handleChange={handleEdit}
                />
            </div>
        </div>
    )
}
)

export default TagAdder;