import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getManga } from '../../helpers/firestore';
import MangaManager from './MangaManager';
import { Helmet } from "react-helmet";
import { linksList } from '../../constants/links';



const ManageMangaScreen = () => {

    const mangaTemplate = {
        author: {id:''},
        date: new Date(),
        description: '',
        img: '',
        name: '',
        thumbnail: '',
        links: linksList,
        collabs: [],
        tags: {
            general: {
                list: [],
                order: 0
            },
            series: {
                list: [],
                order: 1
            },
            characters: {
                list: [],
                order: 2
            },
            ships: {
                list: [],
                order: 3
            }
        },
        lewd: false
    }

    // Read from database if on the URL
    const { mangaId } = useParams();
    const [manga, setManga] = useState(null)
    const [initialAuthorId, setInitialAuthorId] = useState('');

    useEffect(() => {
        // Load from database database woah woah
        if (mangaId) {
            getManga(mangaId)
                .then((returnedManga) => {
                    setManga(returnedManga);
                    setInitialAuthorId(returnedManga.author.id);
                })
                .catch((error) => alert(error));
        }
    }, [mangaId])

    //console.log(manga)

    return (
        <>
            <Helmet>
                <title>Manga Manager</title>
            </Helmet>
            {
                mangaId
                    ?
                    manga
                        ?
                        <MangaManager
                            manga={manga}
                            editMode={true}
                            initialAuthorId={initialAuthorId}
                        />
                        :
                        'loading...'
                    :
                    <MangaManager
                        manga={mangaTemplate}
                        editMode={false}
                        initialAuthorId={initialAuthorId}
                    />
            }
        </>
    )

}

export default ManageMangaScreen;