export const isDate = (d) =>{
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work 
            return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
}

export const getFormattedDate = (date) => {
    if(!date){
        return '16/05/2021'
    }
    // Get parts of date
    const yyyy = date.getFullYear();
    const MM = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
    const dd = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

    return `${dd}/${MM}/${yyyy}`;
}

export const getFormattedDateJP = (date) => {
  if(!date){
      return '2022年4月11日'
  }
  // Get parts of date
  const yyyy = date.getFullYear();
  const MM = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
  const dd = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

  return `${yyyy}年${MM}月${dd}日`;
}


export const getSiteMapDate = (date) => {
  // Get parts of date
  const yyyy = date.getFullYear();
  const MM = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
  const dd = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

  return `${yyyy}-${MM}-${dd}`;
}