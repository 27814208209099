import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { filterTags } from '../../helpers/filters';
import { getTagList } from '../../helpers/firestore';
import TagDisplayer from '../tags/TagDisplayer';
import AddIcon from '@material-ui/icons/Add';
const ReactGA = require('react-ga4');

const MangaTagDialog = ({ handleSubmit, handleClose }) => {

    // tags n shit
    const [tagsLists, setTagsLists] = useState({
        generalTags: [],
        seriesTags: [],
        charactersTags: []
    });

    const [tagsMode, setTagsMode] = useState('any');

    // Ships stuff
    const [currentShip, setCurrentShip] = useState([]);
    const [shipList, setShipList] = useState([]);

    // Words to filter tags
    const [keyWords, setKeywordChange] = useState('');

    // Filtered tags
    const [filteredTagsLists, setFilteredTagsLists] = useState({
        filteredGeneralTags: [],
        filteredSeriesTags: [],
        filteredCharactersTags: []
    });

    const [bigTagList, setBigTagList] = useState([]);

    const {
        generalTags,
        seriesTags,
        charactersTags
    } = tagsLists;

    const {
        filteredGeneralTags,
        filteredSeriesTags,
        filteredCharactersTags
    } = filteredTagsLists;

    function reducer(state, action) {
        switch (action.type) {
            case 'add':
                return [...new Set([...state, action.payload].sort())];
            case 'remove':
                return [...new Set([...state.filter((tag) => tag !== action.payload)].sort())];
            default:
                return state;
        }
    }

    const [state, dispatch] = useReducer(reducer, []);

    const handleCheckTag = useCallback(({ target }, isChecked, category) => {
        if (isChecked) {
            dispatch({
                type: 'add',
                payload: target.name
            })
            if(category === 'characters'){
                // Add to ship array
                setCurrentShip((old) => [...new Set([...old, target.name])]);
            }
        } else {
            dispatch({
                type: 'remove',
                payload: target.name
            })
            if(category === 'characters'){
                // Remove from ship array
                setCurrentShip((old) => [...new Set([...old.filter((tag) => tag !== target.name)])]);
            }
        }
    }, [dispatch])

    useEffect(() => {
        Promise.all([getTagList('general'), getTagList('series'), getTagList('characters')])
            .then((resolvedArray) => {
                setTagsLists({
                    generalTags: resolvedArray[0],
                    seriesTags: resolvedArray[1],
                    charactersTags: resolvedArray[2]
                });
            })
    }, [setTagsLists])

    useEffect(() => {
        setFilteredTagsLists({
            filteredGeneralTags: filterTags(generalTags, keyWords),
            filteredSeriesTags: filterTags(seriesTags, keyWords),
            filteredCharactersTags: filterTags(charactersTags, keyWords),
        })
    }, [generalTags, seriesTags, charactersTags, keyWords])

    useEffect(() => {
        setBigTagList([filteredGeneralTags, filteredSeriesTags, filteredCharactersTags]);
    }, [filteredGeneralTags, filteredSeriesTags, filteredCharactersTags])

    const handleAddShip = () => {
        let newTag = ""
        // Always keeps the same order
        const newShip = [...currentShip].sort();

        newShip.forEach((tag, index) => {
            newTag = `${newTag}${tag}`;
            if (index !== newShip.length - 1) {
                newTag = `${newTag} x `;
            }
        })

        // Makes it a Google Analytics event
        ReactGA.event({
            category: 'MangaTags',
            action: 'Made a ship',
            label: newTag
        })

        // Checks it as a tag
        handleCheckTag({target:{name:newTag}},true,'ships');

        // Creates the new list and sort it for the tags listing
        setShipList((old) => [...new Set([...old, newTag].sort())]);
    }

    const handleDeleteShip = (newTag) => {
        // Unchecks it as a tag
        handleCheckTag({target:{name:newTag}},false,'ships');
        
        // Removes it from the list
        setShipList((old) => [...new Set([...old.filter((tag) => tag !== newTag)])]);
    }

    return (
        <Dialog 
            open 
            onClose={handleClose} 
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={false}
        > 
            <DialogTitle className="pb-0" id="form-dialog-title">Filter by tags</DialogTitle>
            <DialogContent>
                <div className="d-flex flex-column border border-2 mb-2 border-secondary" style={{height:'50vh'}}>
                    <div className="d-flex border-2 border-bottom border-secondary">
                        <TextField
                            autoComplete="off"
                            value={keyWords}
                            onChange={(e)=>setKeywordChange(e.target.value)}
                            name='keyWords'
                            className="form-control flex-grow-1"
                            type='text'
                            fullWidth
                            label="Search tags"
                            placeholder="Keywords"
                        />
                    </div>
                    <div className="h-100 overflow-auto d-flex flex-column">
                        <TagDisplayer
                            categories={['general','series','characters']}
                            bigTagList={bigTagList}
                            handleChange={handleCheckTag}
                        />
                        {
                            shipList.length === 0
                                ||
                            <>
                                <div className="d-flex">
                                    <h5>Ships</h5>
                                    <span className="ms-3 span-tap">Tap to delete</span>
                                </div>
                                <div>
                                    {
                                        shipList.map((ship) => (
                                            <span
                                                key={ship}
                                                className="me-3 border border-2 border-danger px-3 rounded-pill"
                                                role="button"
                                                onClick={() => {handleDeleteShip(ship)}}
                                            >
                                                {ship}
                                            </span>
                                        ))
                                    }
                                </div>
                            </>
                        }
                        <br/>
                        <Button
                            className="w-100 mt-auto rounded-0"
                            variant="contained"
                            color="primary"
                            disabled={currentShip.length === 0}
                            startIcon={<AddIcon />}
                            onClick={handleAddShip}
                        >
                            Add ship
                        </Button>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex mx-auto">
                        <span>
                            Must have 
                        </span>
                        <RadioGroup 
                            row
                            value={tagsMode}
                            onChange={(e)=>setTagsMode(e.target.value)}
                        >
                            <FormControlLabel
                                value="any"
                                control={<Radio color="primary" />}
                                label="any"
                                labelPlacement="top"
                            />
                            <span>
                                /
                            </span>
                            <FormControlLabel
                                value="all"
                                control={<Radio color="primary" />}
                                label="ALL"
                                labelPlacement="top"
                            />
                        </RadioGroup>
                        <span>
                            of these tags
                        </span>
                    </div>
                </div>
                <div className="d-flex flex-column border border-2 mb-2 border-secondary" style={{height:'15vh'}}>
                <div className="h-100 overflow-auto">
                    <TagDisplayer
                        categories={['selected']}
                        bigTagList={[state]}
                        handleChange={()=>{}}
                    />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => { handleSubmit(state, tagsMode) }} color="primary">
                    Set tags
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MangaTagDialog;