import React, { useEffect } from 'react'
import {
    Routes,
    Route,
    useLocation
} from 'react-router-dom';
import Footer from '../ui/Footer';
import NavBar from '../ui/NavBar';
import { MangaScreen } from '../screens/MangaScreen';
import ViewMangaScreen from '../screens/ViewMangaScreen';
import HomeScreen from '../screens/HomeScreen';
import ViewAuthorScreen from '../screens/ViewAuthorScreen';
import AuthorsScreen from '../screens/AuthorsScreen';
import AdminRouter from './AdminRouter';
import ReactGA from "react-ga4";
import AboutScreen from '../screens/AboutScreen';
import Cookies from 'universal-cookie';
import ScrollToTop from './ScrollToTop';
import NicoYumeMainScreen from '../screens/NicoYumeMainScreen';
import NicoYumeNotesScreen from '../screens/NicoYumeNotesScreen';
import MainAdminScreen from '../admin/MainAdminScreen';
import ManageMangaScreen from '../admin/ManageMangaScreen';
import AddTagsScreen from '../admin/AddTagsScreen';
import LoginScreen from '../screens/LoginScreen';
import GranbluedleScreen from '../screens/GranbluedleScreen';

const cookies = new Cookies();

const AppRouter = () => {

    const isIdol = cookies.get('no1idol');

    const location = useLocation();
    useEffect(() => {
        if (location) {
            ReactGA.set({ page: location.pathname }); // Update the user's current page
            ReactGA.send({ hitType: "pageview", page: location.pathname });
        }
    }, [location]);

    return (
        <>
            <NavBar />
            <main className={`pt-3 flex-shrink-0`} style={ 
                location.pathname === '/nicoyume' ? 
                    isIdol ? 
                {backgroundImage: 'url(/images/nicoyume/realspace.jpg)', backgroundSize:'contain', width:'100%'} 
                    : 
                {backgroundImage: 'url(/images/nicoyume/space.png)', backgroundSize:'cover',width:'100%'} 
                    : 
                    location.pathname.includes('/granbluedle') ?
                {backgroundImage: 'url("https://prd-game-a5-granbluefantasy.akamaized.net/assets_en/img/sp/raid/bg/common_029.jpg")', backgroundSize: 'cover' ,width:'100%', minHeight:'100%'} 
                    :
                {} } >
                <ScrollToTop />
                <Routes>
                    <Route path='/nicoyume'>
                        <Route exact path='/nicoyume' element={<NicoYumeMainScreen />} />
                        <Route path='/nicoyume/notes' element={<NicoYumeNotesScreen />} />
                    </Route>
                    <Route path='/admin' element={<AdminRouter />}>
                        <Route exact path='/admin' element={<MainAdminScreen />}/>
                        <Route exact path='/admin/manga' element={<ManageMangaScreen />}/>
                        <Route path='/admin/manga/:mangaId' element={<ManageMangaScreen />}/>
                        <Route exact path='/admin/tags' element={<AddTagsScreen />}/>
                    </Route>
                    <Route exact path="/admin/login" element={<LoginScreen />}/>
                    <Route exact path='/manga' element={<MangaScreen />}/>
                    <Route path="/manga/:mangaId" element={<ViewMangaScreen />}/>
                    <Route exact path='/authors' element={<AuthorsScreen />}/>
                    <Route path="/authors/:authorId" element={<ViewAuthorScreen />}/>
                    <Route path="/about" element={<AboutScreen />}/>
                    <Route path="/granbluedle" element={<GranbluedleScreen endlessMode={false}/>}/>
                    <Route path="/granbluedle/endless" element={<GranbluedleScreen endlessMode={true}/>}/>
                    <Route path="/granbluedle/endless/:weaponId?/:hardParam?" element={<GranbluedleScreen endlessMode={true}/>}/>
                    <Route exact path="/" element={<HomeScreen />}/>
                </Routes>
            </main>
            <Footer />
        </>
    )
}

export default AppRouter;