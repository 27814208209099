import React from 'react'
import { getFormattedDate, getFormattedDateJP } from '../../helpers/dates.js'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StarIcon from '@mui/icons-material/Star';
import { elementIcon, typeIcon } from '../../helpers/granbluedle.js';

const char = require.context('../../../public/images/granbluedle/char')
const wpn = require.context('../../../public/images/granbluedle/wpn')
const skill = require.context('../../../public/images/granbluedle/skill')
const skilljp = require.context('../../../public/images/granbluedle/skilljp')

const GuessSheet = ({ guesssheet, jpMode }) => {
    const { guess, sheet, header } = guesssheet

    if (!header) {
        const {
            awakening,
            element,
            id,
            jpname,
            release,
            series,
            seriesjp,
            type,
            uncap,
            weapon_name,
            obtains,
            skills,
            character_name,
            characterjp
        } = guess;

        const {
            awakening: answer_awakening,
            element: answer_element,
            obtains: answer_obtains,
            release: answer_release,
            series: answer_series,
            skills: answer_skills,
            type: answer_type,
            uncap: answer_uncap
        } = sheet

        var colorObtain = {};
        var colorSkill = {};

        answer_obtains === 'Correct' ?
            colorObtain = { backgroundColor: 'rgb(74 222 128)', display: 'flex', flexDirection: 'column' }
            :
            answer_obtains === 'Partial' ?
                colorObtain = { backgroundColor: 'rgb(251 146 60)', display: 'flex', flexDirection: 'column' }
                :
                colorObtain = { backgroundColor: 'rgb(248 113 113)', display: 'flex', flexDirection: 'column' };

        answer_skills === 'Correct' ?
            colorSkill = { backgroundColor: 'rgb(74 222 128)', display: 'flex', flexDirection: 'column', textAlign: 'left ' }
            :
            answer_skills === 'Partial' ?
                colorSkill = { backgroundColor: 'rgb(251 146 60)', display: 'flex', flexDirection: 'column', textAlign: 'left ' }
                :
                colorSkill = { backgroundColor: 'rgb(248 113 113)', display: 'flex', flexDirection: 'column', textAlign: 'left ' };

        return (
            <div style={{width: '1250px'}} className='text-center m-1 d-flex flex-row me-2 gridsheet-answer'>
                <div className='border border-2 border-secondary rounded d-flex flex-column grub-square-2' style={{ backgroundColor: 'lightgray' }} >
                    <a href={`https://gbf.wiki/${weapon_name}`} target='_blank' rel='noreferrer' style={{textDecoration: 'none'}}>
                        <img src={wpn(`./${id}.png`)} alt={weapon_name} width='100%' />
                        <div className='pb-2' style={{color:'black'}}>
                            {jpMode ? jpname : weapon_name}
                        </div>
                    </a>
                    {
                        character_name != null
                            ?
                            <div className='py-2' style={{borderTop: '2px dotted black'}}>
                            <img 
                                className='me-2'
                                loading="lazy"
                                width={40}
                                src={char(`./${character_name}${weapon_name}.png`)}
                                alt={character_name}
                            />
                            <span>
                                {jpMode ? characterjp : character_name}
                            </span>
                        </div>
                            :
                        <></>
                    }
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 d-flex ' style=
                    {
                        answer_release === 'Correct' ?
                            { backgroundColor: 'rgb(74 222 128)', }
                            :
                            answer_release.includes('Close')
                                ?
                                { backgroundColor: 'rgb(251 146 60)' }
                                :
                                { backgroundColor: 'rgb(248 113 113)' }}>
                    <div className='m-auto text-break'>
                        {
                            answer_release === 'Higher Far' ?
                                <><ArrowUpwardIcon /><ArrowUpwardIcon /></>
                                :
                                answer_release === 'Higher Close' ?
                                    <ArrowUpwardIcon />
                                    :
                                    answer_release === 'Lower Far' ?
                                        <><ArrowDownwardIcon /><ArrowDownwardIcon /> </>
                                        :
                                        answer_release === 'Lower Close' ?
                                            <ArrowDownwardIcon />
                                            :
                                            <></>
                        }
                        <br/>{jpMode ? getFormattedDateJP(new Date(release)) : getFormattedDate(new Date(release))}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-15 ms-1 border-2 border-secondary d-flex flex-column justify-content-center' style=
                    {
                        answer_element === 1 ?
                            { backgroundColor: 'rgb(74 222 128)', textAlign: 'center', verticalAlign: 'middle' }
                            : answer_element === 2  || answer_element === 3 ?
                            { backgroundColor: 'rgb(251 146 60)', textAlign: 'center', verticalAlign: 'middle'}
                            :
                            { backgroundColor: 'rgb(248 113 113)', textAlign: 'center', verticalAlign: 'middle' }}>
                    {
                        answer_element === 3 
                            ?
                            <img className='mx-auto' width='90%' src={elementIcon('weak', jpMode)} alt='advantage'/>
                            :
                            answer_element === 2
                            ?
                            <img className='mx-auto' width='90%' src={elementIcon('resist', jpMode)} alt='resist'/>
                            :
                            <></>
                    }
                    <img className='mx-auto mt-1' width='60%' src={elementIcon(element, jpMode)} alt={element} />
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 border-2 border-secondary d-flex' style=
                    {
                        answer_type ?
                            { backgroundColor: 'rgb(74 222 128)', }
                            :
                            { backgroundColor: 'rgb(248 113 113)' }}>
                    <img className='m-auto' width='60%' src={typeIcon(type, jpMode)} alt={type} />
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 border-2 border-secondary d-flex' style=
                    {
                        answer_series ?
                            { backgroundColor: 'rgb(74 222 128)', }
                            :
                            { backgroundColor: 'rgb(248 113 113)' }}>
                    <div className='m-auto'>
                        {jpMode ? seriesjp : series}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 border-2 border-secondary d-flex' style=
                    {
                        answer_awakening ?
                            { backgroundColor: 'rgb(74 222 128)', }
                            :
                            { backgroundColor: 'rgb(248 113 113)' }}>
                    <div className='m-auto'>
                        {jpMode ? awakening === 'No' ? 'なし' : 'ある' : awakening}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 border-2 border-secondary d-flex' style=
                    {
                        answer_uncap ?
                            { backgroundColor: 'rgb(74 222 128)', }
                            :
                            { backgroundColor: 'rgb(248 113 113)' }}>
                    <div className='m-auto'>
                        {uncap} <StarIcon />
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-2 ms-1 border-2 border-secondary d-flex' style={colorObtain}>
                    <div className='m-auto '>
                        {
                            obtains.map(obt => <div key={obt.name} className='text-break'>{jpMode ? obt.jp: obt.name}</div>)
                        }
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-2 ms-1 border-2 border-secondary d-flex text-left' style={colorSkill}>
                    <div className='my-auto text-left mx-1' >
                        {
                            skills.map(sk => <div key={sk.name}><img src={jpMode ? skilljp(`./${sk.jp}.png`) : skill(`./${sk.name}.png`)} alt={sk.name} width='15%' /> {jpMode? sk.jp : sk.name}</div>)
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{width: '1250px', position: 'sticky', top: '0'}} className='text-center ms-1 d-flex flex-row  me-2' >
                <div className='border border-2 border-secondary rounded d-flex flex-column grub-square-2' style={{ backgroundColor: 'lightgray' }} >
                    <div className='m-auto w-100'>
                        {jpMode ? '武器' : 'Weapon'}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 d-flex ' style={{ backgroundColor: 'lightgray' }}>
                    <div className='m-auto w-100'>
                        {jpMode ? '登録日' : 'Release'}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-15 ms-1 border-2 border-secondary d-flex' style={{ backgroundColor: 'lightgray' }}>
                    <div className='m-auto w-100'>
                        {jpMode ? '属性' : 'Element'}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 border-2 border-secondary d-flex' style={{ backgroundColor: 'lightgray' }}>
                    <div className='m-auto w-100'>
                        {jpMode ? '種' : 'Type'}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 border-2 border-secondary d-flex' style={{ backgroundColor: 'lightgray' }}>
                    <div className='m-auto w-100'>
                        {jpMode ? 'シリーズ' : 'Series'}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 border-2 border-secondary d-flex' style={{ backgroundColor: 'lightgray' }}>
                    <div className='m-auto w-100'>
                        {jpMode ? '覚醒' : 'Awakening'}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-1 ms-1 border-2 border-secondary d-flex' style={{ backgroundColor: 'lightgray' }}>
                    <div className='m-auto w-100'>
                        {jpMode ? '上限解放' : 'Max Uncap'}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-2 ms-1 border-2 border-secondary d-flex' style={{ backgroundColor: 'lightgray' }}>
                    <div className='m-auto w-100'>
                        {jpMode ? '入手方法' : 'Obtain'}
                    </div>
                </div>
                <div className='border border-2 border-secondary rounded grub-square-2 ms-1 border-2 border-secondary d-flex' style={{ backgroundColor: 'lightgray' }}>
                    <div className='my-auto mx-1 w-100' >
                        {jpMode ? 'スキル' : 'Skills'}
                    </div>
                </div>
            </div>
        )
    }


}

export default React.memo(GuessSheet, (prev, curr) => {
    if (prev.guesssheet.header === curr.guesssheet.header){
        if (!curr.guesssheet.header) {
            // Weapon and jp
            return prev.guesssheet.guess.weapon_name === curr.guesssheet.guess.weapon_name && prev.jpMode === curr.jpMode
        } else {
            return prev.jpMode === curr.jpMode
        }
    }
    return false;
});