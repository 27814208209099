import NicoYumeDialog from '../nicoyume/NicoYumeDialog';
import React, { useState } from 'react'
import Cookies from 'universal-cookie';
import NicoYumeRealNotes from '../nicoyume/NicoYumeRealNotes';
const ReactGA = require('react-ga4');

const cookies = new Cookies();

const NicoYumeNotesScreen = () => {

    const [isIdol, setIsIdol] = useState(cookies.get('no1idol'));

    const handleClose = () => {
        setIsIdol('wrong');
        ReactGA.event({
            category: 'NicoPass',
            action: 'Closed window',
            label: 'Closed window',
        })
    }

    const handleSubmit = (name) => {
        if (name === '252524ever') {
            cookies.set('no1idol', 'Nico Yazawa', { path: '/' })
            setIsIdol('Nico Yazawa');
            ReactGA.event({
                category: 'NicoPass',
                action: 'Success!',
                label: 'Played till the end',
            })
        } else {
            setIsIdol('wrong');
            ReactGA.event({
                category: 'NicoPass',
                action: 'Wrong',
                label: name,
            })
        }
    }

    return (
        <>
            {
                isIdol === 'Nico Yazawa'
                    ?
                    <div className="container-fluid alert alert-success">
                        Thank you for playing! You now have access to all of this page, also,
                        there's a neat surprise if you go back to the main Nico page. (Might need to refresh or hard refresh with Control + F5)
                    </div>
                    :
                    isIdol === 'wrong'
                        ?
                        <div className="container-fluid alert alert-danger">
                            You still haven't reached the end, or maybe you just mispelled something?
                            Either way you can still read this page but beware of spoilers.
                        </div>
                        :
                        <NicoYumeDialog
                            handleSubmit={handleSubmit}
                            handleClose={handleClose}
                        />
            }
            <div className="content">
                The title of the game is "<b>Yazawa Nico wa yume wo Miru</b>" which means to dream or to have a dream, but read more literally could also just be "see a dream", naturally Nico does both things as she is in a dream world and has to face other people's dreams, and eventually her own. An alternative could have been something like "Nico Yazawa Is Dream Gazing" or perhaps "Nico Yazawa Is Seeing Dreams" but those feel a bit clunky and place more focus on the second meaning rather than the first, which is part of the game's facade.
                <br /><br />
                Pretty much every enemy in the game is either a pun on "Chun", "Slime" or the dreamer's name, speaking of chun, that's just a generic bird noise but it's also associated with Kotori, since she's a bird. The decision to keep it as chun and not something like cheep / tweet / caw / etc. was due to the chunslimes being weird creatures in general, so their vocabulary reflects this, it's even put more in contrast with the normal looking bird, Chirpy, who just says chirp instead.
                <br /><br />
                Sleezymes are named <i>Charaimu</i>, a play on chunslime and <i>charao</i>, which is slang for sleazy and shallow playboys.
                <br /><br />
                Rapslimes are <i>Chekeraimu</i>, which is short for "check it", as in "check it out, yo!"
                <br /><br />
                Minalinslimeky is a mix of slime and <i>Minalinsky</i>, the name of the legendary charisma maid working in Akihabara, aka, Kotori with a false name. Her defeat line is also what she says in the show before running away from Umi.
                <br /><br />
                Chirpy Trio is just three Chirpys together, but their attack line (and Kotori's dream quote thingy) is "When the three of us are together we bare our fangs'' which is a reference to <a href="http://riceballman.fc2web.com/AA-Illust/Data/ChunChun.html">this</a> 2ch <a href="https://www.nicovideo.jp/watch/nm14868670
">meme</a>, which is a joke on the lyrics of <a href="https://www.youtube.com/watch?v=CgL98JSFoXw
">Chun Chun World</a>, a song broadcast in the radio channel <i>Minna no Uta</i> which is meant to teach children through song.
                One bird goes chun, two birds go chun chun, all three together BARE THEIR FANGS.<br />
                Also of note is that this is the only enemy that has a different line depending on your difficulty, as the defeat line changes in hard (probably an oversight).
                <br /><br />
                Budhaime is entirely based on the fact that 仏 looks like イム, which is the "ime" part of slime. Its dialogue is similarly based on buddhism chants but with chun put randomly instead of other words.
                <br /><br />
                Chunslime Macchiato is just a chunslime but macchiato is spelled as <i>maki aato</i>, so it's here.
                <br /><br />
                She-Chunslime and She-Chirpy are based on She-Slimes from Dragon Quest (hence the coloring), which is also somewhat unfortunate given Girl Chirpy is also an enemy, but not one many players will encounter (or survive if they do).
                <br /><br />
                Chuunimes are based on <i>chuunibyou</i>, slang for people (usually early teens) with delusions of grandeur and amazing powers. One common trope is having demons contained within one of their eyes.
                <br /><br />
                Chika fish (also known as <i>Hypomesus japonicus</i>) are just there because of the name Elichika, the enemy appearance and lines are based on <a href="https://seiga.nicovideo.jp/seiga/im10183632">this meme of a distressed looking fish about to be cut</a>, which is itself based on <a href="https://stat.ameba.jp/user_images/20151208/10/ace0828/e6/59/j/o0480034713505806685.jpg?caw=1125">this image</a>.
                <br /><br />
                King Trumpet Chunshroom are based on King trumpet mushrooms which are also known as eryngii, as the Japanese name of the enemy is <i>Churyngi</i>.
                <br /><br />
                Yezo Siza and Yezoshika (yes they're two different enemies), both are based on Yezo sika deer (<i>Cervus nippon yesoensis</i>) and again it's just because they have chika in their name, somewhat. Also their lines are references to Persona 4 (specifically from Yosuke), for some reason.
                <br /><br />
                Churaumi is the name of an aquarium in Okinawa, which is why all the dialogue it says is with that dialect. The appearing line is "<i>Mensore!</i>" which is a traditional welcome in Okinawan dialect. The defeat line is one of the lyrics for the Okinawan song しまんちゅぬたから. As for why it's an enemy, probably because it starts with "chu".
                <br /><br />
                Katchunsha is a reference to Katyusha (<i>Катюша</i>), a rather well known Russian song made popular during World War II. The attack lines are also the lyrics with some words replaced with chun.
                <br /><br />
                Chunslime Stew is a pun on <i>niko mu</i>, which means to boil/stew/simmer. Appearing on the same floor is also Chunned Noodles, which is <i>Hiyashi chūka​</i> (chilled noodles) but with chun instead, so they are somewhat opposites.
                <br /><br />
                Unicorn is there because it has Nico in its name.
                <br /><br />
                Nico Nico Sliime's attack line is what Nico said to Rin when she first shows her act to the members of μ's before joining.
                <br /><br />
                LLL, also known as The Legend of Legendary Idols complete DVD box set, appears in the show a few times, Hanayo mentions just how rare it is and is amazed at the fact that Nico has three of them, then she ends up receiving one in the final episode of the second season as a gift from her.
                <br /><br />
            </div>
            {
                isIdol === 'Nico Yazawa'
                    ?
                    <NicoYumeRealNotes />
                    :
                    <>
                        <p className="text-center mt-1 mb-2">Finish the game to see this section.</p>
                        <div className="content hidden mb-3">
                            <span className="biggerText">Nice try sucker.</span>
                            
                            <br /><br />
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a consectetur nulla, et varius libero. Nam dictum, tellus et fermentum efficitur, eros libero fringilla lorem, sed faucibus nibh ex nec arcu. Curabitur sed elementum orci. Mauris mollis, dolor in vehicula sagittis, turpis ipsum elementum ipsum, pellentesque interdum neque neque sed ipsum. Ut justo orci, porttitor interdum maximus sit amet, congue in dolor. Suspendisse placerat nec tortor quis hendrerit. Aliquam scelerisque orci lectus, eget maximus quam tincidunt eget. Vivamus placerat, diam eget tincidunt pellentesque, lectus neque bibendum metus, eget consequat nisi nibh at purus.
                            <br /><br />
                            Quisque vestibulum turpis nulla, faucibus rhoncus neque ultricies sed. In eget fermentum mauris. Nam porttitor, mi quis commodo venenatis, augue lorem ullamcorper magna, ac lacinia ligula felis sed orci. Cras hendrerit, magna consectetur semper laoreet, nulla tortor dictum mi, a iaculis felis ipsum at mi. Donec eleifend libero eget massa fringilla vulputate. Nulla eu tincidunt erat. Ut vitae sem sit amet ligula mollis volutpat.
                            <br /><br />
                            Nam quis accumsan massa, id varius leo. Phasellus consectetur velit faucibus nisl suscipit ornare. Donec semper interdum faucibus. Donec nec sapien nunc. Nam ut sem vel lectus imperdiet viverra. Integer vestibulum, eros sed auctor auctor, orci turpis pellentesque nulla, at cursus lectus nulla ut neque. Nullam eget orci interdum, facilisis odio quis, tincidunt turpis.
                            <br /><br />
                            Phasellus ultricies eu enim id rutrum. Suspendisse elementum mattis odio, vel auctor nibh commodo quis. Suspendisse pharetra scelerisque dapibus. Cras ultrices ultricies condimentum. Praesent at ipsum tellus. Cras feugiat augue eget justo pellentesque, et laoreet nulla consectetur. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis rhoncus, nulla in molestie egestas, orci lectus suscipit leo, et tincidunt purus metus eget ligula. Cras lacinia non nisi at porttitor. Maecenas molestie est at elementum tempus. Nam diam ligula, bibendum ac velit eu, pretium dignissim enim. Suspendisse potenti. Curabitur vehicula est sit amet enim dictum, eget pulvinar nisl euismod. Nullam non ipsum purus. Maecenas mollis mi nec sapien laoreet porta.
                            <br /><br />
                            Etiam pretium elementum ullamcorper. Sed eget lorem ultrices, molestie libero ac, egestas erat. Pellentesque enim turpis, tincidunt ac mauris eget, rhoncus suscipit nisl. Duis tristique porta scelerisque. Sed laoreet nibh a varius mollis. Proin lacus nibh, suscipit quis fermentum ut, iaculis sit amet dui. Phasellus pulvinar sapien ac nisl commodo, non tempus odio euismod. Nulla dignissim tellus arcu, a sagittis metus tristique a. Donec ut nulla aliquet, dignissim massa ac, consequat dui. Vestibulum vel mattis sapien. Mauris in facilisis dui, non lacinia metus. Quisque sed dui interdum, ultrices lorem eget, facilisis ipsum. Nullam id neque id velit porttitor rhoncus.
                            <br /><br />
                            In vel neque venenatis, lacinia lectus non, tristique augue. Pellentesque ornare vulputate pellentesque. Nam venenatis pharetra sodales. Curabitur ac vehicula nunc. Proin vehicula convallis lectus non commodo. Etiam aliquam iaculis diam in luctus. Aenean augue dolor, bibendum et dolor iaculis, convallis rutrum erat. Sed et ullamcorper magna. Nam at sem vel diam maximus vulputate vel id nisl. In lacinia vestibulum finibus. Aliquam imperdiet eget lectus a hendrerit. Quisque rhoncus consequat dignissim. Donec at augue varius, lacinia odio nec, cursus nunc.
                            <br /><br />
                            Phasellus tincidunt odio a diam porttitor laoreet. Integer et felis ex. Fusce porta, orci id commodo rhoncus, purus tortor suscipit sem, eu vulputate eros dolor eget sapien. Cras a faucibus ligula. Suspendisse potenti. Donec vitae mi risus. Duis congue fringilla ante, et tincidunt dolor consequat at. Maecenas nec blandit nisi, et cursus erat. Nulla lobortis interdum leo, eu dictum nisl lacinia porta. Vestibulum nisi odio, euismod tincidunt suscipit eget, porta id nisl. Nam et ante turpis. Curabitur volutpat massa id arcu pretium fermentum vel a dolor. Cras condimentum nibh enim, vestibulum dapibus eros pretium vitae.
                            <br /><br />
                            Morbi feugiat lorem eget sapien vestibulum laoreet. Integer hendrerit blandit erat a sollicitudin. Pellentesque et gravida turpis, sed euismod lectus. Quisque id rutrum tellus, a consectetur ligula. Morbi tincidunt pellentesque gravida. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum a lacinia leo. Vivamus sed dui dignissim, placerat augue a, iaculis augue.
                            <br /><br />
                            Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris dapibus urna velit, ac varius lectus tempus aliquet. Aenean sapien nunc, vehicula quis suscipit blandit, rhoncus eu nulla. Donec ac ante ut diam tristique tristique non sit amet est. Duis orci leo, porttitor non lacus non, commodo dignissim metus. Proin tempus pulvinar lobortis. Vestibulum vestibulum leo vitae mauris dignissim consequat. Nulla ut neque eget arcu tincidunt pulvinar vitae in lectus. Suspendisse vulputate odio nulla. Cras congue semper odio sed eleifend. Pellentesque ut sem quis urna suscipit elementum vel sit amet diam.
                            <br /><br />
                            Phasellus non feugiat lorem. Donec rutrum arcu in ligula tincidunt, et hendrerit tellus gravida. Duis eget neque non mauris congue consequat id ut felis. Fusce tempor malesuada ante ac venenatis. Vestibulum ante nulla, tempor nec suscipit eu, mollis placerat mauris. Sed sapien elit, condimentum in sapien eu, interdum condimentum tellus. Pellentesque rhoncus dui quis ligula vulputate, pharetra pharetra erat ullamcorper.
                        </div>
                    </>
            }
        </>
    )
}

export default NicoYumeNotesScreen;