import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import 'firebase/app-check';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyApq8Iz-IN6vy-ydGAbmog5WMj3znJNXoM",
    authDomain: "rudytudysubs-21829.firebaseapp.com",
    projectId: "rudytudysubs-21829",
    storageBucket: "rudytudysubs-21829.appspot.com",
    messagingSenderId: "1008570746274",
    appId: "1:1008570746274:web:58eaa8c4dc4d96934f5efb",
    measurementId: "G-E61CFM3L0B"
  };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

// const appCheck = firebase.appCheck();
// appCheck.activate('6LeNeeYaAAAAAKs3y0NXA-c94_dVZ_sVyTOLxlcy');

const auth = firebase.auth();
const db = firebase.firestore();


export {
    auth,
    db,
    firebase
}