import React, { useReducer } from 'react'
import { AuthContext } from './components/admin/AuthContext';
import AppRouter from './components/routers/AppRouter'
import { auth } from './firebase/firebase-config';
import ReactGA from "react-ga4";
import { BrowserRouter } from 'react-router-dom';

const RudySiteApp = () => {

    const init = () => {
        return JSON.parse(localStorage.getItem('authUser')) || null
    }

    const [user] = useReducer(() => { }, '', init);

    auth.onAuthStateChanged((user) => {
        user
            ? localStorage.setItem('authUser', JSON.stringify(user))
            : localStorage.removeItem('authUser')
    })

    ReactGA.initialize('G-LB6BG8C92E');

    return (
        <AuthContext.Provider value={({ user })}>
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </AuthContext.Provider>
    )
}

export default RudySiteApp;