import React from 'react'

// Get images for links
const images = require.context('../../../public/images/nicoyume/extra');
const nicos = require.context('../../../public/images/nicoyume/extra/nicos');
const club = require.context('../../../public/images/nicoyume/extra/club');
const comics = require.context('../../../public/images/nicoyume/extra/comics');

const NicoYumeRealNotes = () => {
    return (
        <div className="content mb-3 mt-3">
            <span className="biggerText">Secret stuff for finishing the game</span>
            <br /><br />
            Here you can find unused, cut, and unfinished content that's still in the game files somewhere, also just random stuff that I feel like mentioning.
            <br /><br />
            If you want to revisit a part of the game you can change the text inside the "save.txt" file in the game directory to one of these values:
            <br /><br />
            <b>after intro:</b> (anything works as long as save.txt exists) <br />
            <b>after tutorial:</b> tZT18u5eEzReJ+ISEz/TG2VV <br />
            <b>after kotori:</b> tZT18u5eEzdeJ+ISEzBYBiXV <br />
            <b>after maki:</b> tZT18u5eEzBeJ+ISEzDjBiXV <br />
            <b>after eli:</b> tZT18u5eEz2eJ+ISEzRKErCo <br />
            <b>nico's revenge:</b> tZT18u5eEzseJ+ISEzsTEQ5o <br />
            <b>after crash:</b> tZT18u5eEz/eJ+ISEzDYCrao <br />
            <b>end:</b> tZT18u5eEzHeJ+ISEzOo <br />
            <br />
            <a href="https://www.youtube.com/playlist?list=PLRZvQLwph9W-H9eozW49dydI9PbZA1E9g" title="Youtube Dev Logs">
            Here's a bunch of videos I recorded while working on the translation, used to know how to time the delays and stuff.
            </a>
            <br/><br/>
            There's the art used in the trial version of the game (also the image that shows up if you link the game page), along with the mockup for it:
            <br />
            <table className="screenshots">
                <tbody>
                    <tr><td><img src={(images('./trial.png'))} alt="Trial" /></td>
                        <td><img src={(images('./trialB.png'))} alt="Trial mockup" /></td></tr>
                </tbody>
            </table>
            <br />
            Also an unused title, along with its mockup:
            <br />
            <table className="screenshots">
                <tbody>
                    <tr><td><img src={(images('./titleA.png'))} alt="TitleAlt" /></td>
                        <td><img src={(images('./titleB.png'))} alt="TitleAlt mockup" /></td></tr>
                </tbody>
            </table>
            <br />
            Also a really old temporary title screen for the alpha of the game, featuring an unused name for it: "Nico's Wish Upon a Universe":
            <br />
            <table className="screenshots">
                <tbody>
                    <tr><td><img src={(images('./cutName.png'))} alt="Temp" /></td></tr>
                </tbody>
            </table>
            <br />
            There's a few old versions of the Nico portraits, with the actually used ones having her on the bottom edge as she is in-game, instead of being some sort of floating image like the other two:
            <br />
            <table className="screenshots">
                <tbody>
                    <tr>
                        <td><img src={(nicos('./old1.png'))} alt="Old" /></td>
                        <td><img src={(nicos('./old2.png'))} alt="Old NicoNicoNii" /></td>
                    </tr>
                    <tr>
                        <td><img src={(nicos('./mid1.png'))} alt="NotThatOld" /></td>
                        <td><img src={(nicos('./mid2.png'))} alt="NotThatOld NicoNicoNii" /></td>
                    </tr>
                    <tr>
                        <td><img src={(nicos('./nico1.png'))} alt="Current" /></td>
                        <td><img src={(nicos('./nico2.png'))} alt="Current NicoNicoNii" /></td>
                    </tr>
                </tbody>
            </table>
            <br /><br />
            Much the same, there's a few doodles of enemies in Kotori's dream:
            <br />
            <table className="screenshots">
                <tbody>
                    <tr>
                        <td><img src={(images('./chara.png'))} alt="charaimu" /></td>
                        <td><img src={(images('./mahou.png'))} alt="mageslime" /></td>
                    </tr>
                    <tr>
                        <td><img src={(images('./bosskotori.png'))} alt="kotori" /></td>
                        <td><img src={(images('./choraimu.png'))} alt="choraimu" /></td>
                    </tr>
                </tbody>
            </table>
            <br />
            Interestingly, the mage chunslime is still used in the game as the indicator you're in God Mode if you activate that debug option.
            <br /><br />
            There's a few sketches of what the clubroom is like, much of it remains the same in-game:
            <br />
            <table className="screenshots">
                <tbody>
                    <tr>
                        <td><img src={(club('./1.JPG'))} alt="1" /></td>
                        <td><img src={(club('./2.JPG'))} alt="2" /></td>
                    </tr>
                    <tr>
                        <td><img src={(club('./3.JPG'))} alt="3" /></td>
                        <td><img src={(club('./4.JPG'))} alt="4" /></td>
                    </tr>
                    <tr>
                        <td><img src={(club('./5.JPG'))} alt="5" /></td>
                        <td><img src={(club('./6.JPG'))} alt="6" /></td>
                    </tr>
                    <tr>
                        <td><img src={(club('./7.JPG'))} alt="7" /></td>
                        <td><img src={(club('./kotori.PNG'))} alt="8" /></td>
                    </tr>
                </tbody>
            </table>
            <br />
            Two things to note are the set of three in the club plus four outside was already decided, and having multiple save files. Also no vuvuzelas, which makes it immensely inferior.
            <br /> <br />
            The group previously worked on some comics that have parts of this game, clearly this is a story they've been wanting to tell for a long time, the first one can be found <a href="https://nso.booth.pm/items/308600">here</a> and it's Nico-focused, mostly elaborating on what you can see during her dream and the conversation she has with Honoka at the end.
            <br />
            <table className="screenshots">
                <tbody>
                    <tr>
                        <td><img src={(comics('./045.jpg'))} alt="nicohonk1" /></td>
                        <td><img src={(comics('./038.jpg'))} alt="nicohonk2" /></td>
                    </tr>
                    <tr>
                        <td><img src={(comics('./062.jpg'))} alt="nicohonk3" /></td>
                        <td><img src={(comics('./061.jpg'))} alt="nicohonk4" /></td>
                    </tr>
                </tbody>
            </table>
            <br />
            Then there's another one focused on what happens on Kotori's dream but from here perspective, you can find that one <a href="https://nso.booth.pm/items/309478">here</a>.
            <br />
            <table className="screenshots">
                <tbody>
                    <tr>
                        <td><img src={(comics('./alb1.jpg'))} alt="albatross" /></td>
                        <td><img src={(comics('./alb2.jpg'))} alt="albatross2" /></td>
                    </tr>
                </tbody>
            </table>
            <br />
            There's an unused BossKayochin.class file within the code, named like every other boss; this could hint that it was perhaps planned to have all eight other members leading up to Honoka as the final boss? That or maybe you had to wake up Hanayo for the third clubroom visit or something along those lines, either way another thing of note is instead of having "ENEMYNAME attacks!", she has "Hanayo is practicing her dancing."
            <br /> <br />
            There's some old mockups of the adventuring menu that feature different things, the first one displays a slot for armor and a weapon, along with a "Nico Nico Nii" button at the bottom, with four spaces for other stuff while the second one shows the options "Nico Nico Nii", "Tools", "Status" and "System":
            <br />
            <table className="screenshots">
                <tbody>
                    <tr><td><img src={(images('./wakuwaku1.png'))} alt="mock1" /></td>
                        <td><img src={(images('./wakuwaku2.png'))} alt="mock2" /></td></tr>
                </tbody>
            </table>
            <br />
            There's also this one, closest to the one actually used but with a few things moved around:
            <br />
            <table className="screenshots">
                <tbody>
                    <tr><td><img src={(images('./waku3c.png'))} alt="mock3" /></td></tr>
                </tbody>
            </table>
            <br />
            There's an unused black whisper that would have shown up during the 11th floor in Nico's Dream, which I'm a bit surprised it exists, given what it implies, but I'm also not surprised it didn't make the cut, given what it implies:
            <br />
            <table className="screenshots">
                <tbody>
                    <tr><td><img src={(images('./nicobul.png'))} alt="whisper" /></td></tr>
                </tbody>
            </table>
            <br />
            The Chunslime Instructor that shows up in the Chuntorial is actually cut content, but I put him back in to serve as warning to actually use the run option unlike most games, then the first thing people told me when playing was that they kept dying to strong enemies, so I suppose some things just can't be helped.
            <br /><br />

            There were apparently going to be more standard RPG elements in the game as evidenced by a few things:
            <br /><br />
            There's a few records of skills in the game: a "check hand mirror" which would restore HP (kept in the game, somewhat), "hp plus", which would increase your max HP by 10, and "full nico nico nii", which would deal 5 damage.
            <br /><br />
            There's a file called equips.csv that lists some stuff you could find from enemy drops, such as weapons (clubs, microphones, glowsticks, etc.), armor (uniforms, live costumes), and skills (the previously listed ones).
            <br /><br />
            The part in the chuntorial where Kotori explains how defeating enemies and XP works was a bit different, with her explaining what your three stats actually do, guess it was cut because they were easy to figure out by just messing with them? Or maybe the comedy just wasn't up to par.
            <br />Similarly, there are dialogues for the removed RPG elements of the game, with Kotori commenting and explaining how stuff you find after defeating enemies is used, there's one for finding a hand mirror, for finding a passive skill, and for finding weapons. These are removed because obviously those didn't make it into the final version of the game.
            <br /> <br />
            <table className="screenshots">
                <tbody>
                    <tr>
                        <td><img src={(images('./niconiconii.jpg'))} alt="niconiconii" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default NicoYumeRealNotes;