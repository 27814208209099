import axios from "axios";
import { Buffer } from "buffer";

const images = require.context('../../public/images/granbluedle/etc')

export async function checkMaint() {
  try {
    const response = await axios.get('/api/maint');
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getStats() {
  try {
    const response = await axios.get('/api/stats');
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getSpecificWeapon(id) {
  try {
    const response = await axios.post('/api/specific', {id: id});
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getRandomWeapon(hardMode) {
  try {
    const response = hardMode ? await axios.get('/api/randomweaponhard') : await axios.get('/api/randomweapon');
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getWeapons(hardMode) {
  try {
    const response = hardMode ? await axios.get('/api/hardlist') : await axios.get('/api/namelist');
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function lost(hardMode) {
  try {
    const response = await axios.post('/api/lost', { hardMode:hardMode });
    return response.data
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function guess(weapon, hardMode) {
  try {
    const response = await axios.post('/api/guess', { name: weapon.weapon_name, hardMode:hardMode });
    return response.data
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function guexx(weapon, answer, hardMode) {
  try {
    const response = await axios.post('/api/guess', { name: weapon.weapon_name, answer: answer.weapon_name, hardMode:hardMode });
    return response.data
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function ougid(which, hardMode) {
  switch (which) {
    case 1: // image
      try {
        const response = await axios.post('/api/ougi', { which: which, hardMode:hardMode }, {
          responseType: 'arraybuffer'
        });
        return Buffer.from(response.data, 'binary').toString('base64')
      } catch (error) {
        console.error(error);
        throw error;
      }
    case 4: // skills
      try {
        const response = await axios.post('/api/ougi', { which: which, hardMode:hardMode });
        return response.data.skills
      } catch (error) {
        console.error(error);
        throw error;
      }
    default: // description
      try {
        const response = await axios.post('/api/ougi', { which: which, hardMode:hardMode });
        return response.data
      } catch (error) {
        console.error(error);
        throw error;
      }
  }
}

export async function ouxx(which, answer, hardMode) {
  switch (which) {
    case 1: // image
      try {
        const response = await axios.post('/api/ougi', { which: which, answer: answer.weapon_name, hardMode:hardMode }, {
          responseType: 'arraybuffer'
        });
        return Buffer.from(response.data, 'binary').toString('base64')
      } catch (error) {
        console.error(error);
        throw error;
      }
    case 4: // skills
      try {
        const response = await axios.post('/api/ougi', { which: which, answer: answer.weapon_name, hardMode:hardMode });
        return response.data.skills
      } catch (error) {
        console.error(error);
        throw error;
      }
    default: // description
      try {
        const response = await axios.post('/api/ougi', { which: which, answer: answer.weapon_name, hardMode:hardMode });
        return response.data
      } catch (error) {
        console.error(error);
        throw error;
      }
  }
}

export function elementIcon(element, jpMode) {
  var string = `./${jpMode ? 'jp_' : ''}${element.toLowerCase()}.png`
  console.log(string)
  return images(string)
}

export function typeIcon(type, jpMode) {
  var string = `./${jpMode ? 'jp_' : ''}${type.toLowerCase()}.png`
  return images(string)
}