import { addTagFirestore, editTagFirestore } from "./firestore";

export const manageTag = async (state,add) => {
    const {category, label, extra, id='', oldValue = ''} = state;
    // Turn extra tags into array
    const split = extra.split(',');
    // Iterate through the array to trim them
    const trimmed = split.map( (extraTag) => extraTag.trim());
    // Also trim main one
    const newLabel = label.trim();
    
    if(add) {
        return addTagFirestore({
            category,
            extra,
            label: newLabel,
            extras: trimmed
        });
    } else {
        return editTagFirestore({
            category,
            extra,
            id,
            oldValue,
            label: newLabel,
            extras: trimmed
        });
    }
}